import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Footer from "../../layout/Footer";
import { getAllCategories, getAllProducts } from "../../redux/actions/products";
import folder from "../../assets/img/folder.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ShareGlobalSearch from "./ShareGlobalSearch";
export default function ProductShare() {
  document.title = "Product List | Glasier Wellness";
  const [searchParams] = useSearchParams();
  
  const queryParams = searchParams.get("productId");
  
  const dispatch = useDispatch();
  const { catId } = useParams();
  const { categories } = useSelector((state) => state.products);
  const { products } = useSelector((state) => state.products);
  const [Loader, setLoader] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [productId, setProductId] = useState([]);
  const [globalSearch, setGlobalSearch] = useState(false);
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  useEffect(() => {
    setProductId(queryParams?.split("-"));
  }, [queryParams]);

  useEffect(() => {
    getProducts();
  }, [dispatch]);


  useEffect(() => {
    getCategories();
  }, [dispatch]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  const getCategories = async () => {
    await dispatch(getAllCategories());
  };

  const getProducts = async () => {
    setLoader(true);
    await dispatch(getAllProducts({ categoryId: catId })).then(async (res) => {
      setLoader(false);
    });
  };

  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  useEffect(() => {
    categoryList
      ?.filter((cat) => cat._id == catId)
      .map((category) => {
        setCategoryName(category?.categoryName);
      });
  }, [categoryList]);

  useEffect(() => {
    if (queryParams !== null) {
      const filteredProducts = products?.filter((product) => {
        return productId?.find((id) => product._id == id);
      });
      setProductsList(filteredProducts);
    } else {
      setProductsList(products);
    }
  }, [queryParams, products]);
  return (
    <React.Fragment>
      <div className="main-panel" style={{ width: "100%" }}>
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-5 justify-content-center">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center mt-2">
                  <h4 className="main-heading mb-0">
                    {capitalize(categoryName)}
                  </h4>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>
                </div>
              </div>

              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : productsList?.length > 0 ? (
                <div className="row">
                  {productsList
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((product, i) => (
                      <div key={i} className="col-md-2 mb-3">
                        <div className="category-box">
                          <Link
                            to={`/share/gallery/${catId}/${product?._id}`}
                            className="category-box-link"
                          >
                            <div
                              className="folder-icon"
                              style={{ WebkitMaskImage: `url(${folder})` }}
                            ></div>
                            <h5 className="cat-title mb-0">
                              {capitalize(product?.productName)}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="position-relative text-center">
                  No Product Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Global Search Popup */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <ShareGlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
