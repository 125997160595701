import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import IsAuth from "../../../components/auth/IsAuth";
import { fetchAllSource, updateSource } from "../../../redux/actions/sources";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../layout/Footer";
export default function EditSource() {
  document.title = `Edit Source | Glasier Wellness`;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { source } = useSelector((state) => state.sources);
  const [sourceName, setSourceName] = useState(null);
  const [sourcesList, setSourcesList] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  const handleChange = (e) => {
    setisLoading(false)
    if(e.target.value ==""){
      setisLoading(true)
    }
    setSourceName(e.target.value);
  };

  const updateSources = async () => {
    setisLoading(true)
    const res = await dispatch(
      updateSource({ id: id, sourceName: sourceName })
    );
    if (res.status) {
      toast.success(res.message, {
        theme: "colored",
      });
      setTimeout(() => {
        navigate(-1);
      }, 500);
      setisLoading(false)
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setisLoading(false)
    }
  };
  const getSources = async () => {
    await dispatch(fetchAllSource());
  };

  useEffect(() => {
    getSources();
  }, [dispatch]);

  useEffect(() => {
    setSourcesList(source);
  }, [source]);

  useEffect(() => {
    sourcesList
      ?.filter((source) => source._id == id)
      .map((sources) => setSourceName(sources.sourceName));
  }, [sourcesList]);

  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="main-heading mb-0">Update Source</h4>
                </div>
                <div className="col-md-6 text-right">
                  <Link
                    to="/admin/source-list"
                    className="btn btncolor btn-rounded btn-fw"
                  >
                    {" "}
                    <i
                      className="fa fa-chevron-left"
                      style={{ fontSize: 14 }}
                    ></i>{" "}
                    &nbsp;Back
                  </Link>
                </div>
              </div>
              <>
                <div className="row mt-4 align-items-center">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="sourceName">
                        Source Name <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="text"
                        name="sourceName"
                        className="form-control emailinput"
                        id="sourceName"
                        placeholder="India Mart"
                        value={sourceName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btncolor btn-rounded btn-fw"
                      onClick={(e) => updateSources(e)}
                      disabled={isLoading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
