import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
    FETCH_TODO_REQUEST,
    FETCH_TODO_SUCCESS,
    FETCH_TODO_FAIL,
    ADD_TODO_REQUEST,
    ADD_TODO_SUCCESS,
    ADD_TODO_FAIL,
    UPDATE_TODO_REQUEST,
    UPDATE_TODO_SUCCESS,
    UPDATE_TODO_FAIL,
    MARK_TODO_COMPLETED_REQUEST,
    MARK_TODO_COMPLETED_SUCCESS,
    MARK_TODO_COMPLETED_FAIL,
    DELETE_TODO_REQUEST,
    DELETE_TODO_SUCCESS,
    DELETE_TODO_FAIL,
} from "../../constants/toDoConstants";

//Fetch All Todo (Admin)
export const fetchAllToDo = (todoData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: FETCH_TODO_REQUEST});
            const response = await axios.post(API_URLS.TODO_LIST, todoData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: FETCH_TODO_SUCCESS, payload:response?.data?.data});
                    return response?.data;
                }else{
                    dispatch({
                        type: FETCH_TODO_FAIL,
                        payload: response?.data?.message
                    });
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: FETCH_TODO_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Adding A Todo
export const addTodo = (todoData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: ADD_TODO_REQUEST});
            const response = await axios.post(API_URLS.ADD_TODO, todoData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: ADD_TODO_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }else{
                    dispatch({
                        type: ADD_TODO_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: ADD_TODO_FAIL,
                payload: error
            });
            dispatch(fetchAllToDo())
            return error;
        }
    }
}

//Update A Todo
export const updateTodo = (todoData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: UPDATE_TODO_REQUEST});
            const response = await axios.post(API_URLS.UPDATE_TODO, todoData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: UPDATE_TODO_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllToDo())
                    return response?.data;
                }else{
                    dispatch({
                        type: UPDATE_TODO_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllToDo())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: UPDATE_TODO_FAIL,
                payload: error
            });
            dispatch(fetchAllToDo())
            return error;
        }
    }
}

//Toggle Todo Completed Or Not

export const changeStatusTodo = (todoData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: MARK_TODO_COMPLETED_REQUEST});
            const response = await axios.post(API_URLS.CHANGE_STATUS_TODO, todoData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: MARK_TODO_COMPLETED_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }else{
                    dispatch({
                        type: MARK_TODO_COMPLETED_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: MARK_TODO_COMPLETED_FAIL,
                payload: error
            });
            dispatch(fetchAllToDo())
            return error;
        }
    }
}

//Delete A Todo
export const deleteTodo = (todoData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        try {
            dispatch({type: DELETE_TODO_REQUEST});
            const response = await axios.delete(API_URLS.DELETE_TODO, { data: todoData, headers: { Authorization: `Bearer ${Token}` }})
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: DELETE_TODO_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }else{
                    dispatch({
                        type: DELETE_TODO_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch(fetchAllToDo())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: DELETE_TODO_FAIL,
                payload: error
            });
            // dispatch(fetchAllToDo())
            return error;
        }
    }
}
