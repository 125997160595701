import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllUsers } from "../../../redux/actions/users";
import IsAuth from "../../auth/IsAuth";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../layout/Footer";
import ToggleSwitch from "./ToggleSwitch";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../layout/FilterComponent";
import { ClipLoader } from "react-spinners";
const UserList = () => {
  document.title = "Users List | Glasier Wellness";
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getData();
  }, [dispatch]);

  const getData = async () => {
    setIsLoading(true);
    const res = await dispatch(getAllUsers());
    if (res.status) {
      setIsLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.full_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Role",
      selector: (row) => row.userType,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];
  let sortbyNewUsers = users.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const rows = [
    ...sortbyNewUsers
      ?.filter(
        (item) =>
          item.full_name &&
          item.full_name.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((row, order) => ({
        sno: (order + 1),
        full_name: row.full_name,
        email: row.email,
        userType: row.userType,
        status: (
          <>
            <ToggleSwitch
              key={order + 1}
              status={row.status}
              id={row._id}
              order={order}
            />
          </>
        ),
        action: (
          <>
            <Link
              to={`/admin/users/${row._id}`}
              className="iconbgedit"
              title="Edit"
            >
              <i className="fa fa-edit eyeicon"></i>
            </Link>{" "}
          </>
        ),
      })),
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <IsAuth />
      <ToastContainer />
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <>
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="main-heading mb-0">User List</h4>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to="/admin/add-users/"
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add User &nbsp;{" "}
                      <i
                        className="fa fa-plus"
                        style={{ fontSize: "14px" }}
                      ></i>{" "}
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <DataTable
                                columns={columns}
                                data={rows}
                                progressPending={isLoading}
                                progressComponent={
                                  <div style={{ position: "relative" }}>
                                    <ClipLoader size={50} color="#264B75" />
                                  </div>
                                }
                                pagination
                                customStyles={customStyles}
                                highlightOnHover
                                pointerOnHover
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default UserList;
