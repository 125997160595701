import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRequirments } from "../../../../redux/actions/requirments";
import IsAuth from "../../../auth/IsAuth";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createInquiry } from "../../../../redux/actions/inquiry";
import axios from "axios";
import {
  getAllUsers,
  getManagerStaffUsers,
} from "../../../../redux/actions/users";
import { fetchAllSource } from "../../../../redux/actions/sources";
import Footer from "../../../../layout/Footer";
import BasicDetails from "./BasicDetails";
import RequirementSelect from "./RequirementSelect";
import SelectedReuirements from "./SelectedReuirements";
import moment from "moment-timezone";

export default function AddNewInquiry() {
  document.title = "Add Inquiry | Glasier Wellness";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requirment } = useSelector((state) => state.requirment);
  const { users } = useSelector((state) => state.users);
  const { source } = useSelector((state) => state.sources);
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [selectRequirmentModule, setSelectRequirmentModule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [userList, setUserList] = useState([]);
  const [allData, setAllData] = useState({
    partyName: "",
    companyName: "",
    contactNumber: "",
    email: "",
    address: "",
    source: "",
    person: "",
    country: "",
    state: "",
    city: "",
    remark: "",
    date: "",
    requirements: [],
  });

  useEffect(() => {
    getRequirmentData();
  }, [dispatch]);


  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    getUserData();
  }, [dispatch]);

  useEffect(() => {
    getSources();
  }, [dispatch]);

  useEffect(() => {
    setUserList(users);
  }, [users]);
  
  const getSources = async () => {
    await dispatch(fetchAllSource());
  };
  const getUserData = async () => {
    const res = await dispatch(getAllUsers());
    if (!res?.status) {
      await dispatch(getManagerStaffUsers());
    }
  };
  const getCountry = async () => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };
    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setCountries(response?.data);
      } else {
        setCountries([]);
        setStates([]);
        setCities([]);
      }
    }
  };

  const getStates = async (countryCode) => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };

    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/" + countryCode + "/states",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setStates(response?.data);
      } else {
        setStates([]);
        setCities([]);
      }
    }
  };

  const getCities = async (stateCode) => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };
    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/" +
        countryCode +
        "/states/" +
        stateCode +
        "/cities",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setCities(response?.data);
      } else {
        setCities([]);
      }
    }
  };

  const getRequirmentData = async () => {
    setIsLoading(true);
    await dispatch(fetchAllRequirments()).then(async (res) => {
      if (res.status) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const [passedStepsVertical, setPassedStepsVertical] = useState([0]);
  const [currentStep, setCurrentStep] = useState(0);

  function toggleTab(tab) {
    if (currentStep !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab];
      if (tab >= 0 && tab <= 1) {
        setCurrentStep(tab);
        setPassedStepsVertical(modifiedSteps);
      }
    }
  }
  const handleNextStep = async (newData, requirment, final = false) => {
    setAllData((prev) => ({
      ...prev,
      ...newData,
    }));
    let nextData = {
      ...newData,
      date: moment(new Date()).format("MM/DD/YYYY"),
    };
    setAllData(nextData);
    if (final) {
      let finalData = {
        ...newData,
        requirements: requirment,
        date: moment(new Date()).format("MM/DD/YYYY"),
      };
      // setIsLoading(true);
      await dispatch(createInquiry(finalData)).then(async (res) => {
        if (res.status) {
          setIsLoading(false);
          toast.success(res.message, {
            theme: "colored",
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          setIsLoading(false);
          toast.error(res.message, {
            theme: "colored",
          });
        }
      });
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevStep = (newData, requirment, final = false) => {
    setAllData((prev) => ({
      ...prev,
      ...newData,
    }));
    if (final == "back") {
      let finalData = {
        ...newData,
        requirements: requirment,
        date: moment(new Date()).format("DD/MM/YYYY"),
      };
      setAllData(finalData);
      setSelectRequirmentModule(false);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  <Nav tabs>
                    <NavItem
                      className={classnames({
                        current: currentStep === 0,
                      })}
                    >
                      <NavLink
                        aria-controls="pills-home"
                        // className={classnames({
                        //   active:
                        //     activeTab === "BasicDetailsTab" ? true : false,
                        // })}
                        // onClick={() => setActiveTab("BasicDetailsTab")}
                        // disabled={!(passedStepsVertical || []).includes(0)}
                        className={classnames({
                          active: currentStep === 0,
                        })}
                        onClick={() => {
                          toggleTab(0);
                        }}
                        disabled={!(passedStepsVertical || []).includes(0)}
                      >
                        Basic Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-profile"
                        // onClick={() => setActiveTab("RequirementsTab")}
                        // disabled={activeTab === "BasicDetailsTab" ? true : false}
                        // className={classNames({
                        //   active:
                        //     activeTab === "RequirementsTab" ? true : false,
                        // })}
                        className={classnames({
                          active: currentStep === 1,
                        })}
                        onClick={() => {
                          toggleTab(1);
                        }}
                        disabled={!(passedStepsVertical || []).includes(1)}
                      >
                        Requirements
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="col-md-2">
                  {selectRequirmentModule ? (
                    <button
                      //   onClick={(e) => navigate(-1)}
                      onClick={() => setSelectRequirmentModule(false)}
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ fontSize: "14px", marginRight: "10px" }}
                      ></i>
                      Back
                    </button>
                  ) : (
                    <button
                      onClick={(e) => navigate(-1)}
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ fontSize: "14px", marginRight: "10px" }}
                      ></i>
                      Back
                    </button>
                  )}
                </div>

                <div className="col-md-12">
                  {currentStep === 0 ? (
                    <TabContent activeTab={currentStep} className="p-0">
                      <TabPane tabId={0} className="pt-4">
                        <BasicDetails
                          key={0}
                          toggleTab={toggleTab}
                          handleNextStep={handleNextStep}
                          handlePrevStep={handlePrevStep}
                          data={allData}
                          isLoading={isLoading}
                          getStates={getStates}
                          getCities={getCities}
                          users={userList}
                          source={source}
                          countries={countries}
                          states={states}
                          cities={cities}
                        />
                      </TabPane>
                    </TabContent>
                  ) : (
                    ""
                  )}

                  {currentStep === 1 ? (
                    <TabContent activeTab={currentStep} className="p-0">
                      <TabPane tabId={1} className="pt-2">
                        {selectRequirmentModule ? (
                          <RequirementSelect
                            key={0}
                            toggleTab={toggleTab}
                            handleNextStep={handleNextStep}
                            handlePrevStep={handlePrevStep}
                            data={allData}
                            isLoading={isLoading}
                            selectRequirmentModule={selectRequirmentModule}
                            setSelectRequirmentModule={
                              setSelectRequirmentModule
                            }
                            setSelectedRequirements={setSelectedRequirements}
                            selectedRequirements={selectedRequirements}
                          />
                        ) : (
                          <SelectedReuirements
                            key={0}
                            toggleTab={toggleTab}
                            handleNextStep={handleNextStep}
                            handlePrevStep={handlePrevStep}
                            data={allData}
                            isLoading={isLoading}
                            selectRequirmentModule={selectRequirmentModule}
                            setSelectRequirmentModule={
                              setSelectRequirmentModule
                            }
                            AllRequirment={requirment}
                            setSelectedRequirements={setSelectedRequirements}
                            selectedRequirements={selectedRequirements}
                          />
                        )}
                      </TabPane>
                    </TabContent>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
