import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logoIcon from "../../assets/img/logo-icon.png";
import fileIcon from "../../assets/img/file.png";
import moment from "moment-timezone";
import ChatAvatar from "./ChatAvatar";
import { useDispatch } from "react-redux";
import { API_URLS } from "../constant";
import axios from "axios";
import IsAuth from "../../components/auth/IsAuth";
import { toast, ToastContainer } from "react-toastify";
// import { io } from "socket.io-client";
// const socket = io.connect(process.env.REACT_APP_API_URL);
// const socket = io.connect('http://192.168.0.21:4040');

function ChatRoom({ socket }) {
  const dispatch = useDispatch();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [Token] = useState(localStorage.getItem("accessToken"));
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentMessageType, setCurrentMessageType] = useState("text");
  const [receiveAllMessageList, setReceiveAllMessageList] = useState([]);
  const [blobFile, setBlobFile] = useState([]);
  const [fileTransfer, setFileTransfer] = useState("");
  const scrollRef = useRef();

  const currentMegHandle = (event) => {
    setCurrentMessage(event.target.value);
    setCurrentMessageType("text");
  };
  const sendMessage = async (currentMessage, currentMessageType) => {
    if (currentMessage !== "") {
      await socket.emit("sendMessage", currentMessage, currentMessageType);
      setCurrentMessage("");
      setBlobFile([]);
      setFileTransfer("");
      setCurrentMessageType("text");
    }
  };
  const joinRoom = () => {
    socket.emit("joinRoom", authUser?._id);
  };
  useMemo(() => {
    joinRoom();
  }, [0]);

  useEffect(() => {
    socket.on("receiveAllMessageList", (data) => {
      setReceiveAllMessageList(data);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("receiveMessage", (data) => {
      setReceiveAllMessageList([...receiveAllMessageList, data]);
    });
  }, [socket, receiveAllMessageList]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "auto" });
  }, [receiveAllMessageList]);

  const sendMedia = async () => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      let formData = new FormData();
      formData.append("sendDocument", fileTransfer);
      await axios
        .post(API_URLS.SEND_DOCUMENTS, formData, config)
        .then((result) => {
          if (result.status) {
            if (result?.data?.status) {
              sendMessage(result?.data?.data[0], currentMessageType);
              setBlobFile([]);
            }
            else{
              toast.error(result?.data?.message, {
                theme: "colored",
              });
            }
          }
        });
    } catch (error) {
      toast.error('Something went wrong!', {
        theme: "colored",
        toastId:'something_went_wrong_sendMedia'
      });
    }
  };

  function handleUploadChange(e) {
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    let extension = e.target.value.split(".").pop();
    setCurrentMessageType(extension);
    setBlobFile(ImagesArray);
    setFileTransfer(e.target.files[0]);
  }

  function deleteFile(e) {
    const img = blobFile.filter((item, index) => index !== e);
    setBlobFile(img);
    setFileTransfer("");
  }

  const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="chat-area">
                {/* <!-- chatbox --> */}
                <div className="chatbox">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row align-items-center">
                          <div className="col-8">
                            <div className="d-flex align-items-center">
                              <span className="header_img chat-icon">
                                <img
                                  className="img-fluid"
                                  src={logoIcon}
                                  alt="image title"
                                />
                              </span>
                              <div className="header_img flex-shrink-0">
                                <img
                                  className="img-fluid"
                                  src={logoIcon}
                                  alt="user img"
                                />
                              </div>
                              <div className="flex-grow-1 ml-3">
                                <h3>Glasier Wellness</h3>
                                {/* <p>front end developer</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            {receiveAllMessageList?.length > 0 ? (
                              receiveAllMessageList
                                ?.sort(
                                  (a, b) =>
                                    new Date(a.createdAt) -
                                    new Date(b.createdAt)
                                )
                                .map((messageContent, i) => {
                                  return (
                                    <li
                                      key={i}
                                      ref={scrollRef}
                                      className={`${
                                        authUser?._id ==
                                        messageContent?.userId?._id
                                          ? "repaly"
                                          : "sender"
                                      }${
                                        messageContent?.messageType == "text"
                                          ? " text"
                                          : " document"
                                      }`}
                                    >
                                      <span className="user-logo">
                                        {/* <ChatAvatar
                                          userId={messageContent?.userId?.full_name}
                                          className="chat-avatar"
                                        /> */}
                                        <div className={`avatar-name small`}>
                                            {getInitials(messageContent?.userId?.full_name?messageContent?.userId?.full_name:"Guest")}
                                        </div>
                                      </span>
                                      {messageContent?.messageType == "text" ? (
                                        <p>
                                          {" "}
                                          <span className="username">
                                            {messageContent?.userId?.full_name}
                                          </span>{" "}
                                          {messageContent?.message}{" "}
                                        </p>
                                      ) : messageContent?.messageType ==
                                          "png" ||
                                        messageContent?.messageType == "jpg" ||
                                        messageContent?.messageType == "jpeg" ||
                                        messageContent?.messageType == "gif" ? (
                                        <Link
                                        // onClick={()=>download(`${process.env.REACT_APP_CHAT_IMAGE_PATH}/${messageContent?.message}`, messageContent?.message)}
                                          download={`${messageContent?.message}`}
                                          to={`${process.env.REACT_APP_CHAT_IMAGE_PATH}/${messageContent?.message}`}
                                          className="img-div"
                                          target={'_blank'}
                                        >
                                          <span className="username">
                                            {messageContent?.userId?.full_name}
                                          </span>{" "}
                                          <img
                                            crossOrigin="anonymous"
                                            className="w-100"
                                            src={`${process.env.REACT_APP_CHAT_IMAGE_PATH}/${messageContent?.message}`}
                                          />
                                        </Link>
                                      ) : (
                                        <Link
                                          download={`${messageContent?.message}`}
                                          to={`${process.env.REACT_APP_CHAT_IMAGE_PATH}/${messageContent?.message}`}
                                          className="document-div"
                                          target={'_blank'}
                                        >
                                          <span className="username">
                                            {messageContent?.userId?.full_name}
                                          </span>{" "}
                                          <div
                                            className="document-file"
                                            ext={messageContent?.messageType}
                                          >
                                            <img
                                              crossOrigin="anonymous"
                                              className=""
                                              src={fileIcon}
                                            />
                                            <span>
                                              {messageContent?.message}
                                            </span>
                                          </div>
                                        </Link>
                                      )}
                                      {/* {messageContent.author} */}
                                      <span className="time">
                                        {moment(
                                          messageContent?.createdAt
                                        ).fromNow()}
                                      </span>
                                    </li>
                                  );
                                })
                            ) : (
                              <li>
                                <div className="divider">
                                  <h6>No Message Available</h6>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="send-box">
                        <div>
                          <div className="send-btns">
                            <div className="fab_field">
                              {blobFile.length > 0 ? (
                                <div className="main-box">
                                  {blobFile.map((item, i) => {
                                    return (
                                      <div key={i} className="images-box">
                                        {currentMessageType == "png" ||
                                        currentMessageType == "jpg" ||
                                        currentMessageType == "jpeg" ||
                                        currentMessageType == "gif" ? (
                                          <img src={item} width="40px" />
                                        ) : (
                                          <div
                                            className="doc-file"
                                            ext={currentMessageType}
                                          >
                                            <img src={fileIcon} width="40px" />
                                          </div>
                                        )}
                                        <button
                                          type="button"
                                          className="remove-btn"
                                          onClick={() => deleteFile(i)}
                                        >
                                          &times;
                                        </button>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="attach">
                              <div className="button-wrapper">
                                <span className="label">
                                  <i className="fa fa-camera"></i>&nbsp;
                                  attached file
                                </span>
                                <input
                                  type="file"
                                  name="upload"
                                  id="upload"
                                  className="upload-box"
                                  placeholder="Upload File"
                                  aria-label="Upload File"
                                  onChange={handleUploadChange}
                                />
                              </div>
                            </div>
                          </div>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            aria-label="message…"
                            placeholder="Write message…"
                            onChange={(event) => currentMegHandle(event)}
                            onKeyPress={(event) => {
                              event.key === "Enter" &&
                                sendMessage(currentMessage, currentMessageType);
                            }}
                            value={currentMessage}
                            readOnly={blobFile.length!==0}
                            disabled={blobFile.length!==0}
                          />

                          {blobFile.length > 0 ? (
                            <button type="button" onClick={sendMedia}>
                              <i
                                className="fa fa-paper-plane"
                                aria-hidden="true"
                              ></i>{" "}
                              Send
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                sendMessage(currentMessage, currentMessageType)
                              }
                            >
                              <i
                                className="fa fa-paper-plane"
                                aria-hidden="true"
                              ></i>{" "}
                              Send
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default (ChatRoom);
