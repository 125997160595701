export const FETCH_INQUIRY_REQUEST = "FETCH_INQUIRY_REQUEST";
export const FETCH_INQUIRY_SUCCESS = "FETCH_INQUIRY_SUCCESS";
export const FETCH_INQUIRY_FAIL = "FETCH_INQUIRY_FAIL";

export const FETCH_SINGLE_INQUIRY_REQUEST = "FETCH_SINGLE_INQUIRY_REQUEST";
export const FETCH_SINGLE_INQUIRY_SUCCESS = "FETCH_SINGLE_INQUIRY_SUCCESS";
export const FETCH_SINGLE_INQUIRY_FAIL = "FETCH_SINGLE_INQUIRY_FAIL";

export const GET_INQUIRY_BY_USER_REQUEST = "GET_INQUIRY_BY_USER_REQUEST";
export const GET_INQUIRY_BY_USER_SUCCESS = "GET_INQUIRY_BY_USER_SUCCESS";
export const GET_INQUIRY_BY_USER_FAIL = "GET_INQUIRY_BY_USER_FAIL";


export const CREATE_INQUIRY_REQUEST = "CREATE_INQUIRY_REQUEST";
export const CREATE_INQUIRY_SUCCESS = "CREATE_INQUIRY_SUCCESS";
export const CREATE_INQUIRY_FAIL = "CREATE_INQUIRY_FAIL";

export const UPDATE_INQUIRY_REQUEST = "UPDATE_INQUIRY_REQUEST";
export const UPDATE_INQUIRY_SUCCESS = "UPDATE_INQUIRY_SUCCESS";
export const UPDATE_INQUIRY_FAIL = "UPDATE_INQUIRY_FAIL";

// add User Comments
export const ADD_USER_COMMENT_REQUEST = "ADD_USER_COMMENT_REQUEST";
export const ADD_USER_COMMENT_SUCCESS = "ADD_USER_COMMENT_SUCCESS";
export const ADD_USER_COMMENT_FAIL = "ADD_USER_COMMENT_FAIL";

export const UPDATE_STEPSTATUS_REQUEST = "UPDATE_STEPSTATUS_REQUEST";
export const UPDATE_STEPSTATUS_SUCCESS = "UPDATE_STEPSTATUS_SUCCESS";
export const UPDATE_STEPSTATUS_FAIL = "UPDATE_STEPSTATUS_FAIL";


export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL";

// Search Party Name
export const SEARCH_PARTY_NAME_REQUEST = "SEARCH_PARTY_NAME_REQUEST";
export const SEARCH_PARTY_NAME_SUCCESS = "SEARCH_PARTY_NAME_SUCCESS";
export const SEARCH_PARTY_NAME_FAIL = "SEARCH_PARTY_NAME_FAIL";