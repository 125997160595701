import React from "react";
import { Input } from "reactstrap";

export default function FilterComponent({ onFilter, filterText }) {
  return (
      <label>
        <Input
          type="search"
          className="form-control"
          placeholder="Search..."
          aria-controls="order-listing"
          onChange={onFilter}
          value={filterText}
          style={{width: '250px'}}
        />
      </label>
  );
}
