import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Footer from "../../layout/Footer";
import {
  clearNotifications,
  getNotifications,
} from "../../redux/actions/notifications";
import { ClipLoader } from "react-spinners";
import IsAuth from "../auth/IsAuth";
import { Link } from "react-router-dom";
export default function Notification() {
  const dispatch = useDispatch();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { notifications } = useSelector((state) => state.notifications);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllNotifications();
  }, [dispatch]);

  const getAllNotifications = async () => {
    setIsLoading(true);
    await dispatch(getNotifications()).then(async (res) => {
      if (res.status) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    });
  };

  const clearAllSubmit = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(clearNotifications()).then(async (res) => {
          if (res.status) {
            setIsLoading(false);
            Swal.fire("Success!", res.message, "success");
            getAllNotifications();
          } else {
            setIsLoading(false);
            Swal.fire("Error!", res.message, "error");
            getAllNotifications();
          }
        });
      }
    });
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      width: "120px",
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
      width: "100px",
    },
    {
      name: "Notificaton",
      selector: (row) => row.notificaton,
      width: "300px",
    },
    {
      name: "Details",
      selector: (row) => row.details,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "80px"
    },
  ];
  const rows = [
    ...notifications?.map((row, order) => ({
      sno: order + 1,
      date: moment(row.createdAt).format("L"),
      time: moment(row.createdAt).format("LT"),
      notificaton: row.title,
      details: row.body,
      action: (
        row?.type=="Inquiry"?
        <Link
          to={row?.type=="Inquiry"?`/${authUser?.userType.toLowerCase()}/view-inquiry/${row?.data?.inquiryId}`:'/notification'}
          className="iconbgview"
        >
          <i className="fa fa-eye eyeicon"></i>
        </Link>
        :null
      ),
    })),
  ];
  const customStyles = {
    tableWrapper: {
      style: {
        display: "block",
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };
  return (
    <React.Fragment>
      <IsAuth />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3 ">
                <div className="col-md-4">
                  <h4 className="mb-4">Notification</h4>
                </div>
                <div className="col-md-8">
                  {notifications?.length ? (
                    <button
                      type="button"
                      className="btn btn-danger btn-rounded btn-fw pull-right"
                      onClick={clearAllSubmit}
                    >
                      Clear All
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      progressComponent={
                        <div style={{ position: "relative" }}>
                          <ClipLoader size={50} color="#264B75" />
                        </div>
                      }
                      pagination
                      persistTableHead
                      customStyles={customStyles}
                      highlightOnHover
                      pointerOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
