import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);
const getDaysInMonth = (month, year) => {
  var date = new Date(year, month, 1);
  var days = [];
  var count = 1;
  while (date.getMonth() === month) {
    days.push(count);
    date.setDate(date.getDate() + 1);
    count++;
  }
  return days;
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    }
  }
};

function Charts ( { data } ) {
  const LineChart = {
    labels: getDaysInMonth(new Date().getMonth(), new Date().getFullYear()),  
    datasets: data
  };
  return <Line options={options} data={LineChart} />;
}
export default Charts;
