export const FETCH_REQUIRMENT_REQUEST = "FETCH_REQUIRMENT_REQUEST";
export const FETCH_REQUIRMENT_SUCCESS = "FETCH_REQUIRMENT_SUCCESS";
export const FETCH_REQUIRMENT_FAIL = "FETCH_REQUIRMENT_FAIL";

export const ADD_REQUIRMENT_REQUEST = "ADD_REQUIRMENT_REQUEST";
export const ADD_REQUIRMENT_SUCCESS = "ADD_REQUIRMENT_SUCCESS";
export const ADD_REQUIRMENT_FAIL = "ADD_REQUIRMENT_FAIL";

export const UPDATE_REQUIRMENT_REQUEST = "UPDATE_REQUIRMENT_REQUEST";
export const UPDATE_REQUIRMENT_SUCCESS = "UPDATE_REQUIRMENT_SUCCESS";
export const UPDATE_REQUIRMENT_FAIL = "UPDATE_REQUIRMENT_FAIL";

export const MARK_REQUIRMENT_COMPLETED_REQUEST = "MARK_REQUIRMENT_COMPLETED_REQUEST";
export const MARK_REQUIRMENT_COMPLETED_SUCCESS = "MARK_REQUIRMENT_COMPLETED_SUCCESS";
export const MARK_REQUIRMENT_COMPLETED_FAIL = "MARK_REQUIRMENT_COMPLETED_FAIL";

export const REQUIRMENT_BY_DATE_REQUEST = "REQUIRMENT_BY_DATE_REQUEST";
export const REQUIRMENT_BY_DATE_SUCCESS = "REQUIRMENT_BY_DATE_SUCCESS";
export const REQUIRMENT_BY_DATE_FAIL = "REQUIRMENT_BY_DATE_FAIL";

export const DELETE_REQUIRMENT_REQUEST = "DELETE_REQUIRMENT_REQUEST";
export const DELETE_REQUIRMENT_SUCCESS = "DELETE_REQUIRMENT_SUCCESS";
export const DELETE_REQUIRMENT_FAIL = "DELETE_REQUIRMENT_FAIL";



