export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const EDIT_USER_PROFILE_REQUEST = "EDIT_USER_PROFILE_REQUEST";
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS";
export const EDIT_USER_PROFILE_FAIL = "EDIT_USER_PROFILE_FAIL";

// SIGNUP
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const ADMIN_EDIT_PROFILE_REQUEST = "ADMIN_EDIT_PROFILE_REQUEST";
export const ADMIN_EDIT_PROFILE_SUCCESS = "ADMIN_EDIT_PROFILE_SUCCESS";
export const ADMIN_EDIT_PROFILE_FAIL = "ADMIN_EDIT_PROFILE_FAIL";

export const CHANGE_USER_STATUS_REQUEST = "CHANGE_USER_STATUS_REQUEST";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAIL = "CHANGE_USER_STATUS_FAIL";

export const TOP_USER_REQUEST = "TOP_USER_REQUEST";
export const TOP_USER_SUCCESS = "TOP_USER_SUCCESS";
export const TOP_USER_FAIL = "TOP_USER_FAIL";


    