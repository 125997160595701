import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import React, { useEffect, useRef, useState } from "react";
import ok from "../../../assets/img/ok.png";
import loadergif from "../../../assets/img/giphy.webp";
import placeholder from "../../../assets/img/placeholder.png";

import Footer from "../../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getProductImages,
  updateProductsImage,
} from "../../../redux/actions/products";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import IsAuth from "../../auth/IsAuth";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { API_URLS, ROLES } from "../../../common/constant";
import axios from "axios";
import GlobalSearch from "./GlobalSearch";
import LazyImage from "./LazyImage";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";



export default function ProductGallery() {
  document.title = "Products Gallery | Glasier Wellness";
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_S3_KEY_SECRET,
    },
  });
  const bucketName =`${process.env.REACT_APP_AWS_S3_BUCKET}`
  const s3BucketPath = `${process.env.REACT_APP_AWS_S3_FOLDER}`
  const imageBaseUrl = process.env.REACT_APP_S3_IMAGE_BASE_URL
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { catId, productId } = useParams();
  const { products } = useSelector((state) => state.products);
  const { productImages } = useSelector((state) => state.products);
  const [urls, setUrls] = useState([]);
  const [prodImages, setProdImages] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [AttachPhotos, setAttachPhotos] = useState([]);
  const [oldImage, setOldImage] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [galleryModel, setGalleryModel] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [productName, setProductName] = useState("");
  const IMAGE_URL = process.env.REACT_APP_IMAGE_PATH;
  const attachPhotosRef = useRef();
  const [globalSearch, setGlobalSearch] = useState(false);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  useEffect(() => {
    fetchProductImages();
  }, [dispatch]);

  useEffect(() => {
    setProdImages(productImages);
  }, [productImages]);

  const fetchProductImages = async () => {
    setLoader(true);
    await dispatch(getProductImages({ productId: productId })).then(
      async (res) => {
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    getProducts();
  }, [dispatch]);

  useEffect(() => {
    setProductsList(products);
  }, [products]);

  const getProducts = async () => {
    await dispatch(getAllProducts({ categoryId: catId }));
  };

  useEffect(() => {
    productsList
      ?.filter((product) => product._id == productId)
      .map((product) => {
        setProductName(product?.productName);
      });
  }, [productsList]);

  const selectItem = (e) => {
    const { checked, value } = e.currentTarget;
    setUrls((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const getImgSignedUrl = async (imageName) => {

    const command = await new GetObjectCommand({
      Bucket: bucketName,
      Key: `${s3BucketPath}/${imageName}`,
    });
    const res = await getSignedUrl(s3Client, command)

    const buffer = await axios.get(res, {responseType: 'arraybuffer'})
    return buffer.data
  }


  const getSignedUrlsAndCreateZip = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const zip = new JSZip();
  
        for (let i = 0; i < urls.length; i++) {
          const urlArr = urls[i].split("/productImages/");
          const filename = urlArr[urlArr.length - 1];
          const signedUrl = await getImgSignedUrl(filename);
  
          if (signedUrl) {
            zip.file(filename, signedUrl, { binary: true });
  
            if (i + 1 === urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                resolve(content);
              });
            }
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const downloadZip = async () => {
    try {
      const zipContent = await getSignedUrlsAndCreateZip();
      saveAs(zipContent, 'products.zip');
    } catch (error) {
      toast.error("Download failed!", {
        theme: "colored",
        toastId: "download_failed",
      });
    }

    // const zip = new JSZip();
    // // let count = 0;
    // const zipFilename = "products.zip";
    // urls.map(async (url, i) => {
    //   const urlArr = url.split("/productImages/");
    //   const filename = urlArr[urlArr.length - 1];
    //   try {
        
    //     const signedUrl =  await getImgSignedUrl(filename)
    //     if(signedUrl){
      //     zip.file(filename, signedUrl, { binary: true })
      //     console.log('signedUrl--->', signedUrl)
      //     if (i +1 === urls.length) {
      //         zip.generateAsync({ type: "blob" }).then(function (content) {
      //           saveAs(content, zipFilename);
      //         });
      //     }
    //     }
    //   } catch (err) {
    //     console.log('error---->', err)
    //     toast.error("Download failed!", {
    //       theme: "colored",
    //       toastId: "download_failed",
    //     });
    //   }
    // });
  };

  const submitImages = async () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("productId", productId);

    if (oldImage?.length > 0) {
      formData.append("oldImg", JSON.stringify(oldImage));
    }
    for (var i = 0; i < AttachPhotos.length; i++) {
      formData.append("attachPhotos", AttachPhotos[i]);
    }
    await dispatch(updateProductsImage(formData)).then(async (res) => {
      if (res.status) {
        toast.success(res.message, {
          theme: "colored",
        });
        // setProdImages([...prodImages, res?.data]);
        fetchProductImages();
        attachPhotosRef.current.value = null;
        galleryModelToggle();
        setOldImage([]);
        setNewImage([]);
        setAttachPhotos([]);
        setIsLoading(false);
      } else {
        toast.error(res.message, {
          theme: "colored",
        });
        setIsLoading(false);
      }
    });
  };

  const galleryModelToggle = () => {
    setGalleryModel((state) => !state);
  };

  const removeOldImage = (image) => {
    setOldImage((prev) => [...prev, image]);
  };
  const removeNewImage = (e) => {
    const newImgRemove = newImage.filter((item, index) => index !== e);
    setNewImage(newImgRemove);
  };

  const share = async (e) => {
    const fileNames = [];
    urls.forEach(async function (url) {
      const urlArr = url.split("/");
      const filename = urlArr[urlArr.length - 1];
      fileNames.push(filename);
    });
    try {
      const Token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(
          API_URLS.SHARE_IMAGES_TOKEN,
          { productId: productId, images: fileNames },
          config
        )
        .then((result) => {
          if (result?.status) {
            if (result?.data?.status) {
              e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copied!';
              const el = document.createElement("input");
              el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/images/?token=${result?.data?.data}`;
              // el.value = urls?.toString()?.replace(/,/g, ", ");
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
              setTimeout(() => {
                e.target.innerHTML =
                  '<i class="fa fa-copy mr-2"></i> Copy Link';
              }, 2000);
            }
          }
        });
    } catch (error) {
      toast.error("Sharing failed!", {
        theme: "colored",
        toastId: "sharing_failed",
      });
    }
  };

  const SelectAll = () => {
    const images = prodImages?.map((img) => {
      return `${imageBaseUrl}/${img}`;
    });
    setUrls(images);
  };
  const DeSelectAll = () => {
    setUrls([]);
  };

  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-3">
                  <button
                    onClick={(e) => navigate(-1)}
                    className="btn btncolor btn-rounded btn-fw pull-left mb-0"
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: "14px", marginRight: "10px" }}
                    ></i>
                    Back
                  </button>
                </div>
                <div className="col-md-6 text-center mt-2">
                  <h4 className="main-heading">{capitalize(productName)}</h4>
                </div>

                <div className="col-md-3">
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>
                  <button
                    type="button"
                    onClick={downloadZip}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3"
                    disabled={urls.length === 0}
                    title="Download"
                  >
                    <i className="fa fa-download eyeicon"></i>
                  </button>{" "}
                  {authUser && authUser.userType === ROLES.PRODUCTION_USER ? (
                    <button
                      type="button"
                      className="btn btncolor btn-rounded btn-fw pull-right"
                      onClick={() => {
                        galleryModelToggle();
                        setLoader(false);
                      }}
                    >
                      Add New Image
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  {urls?.length === 0 ? (
                    <button
                      className="btn btncolor btn-rounded btn-fw mr-2"
                      onClick={() => SelectAll()}
                      disabled={prodImages?.length === 0}
                    >
                      Select All
                    </button>
                  ) : (
                    <button
                      className="btn btncolor btn-rounded btn-fw mr-2"
                      onClick={() => DeSelectAll()}
                      disabled={prodImages?.length === 0}
                    >
                      Deselect All
                    </button>
                  )}
                  <button
                    className="btn btncolor btn-rounded btn-fw"
                    onClick={(e) => share(e)}
                    disabled={urls?.length == 0}
                  >
                    <i className="fa fa-copy mr-2"></i> Copy Link
                  </button>
                </div>
              </div>
              {/* Image rendering */}
              {
              Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : prodImages?.length > 0 ? (
                <Gallery withDownloadButton
                  options={{
                    padding: { top: 20, bottom: 40, left: 100, right: 100 },
                  }}
                >
                  <div className="row">
                    {prodImages?.map((image, i) => (
                      <div key={i} className="col-md-2">
                        <label className="pro_img">
                          <input
                            key={i}
                            type="checkbox"
                            name="pro_img"
                            value={`${imageBaseUrl}/${image}`}
                            onChange={(e) => selectItem(e)}
                            checked={
                              urls?.find((url) => `${imageBaseUrl}/${image}` == url)
                                ? true
                                : false
                            }
                          />
                          <Item
                            original={`${imageBaseUrl}/${image}`}
                            thumbnail={`${imageBaseUrl}/${image}`}
                            width="800"
                            height="1024"
                          >
                            {({ ref, open }) => (
                              <>
                                <LazyImage
                                  placeholderSrc={loadergif}
                                  key={i}
                                  reference={ref}
                                  onDoubleClick={open}
                                  src={`${imageBaseUrl}/${image}`}
                                  placeholderStyle={{ width: "100%" }}
                                  errorImg={placeholder}
                                />
                              </>
                            )}
                          </Item>

                          <span
                            style={{
                              WebkitMaskImage: `url(${ok})`,
                              WebkitMaskBoxImage: `url(${ok})`,
                            }}
                          ></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </Gallery>
              ) : (
                <div className="position-relative text-center">
                  No Product Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Add new images and remove images modal*/}
      <Modal size="lg" isOpen={galleryModel} toggle={galleryModelToggle}>
        <ModalHeader toggle={galleryModelToggle}>Add Images</ModalHeader>
        <ModalBody>
          <Form encType="multipart/form-data">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Label for="attachPhotos">Images</Label>
                  <Input
                    type="file"
                    id="attachPhotos"
                    name="attachPhotos"
                    className="form-control emailinput"
                    autoComplete="off"
                    ref={attachPhotosRef}
                    multiple
                    onChange={(e) => {
                      if (e.target.name == "attachPhotos") {
                        let ImagesArray = Object.entries(e.target.files).map(
                          (file) => file[1]
                        );
                        setAttachPhotos(ImagesArray);
                        let newImagesArray = Object.entries(e.target.files).map(
                          (e) => URL.createObjectURL(e[1])
                        );
                        setNewImage(newImagesArray);
                      }
                      return false;
                    }}
                  />
                </div>
              </div>
              
              <div className="col-md-12">
                <div className="old-img">
                  {prodImages?.map((image, i) => {
                    return oldImage?.find((image2) => image == image2) ? (
                      ""
                    ) : (
                      <label className={""}>
                        <span
                          className="close-btn"
                          onClick={() => removeOldImage(image)}
                        >
                          &times;{" "}
                        </span>
                        <img src={`${imageBaseUrl}/${image}`} />
                      </label>
                    );
                  })}
                  {newImage?.map((image, i) => {
                    return (
                      <label className={""} key={i}>
                        <span
                          className="close-btn"
                          onClick={() => removeNewImage(i)}
                        >
                          &times;{" "}
                        </span>
                        <img src={image} />
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {isLoading ? (
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    disabled={isLoading}
                  >
                    <ClipLoader size={20} color="#fff" className="ml-2" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    onClick={submitImages}
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* Global Search */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <GlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
