import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  addTodo,
  deleteTodo,
  fetchAllToDo,
  updateTodo,
} from "../../redux/actions/todo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import IsAuth from "../auth/IsAuth";
import Footer from "../../layout/Footer";
import FilterComponent from "../../layout/FilterComponent";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ClipLoader } from "react-spinners";
export default function TodoList() {
  const dispatch = useDispatch();
  document.title = "To Do List | Glasier Wellness";
  const { todo, loading } = useSelector((state) => state.todo);
  const [isLoading, setIsLoading] = useState(true);
  const [todoFormData, setTodoFormData] = useState({
    title: "",
    date: "",
  });
  const [todoList, setTodoList] = useState([]);
  const [taskModels, setTaskModels] = useState(false);
  const [taskDate, setTaskDate] = useState(new Date());
  const [isEdit, setIsEdit] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const getTODO = async () => {
    await dispatch(fetchAllToDo({}));
  };
  useEffect(() => {
    getTODO();
    setTodoList(todo);
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleChangeTask = (e) => {
    setTodoFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeTaskDate = (date) => {
    setTaskDate(new Date(date));
    setTodoFormData((prev) => ({
      ...prev,
      date: moment(date).format("MM/DD/YYYY"),
    }));
  };
  const submitTodos = async () => {
    setIsLoading(true);
    const res = await dispatch(addTodo(todoFormData));
    if (res.status) {
      setTodoFormData({
        title: "",
        date: moment(new Date()).format("MM/DD/YYYY"),
      });
      setIsLoading(false);
      toast.success(res.message, {
        theme: "colored",
      });
      setTodoList([...todoList, res?.data]);
      setTimeout(() => {
        taskModelToggle();
      }, 500);
    } else {
      setIsLoading(false);
      toast.error(res.message, {
        theme: "colored",
      });
    }
  };

  const editTask = (id, title, date) => {
    setIsEdit(true);
    taskModelToggle();
    setTodoFormData({
      id: id,
      title: title,
      date: date,
    });
    setTaskDate(new Date(date));
  };

  const updateTodos = async () => {
    setIsLoading(true);
    const res = await dispatch(updateTodo(todoFormData));
    if (res.status) {
      setIsEdit(false);
      toast.success(res.message, {
        theme: "colored",
      });
      setTimeout(() => {
        taskModelToggle();
        setTodoFormData({
          title: "",
          date: "",
        });
      }, 800);
      setIsLoading(false);
    } else {
      setIsEdit(false);
      toast.error(res.message, {
        theme: "colored",
      });
      setIsLoading(false);
    }
  };

  const deleteTask = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteTodo({ id: id }));
        if (res.status) {
          dispatch(
            fetchAllToDo({
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
            })
          );
          Swal.fire("Deleted!", res.message, "success");
          setTodoList(todo);
          setIsLoading(false);
        } else {
          dispatch(
            fetchAllToDo({
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
            })
          );
          Swal.fire("Error!", res.message, "error");
          setIsLoading(false);
        }
      }
    });
  };

  const handleSearchTodo = async () => {
    setIsLoading(true);
    const res = await dispatch(
      fetchAllToDo(
        startDate || endDate
          ? {
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
            }
          : {}
      )
    );
    if (res.status) {
      setTodoList(todo);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const taskModelToggle = () => {
    setTaskModels((state) => !state);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      width: "200px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "200px",
    },
  ];

  const rows = [
    ...todoList
      ?.filter(
        (item) =>
          item.title &&
          item.title.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((row, i) => ({
        sno: i + 1,
        date: moment(row.date).format("L"),
        title: row.title,
        action: (
          <>
            <Link
              className="iconbgedit"
              title="Edit"
              onClick={(id, title, date) =>
                editTask(row._id, row.title, row.date)
              }
            >
              <i className="fa fa-edit eyeicon"></i>
            </Link>
            <button
              type="button"
              className="iconbgdelete ml-2"
              title="Delete"
              onClick={(id) => deleteTask(row._id)}
            >
              <i className="fa fa-trash eyeicon"></i>
            </button>
          </>
        ),
      })),
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    tableWrapper: {
      style: {
        display: "block",
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);
  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <h4 className="mb-4 text-white">To Do List</h4>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <div className="form-group mb-0">
                    <label htmlFor="search">Date </label>
                    <DatePicker
                      id="search"
                      name="search"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                      className="form-control emailinput"
                      // withPortal
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw"
                    onClick={handleSearchTodo}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw pull-right mobilrbott mb-0"
                    onClick={() => {
                      setIsEdit(false);
                      taskModelToggle();
                      setTodoFormData({
                        title: "",
                        date: moment(new Date()).format("MM/DD/YYYY"),
                      });
                      setTaskDate(new Date());
                    }}
                  >
                    Add Task
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={rows}
                      progressPending={isLoading}
                      progressComponent={
                        <div style={{ position: "relative" }}>
                          <ClipLoader size={50} color="#264B75" />
                        </div>
                      }
                      pagination
                      persistTableHead
                      customStyles={customStyles}
                      highlightOnHover
                      pointerOnHover
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Model */}
      <Modal isOpen={taskModels} toggle={taskModelToggle}>
        <ModalHeader toggle={taskModelToggle}>Add Task</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputUsername1">
                  Add Task <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control emailinput"
                  id="exampleInputUsername1"
                  placeholder="Enter task"
                  name="title"
                  onChange={(e) => handleChangeTask(e)}
                  value={todoFormData?.title}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label htmlFor="exampleFormControlSelect2">Date </Label>
                <DatePicker
                  id="date"
                  selected={taskDate}
                  onChange={handleChangeTaskDate}
                  // name="date"
                  className="form-control emailinput"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {isEdit ? (
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw"
              onClick={(e) => updateTodos(e)}
              disabled={isLoading || !todoFormData?.title}
            >
              Update
            </button>
          ) : isLoading ? (
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw"
              disabled={isLoading || !todoFormData?.title}
            >
              <ClipLoader size={20} color="#fff" className="ml-2" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw"
              onClick={(e) => submitTodos(e)}
              disabled={isLoading || !todoFormData?.title}
            >
              Submit
            </button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
