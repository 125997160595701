import React, { useEffect, useState } from "react";
import {
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import checkedPNG from "../../../../assets/img/checked.png";
import closePNG from "../../../../assets/img/cancel.png";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteComment,
  SingleInquiry,
  updateStepStatus,
  addProdUserRate,
  addUserRate,
} from "../../../../redux/actions/inquiry";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../../../common/SmallLoader";
import { addReminder } from "../../../../redux/actions/reminder";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROLES } from "../../../../common/constant";
import { ClipLoader } from "react-spinners";
import IsAuth from "../../../auth/IsAuth";
export default function InquiryView() {
  document.title = "View Inquiry | Glasier Wellness";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inquiryId } = useParams();
  const { inquiry } = useSelector((state) => state.inquiry);
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [isLoading, setIsLoading] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [inquiryDetails, setInquiryDetails] = useState([]);
  const [InquiryStep, setInquiryStep] = useState(0);
  const [statusStep, setStatusStep] = useState(0);
  const [comment, setComment] = useState("");
  const [commentsData, setCommentsData] = useState("");
  const [commentErr, setCommentErr] = useState();
  const [reminderModels, setReminderModels] = useState(false);
  const [reminderDate, setReminderDate] = useState(new Date());
  const [rateFormModels, setRateFormModels] = useState(false);
  const [userRatesData, setUserRatesData] = useState("");
  const [productionRatesData, setProductionRatesData] = useState("");
  const [userDiscountRequierments, setUserDiscountRequierments] = useState([]);
  const [userDiscountRate, setUserDiscountRate] = useState("");
  const [reminderFormData, setReminderFormData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    getInquiryDetails();
  }, [dispatch]);

  useEffect(() => {
    setInquiryDetails(inquiry);
    setCommentsData(inquiry?.inquiryStatus);
    setUserRatesData(inquiry?.userComment);
    setProductionRatesData(inquiry?.productionUserComment);
    setUserDiscountRequierments(
      inquiry?.discountOnrequirement ? inquiry?.discountOnrequirement : []
    );
    setStatusStep(
      inquiry?.stepStatus == "new"
        ? 0
        : inquiry?.stepStatus == "contacted"
        ? 1
        : inquiry?.stepStatus == "working"
        ? 2
        : inquiry?.stepStatus == "won"
        ? 2
        : 0
    );
  }, [inquiry]);

  const getInquiryDetails = async () => {
    setLoader(true);
    await dispatch(SingleInquiry(inquiryId)).then(async (res) => {
      setLoader(false);
    });
  };

  const handleChangeComments = async (e) => {
    setComment(e.target.value);
    if (e.target.value == "") {
      setCommentErr("Please Enter Comment");
    }
  };
  const commentSubmit = async (id, stepStatus, remark) => {
    setCommentErr("");
    if (remark) {
      let data = {
        id: id,
        inquiryStatus: {
          stepStatus: stepStatus,
          remark: remark,
        },
      };
      dispatch(updateStepStatus(data)).then(async (response) => {
        if (response.status) {
          toast.success(response.message, {
            theme: "colored",
          });
          setIsLoading(false);
          dispatch(SingleInquiry(inquiryDetails?._id)).then(async (result) => {
            setCommentsData(result?.data.inquiryStatus);
            setComment("");
          });
        } else {
          toast.error(response.message, {
            theme: "colored",
          });
          setIsLoading(false);
          setComment("");
        }
      });
    } else {
      setCommentErr("Please Enter Comment");
    }
  };

  const commentDelete = async (id, stepStatus) => {
    let data = {
      id: id,
      stepStatus: stepStatus,
      remark: "",
      status: 0,
    };
    dispatch(deleteComment(data)).then(async (response) => {
      if (response.status) {
        toast.success(response.message, {
          theme: "colored",
        });
        setIsLoading(false);
        dispatch(SingleInquiry(inquiryDetails?._id)).then(async (result) => {
          setCommentsData(result?.data.inquiryStatus);
        });
      } else {
        toast.error(response.message, {
          theme: "colored",
        });
        setIsLoading(false);
      }
    });
  };
  const inquiryTab = (tab) => {
    setCommentErr("");
    if (InquiryStep !== tab) {
      if (tab >= 0 && tab <= 3) {
        setInquiryStep(tab);
      }
    }
  };
  const statusTab = (tab) => {
    setCommentErr("");
    if (statusStep !== tab) {
      if (tab >= 0 && tab <= 3) {
        setStatusStep(tab);
      }
    }
  };

  const handleChangeReminder = (e) => {
    setReminderFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeReminderDate = (date) => {
    setReminderDate(new Date(date));
    setReminderFormData((prev) => ({
      ...prev,
      date: moment(date).format("MM/DD/YYYY"),
    }));
  };
  const submitReminders = async () => {
    // setIsLoading(true);
    const res = await dispatch(
      addReminder({
        inquiryId: inquiryDetails._id,
        title: reminderFormData.title,
        date: moment(reminderDate).format("MM/DD/YYYY"),
        description: reminderFormData.description,
        time: moment(reminderFormData.time, "HHmm").format("HH:mm"),
      })
    );
    // setIsLoading(true);
    if (res.status) {
      toast.success(res.message, {
        theme: "colored",
      });
      // setTimeout(() => {
      reminderModelToggle();
      // }, 1500);
      setIsLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setIsLoading(false);
    }
  };

  const reminderModelToggle = () => {
    setReminderModels((state) => !state);
  };

  const toggleRateForm = () => {
    setRateFormModels((state) => !state);
  };

  const handleDiscountRequiermentChange = (e) => {
    const { checked, value } = e.currentTarget;
    setUserDiscountRequierments((prev) =>
      checked
        ? [...prev, JSON.parse(value)]
        : prev.filter((val) => val._id !== JSON.parse(value)._id)
    );
  };

  const handleUserDiscountRate = async (id, userDiscuntRate) => {
    const data = {
      id: id,
      discountOnrequirement: userDiscountRequierments,
      userComment: userDiscuntRate,
    };
    const proData = {
      id: id,
      productionUserComment: userDiscuntRate,
    };

    dispatch(
      authUser?.userType == ROLES.PRODUCTION_USER
        ? addProdUserRate(proData)
        : addUserRate(data)
    ).then(async (response) => {
      if (response.status) {
        toast.success(response.message, {
          theme: "colored",
        });
        setIsLoading(false);
        setInquiryDetails(response?.data);
        authUser?.userType == ROLES.PRODUCTION_USER
          ? setProductionRatesData(response?.data?.productionUserComment)
          : setUserRatesData(response?.data?.userComment);
        setUserDiscountRate("");
        inquiryTab(2);
        toggleRateForm();
      } else {
        toast.error(response.message, {
          theme: "colored",
        });
        setIsLoading(false);
      }
    });
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <IsAuth />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <h4 className="mb-5">Inquiry Details</h4>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={(e) => navigate(-1)}
                    className="btn btncolor btn-rounded btn-fw pull-right mb-0"
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: "14px", marginRight: "10px" }}
                    ></i>
                    Back
                  </button>
                </div>
              </div>
              {Loader ? (
                <div className="text-center position-relative">
                  <ClipLoader size={50} color="#264B75" />
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs" role="tablist">
                      <NavItem
                        className={classnames({
                          current: InquiryStep == 0,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: InquiryStep == 0,
                          })}
                          onClick={() => {
                            inquiryTab(0);
                          }}
                        >
                          {" "}
                          Party Details
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: InquiryStep == 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: InquiryStep == 1,
                          })}
                          onClick={() => {
                            inquiryTab(1);
                          }}
                        >
                          {" "}
                          Inquiry Details
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({
                          current: InquiryStep == 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: InquiryStep == 2,
                          })}
                          onClick={() => {
                            inquiryTab(2);
                          }}
                        >
                          {" "}
                          Product Details
                        </NavLink>
                      </NavItem>

                      {/* <NavItem
                        className={classnames({
                          current: InquiryStep == 3,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: InquiryStep == 3,
                          })}
                          onClick={() => {
                            inquiryTab(3);
                          }}
                        >
                          {" "}
                          Rates
                        </NavLink>
                      </NavItem> */}
                      
                    </ul>

                    <TabContent activeTab={InquiryStep}>
                      <TabPane tabId={0}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    {inquiryDetails &&
                                    inquiryDetails?.companyName ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1">
                                              Company Name
                                            </h6>
                                            <small className="text-muted mb-0">
                                              {inquiryDetails &&
                                                inquiryDetails?.companyName}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}

                                    {inquiryDetails &&
                                    inquiryDetails?.partyName ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1">Party Name</h6>
                                            <small className="text-muted mb-0">
                                              {inquiryDetails &&
                                                inquiryDetails?.partyName}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                  <tr>
                                    {inquiryDetails && inquiryDetails?.email ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1">Email</h6>
                                            <small className="text-muted mb-0">
                                              {" "}
                                              {inquiryDetails &&
                                                inquiryDetails?.email}{" "}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}

                                    {inquiryDetails &&
                                    inquiryDetails?.contactNumber ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1">Contact No</h6>
                                            <small className="text-muted mb-0">
                                              {" "}
                                              {inquiryDetails &&
                                                inquiryDetails?.contactNumber}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                  <tr>
                                    {inquiryDetails &&
                                    inquiryDetails?.address ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1"> Location </h6>
                                            <small className="text-muted mb-0">
                                              {" "}
                                              {inquiryDetails &&
                                                inquiryDetails?.address}{" "}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}

                                    {(inquiryDetails && inquiryDetails?.city) ||
                                    (inquiryDetails && inquiryDetails?.state) ||
                                    (inquiryDetails &&
                                      inquiryDetails?.country) ? (
                                      <td width="50%">
                                        <div className="list d-flex align-items-center">
                                          <img
                                            className="img-sm rounded-circle"
                                            src={checkedPNG}
                                            alt=""
                                          />
                                          <div className="wrapper ml-3">
                                            <h6 className="mb-1">
                                              {" "}
                                              City / State / Country{" "}
                                            </h6>
                                            <small className="text-muted mb-0">
                                              {" "}
                                              {`${
                                                inquiryDetails &&
                                                inquiryDetails?.city
                                              }, ${
                                                inquiryDetails &&
                                                inquiryDetails?.state
                                              }, ${
                                                inquiryDetails &&
                                                inquiryDetails?.country
                                              }`}{" "}
                                            </small>
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={1}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table className="table">
                                    <tbody>
                                      {inquiryDetails &&
                                      inquiryDetails?.source ? (
                                        <tr>
                                          <td>
                                            <div className="list d-flex align-items-center">
                                              <img
                                                className="img-sm rounded-circle"
                                                src={checkedPNG}
                                                alt=""
                                              />
                                              <div className="wrapper ml-3">
                                                <h6 className="mb-1">
                                                  Source of Inquiry
                                                </h6>
                                                <small className="text-muted mb-0">
                                                  {" "}
                                                  {inquiryDetails &&
                                                    inquiryDetails?.source}
                                                </small>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : null}
                                      {inquiryDetails &&
                                      inquiryDetails?.person ? (
                                        <tr>
                                          <td>
                                            <div className="list d-flex align-items-center">
                                              <img
                                                className="img-sm rounded-circle"
                                                src={checkedPNG}
                                                alt=""
                                              />
                                              <div className="wrapper ml-3">
                                                <h6 className="mb-1">
                                                  Person of Inquiry
                                                </h6>
                                                <small className="text-muted mb-0">
                                                  {" "}
                                                  {inquiryDetails &&
                                                    inquiryDetails?.person}
                                                </small>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : null}
                                      {inquiryDetails &&
                                      inquiryDetails?.date ? (
                                        <tr>
                                          <td>
                                            <div className="list d-flex align-items-center">
                                              <img
                                                className="img-sm rounded-circle"
                                                src={checkedPNG}
                                                alt=""
                                              />
                                              <div className="wrapper ml-3">
                                                <h6 className="mb-1">
                                                  Date of Inquiry
                                                </h6>
                                                <small className="text-muted mb-0">
                                                  {" "}
                                                  {inquiryDetails &&
                                                    moment(
                                                      inquiryDetails?.date
                                                    ).format("LLL")}
                                                </small>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            {/* <!--End Row--> */}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead className="thead-light">
                                  <tr>
                                    <th>Action</th>
                                    <th>Requirements</th>
                                    <th>Units</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {authUser?.userType == ROLES.PRODUCTION_USER
                                    ? inquiryDetails &&
                                      inquiryDetails?.discountOnrequirement?.map(
                                        (item, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                <div className="form-check">
                                                  <label className="form-check-label form-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input form-check-input"
                                                      defaultChecked={
                                                        inquiryDetails?.discountOnrequirement?.find(
                                                          (item2) =>
                                                            item._id ==
                                                            item2._id
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      value={JSON.stringify(
                                                        item
                                                      )}
                                                      onChange={(e) => {
                                                        handleDiscountRequiermentChange(
                                                          e
                                                        );
                                                      }}
                                                      disabled={
                                                        authUser?.userType ==
                                                          ROLES.PRODUCTION_USER ||
                                                        inquiryDetails
                                                          ?.discountOnrequirement
                                                          ?.length
                                                      }
                                                    />
                                                    <i className="input-helper"></i>
                                                  </label>
                                                </div>
                                              </td>
                                              <td width="80%">
                                                {item?.requirement}
                                              </td>
                                              <td>{item?.units} Units</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : inquiryDetails &&
                                      inquiryDetails?.requirements?.map(
                                        (item, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                <div className="form-check">
                                                  <label className="form-check-label form-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input form-check-input"
                                                      defaultChecked={
                                                        inquiryDetails?.discountOnrequirement?.find(
                                                          (item2) =>
                                                            item._id ==
                                                            item2._id
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      value={JSON.stringify(
                                                        item
                                                      )}
                                                      onChange={(e) => {
                                                        handleDiscountRequiermentChange(
                                                          e
                                                        );
                                                      }}
                                                      // disabled={
                                                      //   authUser?.userType ==
                                                      //     ROLES.PRODUCTION_USER ||
                                                      //   inquiryDetails
                                                      //     ?.discountOnrequirement
                                                      //     ?.length
                                                      // }
                                                    />
                                                    <i className="input-helper"></i>
                                                  </label>
                                                </div>
                                              </td>
                                              <td width="80%">
                                                {item?.requirement}
                                              </td>
                                              <td>{item?.units} Units</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        {userRatesData ? (
                          <div className="row mt-4">
                            <div className="col-md-5">
                              {/* <h5>{authUser?.userType}</h5> */}
                              <div className="dotebox">
                                <span className="author text-left">
                                  <b> User: </b>
                                  {userRatesData}
                                </span>
                                {/* <span>February 27, 2023 2:05 PM</span> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {productionRatesData ? (
                          <div className="row mt-2">
                            <div className="col-md-5">
                              {/* <h5>Production Admin</h5> */}
                              <div className="dotebox">
                                <span className="author text-left">
                                  <b> Production Admin: </b>
                                  {productionRatesData}
                                </span>
                                {/* <span>February 27, 2023 2:05 PM</span> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {authUser?.userType == ROLES.PRODUCTION_USER ? (
                          <div className="row mt-4 align-items-center">
                            <div className="col-md-2 mt-1">
                              <button
                                onClick={() => toggleRateForm()}
                                className="btn btncolor btn-rounded btn-fw"
                              >
                                {inquiryDetails?.productionUserComment?'Update':'Add'} Rate
                              </button>
                            </div>
                          </div>
                        ) : authUser?.userType != ROLES.PRODUCTION_USER ? (
                          <div className="row mt-4 align-items-center">
                            <div className="col-md-2 mt-1">
                              <button
                                onClick={() => toggleRateForm()}
                                className="btn btncolor btn-rounded btn-fw"
                                disabled={userDiscountRequierments?.length == 0}
                              >
                                {inquiryDetails?.userComment?'Update':'Add'} Rate
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {authUser?.userType == ROLES.PRODUCTION_USER &&
                        !inquiryDetails?.productionUserComment ? (
                          <div className="row mt-4 align-items-center">
                            <div className="col-md-2 mt-1">
                              <button
                                onClick={() => toggleRateForm()}
                                className="btn btncolor btn-rounded btn-fw"
                              >
                                Add Rate
                              </button>
                            </div>
                          </div>
                        ) : authUser?.userType != ROLES.PRODUCTION_USER &&
                          !inquiryDetails?.userComment ? (
                          <div className="row mt-4 align-items-center">
                            <div className="col-md-2 mt-1">
                              <button
                                onClick={() => toggleRateForm()}
                                className="btn btncolor btn-rounded btn-fw"
                                disabled={userDiscountRequierments?.length == 0}
                              >
                                Add Rate
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </TabPane>
                      {/* {userDiscountRequierments?.length > 0 ? ( */}
                      {/* <TabPane tabId={3}>
                        {userRatesData ? (
                          <div className="row">
                            <div className="col-md-5">
                              <div className="dotebox">
                                <span className="author text-left">
                                  <b> User: </b>
                                  {userRatesData}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {productionRatesData ? (
                          <div className="row mt-4">
                            <div className="col-md-5">
                              <div className="dotebox">
                                <span className="author text-left">
                                  <b> Production Admin: </b>
                                  {productionRatesData}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPane> */}
                      {/* ) : (
                  ""
                )} */}
                    </TabContent>
                    {authUser?.userType != ROLES.PRODUCTION_USER ? (
                      <div>
                        <div className="row mt-4">
                          <div className="col-md-6">
                            <span className="staindo">Status Information</span>
                          </div>
                          <div className="col-md-6">
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btncolor btn-rounded btn-fw"
                                onClick={() => {
                                  reminderModelToggle();
                                  setReminderFormData({
                                    title: "",
                                    date: "",
                                    time: "",
                                    description: "",
                                  });
                                  setReminderDate(new Date());
                                }}
                              >
                                {" "}
                                Add Reminder{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <ul
                          className="nav nav-tabs card-header-tabs arrow-main-div "
                          id="innerTab"
                          role="tablist"
                        >
                          {isLoading ? (
                            <SmallLoader />
                          ) : (
                            commentsData &&
                            commentsData?.map((item, i) => (
                              <NavItem
                                key={i}
                                className={classnames({
                                  current: statusStep == i,
                                  "tab-arrow": true,
                                })}
                              >
                                <NavLink
                                  key={i}
                                  className={classnames({
                                    active: statusStep == i,
                                  })}
                                  onClick={() => {
                                    statusTab(i);
                                  }}
                                  disabled={inquiryDetails?.stepStatus == "won"}
                                >
                                  {" "}
                                  <div
                                    className={classnames({
                                      active: statusStep == i,
                                      incompleted:
                                        item?.status == 0 ? true : false,
                                      completed:
                                        item?.status == 1 ? true : false,
                                      "arrow-pointer bordr-left-none": true,
                                    })}
                                  >
                                    <p className="arrowp">
                                      {item?.stepStatus.toUpperCase()}
                                    </p>
                                  </div>
                                  {item?.status && item?.date ? (
                                    <span className="arrowspan">
                                      {moment(item?.date).format("LLL")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </NavLink>
                              </NavItem>
                            ))
                          )}
                        </ul>
                        <TabContent activeTab={statusStep}>
                          {isLoading ? (
                            <SmallLoader />
                          ) : (
                            commentsData &&
                            commentsData?.map((item, i) => (
                              <TabPane tabId={i}>
                                {item?.remark ? (
                                  <>
                                    <div className="row">
                                      {i == 0 ? (
                                        <>
                                          <div className="col-md-3">
                                            <div className="dotebox">
                                              <span>
                                                {" "}
                                                <strong> {item.remark} </strong>
                                              </span>
                                              <span>
                                                {moment(item?.date).format(
                                                  "LLL"
                                                )}
                                              </span>
                                              {inquiryDetails?.stepStatus !==
                                              "won" ? (
                                                <div className="close-btn">
                                                  <Link
                                                    onClick={() =>
                                                      commentDelete(
                                                        inquiryDetails?._id,
                                                        item?.stepStatus
                                                      )
                                                    }
                                                  >
                                                    <img src={closePNG} />
                                                  </Link>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-9"></div>
                                        </>
                                      ) : i == 1 ? (
                                        <>
                                          <div className="col-md-3"></div>
                                          <div className="col-md-3">
                                            <div className="dotebox">
                                              <span>
                                                {" "}
                                                <strong> {item.remark} </strong>
                                              </span>
                                              <span>
                                                {moment(item?.date).format(
                                                  "LLL"
                                                )}
                                              </span>
                                              {inquiryDetails?.stepStatus !==
                                              "won" ? (
                                                <div className="close-btn">
                                                  <Link
                                                    onClick={() =>
                                                      commentDelete(
                                                        inquiryDetails?._id,
                                                        item?.stepStatus
                                                      )
                                                    }
                                                  >
                                                    <img src={closePNG} />
                                                  </Link>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-6"></div>
                                        </>
                                      ) : i == 2 ? (
                                        <>
                                          <div className="col-md-3"></div>
                                          <div className="col-md-3"></div>
                                          <div className="col-md-3">
                                            <div className="dotebox">
                                              <span>
                                                {" "}
                                                <strong> {item.remark} </strong>
                                              </span>
                                              <span>
                                                {moment(item?.date).format(
                                                  "LLL"
                                                )}
                                              </span>
                                              {inquiryDetails?.stepStatus !==
                                              "won" ? (
                                                <div className="close-btn">
                                                  <Link
                                                    onClick={() =>
                                                      commentDelete(
                                                        inquiryDetails?._id,
                                                        item?.stepStatus
                                                      )
                                                    }
                                                  >
                                                    <img src={closePNG} />
                                                  </Link>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-3"></div>
                                        </>
                                      ) : i == 3 ? (
                                        <>
                                          <div className="col-md-9"></div>
                                          <div className="col-md-3">
                                            <div className="dotebox">
                                              <span>
                                                {" "}
                                                <strong> {item.remark} </strong>
                                              </span>
                                              <span>
                                                {moment(item?.date).format(
                                                  "LLL"
                                                )}
                                              </span>
                                              {inquiryDetails?.stepStatus !==
                                              "won" ? (
                                                <div className="close-btn">
                                                  <Link
                                                    onClick={() =>
                                                      commentDelete(
                                                        inquiryDetails?._id,
                                                        item?.stepStatus
                                                      )
                                                    }
                                                  >
                                                    <img src={closePNG} />
                                                  </Link>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {inquiryDetails?.stepStatus == "won" ? (
                                  ""
                                ) : !item?.status || !item?.remark ? (
                                  <div
                                    className="row mt-4 align-items-center"
                                    id="html-show"
                                  >
                                    <div className="col-md-10">
                                      <div className="form-group">
                                        <label
                                          htmlFor="comments"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item?.stepStatus} Comments
                                        </label>
                                        <Input
                                          className="form-control emailinput"
                                          id="comments"
                                          rows="4"
                                          placeholder="Enter Comments..."
                                          onChange={(e) =>
                                            handleChangeComments(e)
                                          }
                                          value={comment ? comment : ""}
                                          invalid={commentErr ? true : false}
                                        />
                                        {commentErr ? (
                                          <FormFeedback type="invalid">
                                            {commentErr}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        type="button"
                                        className="btn btncolor btn-rounded btn-fw"
                                        onClick={() => {
                                          commentSubmit(
                                            inquiryDetails?._id,
                                            item?.stepStatus,
                                            comment
                                          );
                                        }}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </TabPane>
                            ))
                          )}
                        </TabContent>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Reminder Models */}
      <Modal isOpen={reminderModels} toggle={reminderModelToggle}>
        <ModalHeader toggle={reminderModelToggle}>Add Reminder</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="title">
                  Add Title <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control emailinput"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={reminderFormData?.title}
                  onChange={(e) => handleChangeReminder(e)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <Label htmlFor="date">Date </Label>
                <DatePicker
                  id="date"
                  selected={reminderDate}
                  onChange={handleChangeReminderDate}
                  name="date"
                  className="form-control emailinput"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <Label htmlFor="time">Time </Label>
                <Input
                  id="time"
                  name="time"
                  type="time"
                  className="form-control emailinput"
                  value={reminderFormData?.time}
                  onChange={(e) => handleChangeReminder(e)}
                ></Input>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="description">
                  Add Description <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  rows="5"
                  className="form-control emailinput"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  onChange={(e) => handleChangeReminder(e)}
                  value={reminderFormData?.description}
                ></textarea>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btncolor btn-rounded btn-fw"
            onClick={(e) => submitReminders(e)}
            disabled={isLoading}
          >
            Submit
          </button>

          <button
            type="button"
            className="btn btncolor btn-rounded btn-fw"
            style={{ backgroundColor: "#ACACAC" }}
            onClick={reminderModelToggle}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>

      {/* Rates */}
      <Modal isOpen={rateFormModels} toggle={toggleRateForm}>
        <ModalHeader toggle={toggleRateForm}>Add Rate</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-0">
                <label htmlFor="rates">Requirements Rate</label>
                <input
                  id="rates"
                  rows="4"
                  placeholder="Enter Rates..."
                  type="text"
                  className="form-control emailinput form-control"
                  aria-invalid="false"
                  onChange={(e) =>
                    setUserDiscountRate(
                      e.target.value.replace(/^\s+|\s+$/gm, " ")
                    )
                  }
                  value={userDiscountRate}
                />
              </div>
              {userDiscountRate ? (
                <button
                  onClick={() => {
                    handleUserDiscountRate(
                      inquiryDetails?._id,
                      userDiscountRate
                    );
                  }}
                  className="btn btncolor btn-rounded pull-right btn-fw mt-3"
                  disabled={!userDiscountRate}
                >
                  Submit Rate
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
