import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Footer from "../../../layout/Footer";
import {
  deleteReminder,
  fetchAllReminders,
  updateReminder,
} from "../../../redux/actions/reminder";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../layout/FilterComponent";
import { Link } from "react-router-dom";
import ToggleSwitch from "./ToggleSwitch";
import moment from "moment-timezone";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from "react-spinners";
import IsAuth from "../../auth/IsAuth";
const InquiryReminder = () => {
  const dispatch = useDispatch();
  document.title = "Inquiry Reminders | Glasier Wellness";
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { reminder } = useSelector((state) => state.reminder);
  const [reminderList, setReminderList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoding] = useState(false);
  const [reminderModels, setReminderModels] = useState(false);
  const [reminderDate, setReminderDate] = useState(new Date());
  const getInquiryReminder = async () => {
    setIsLoding(true);
    await dispatch(fetchAllReminders({ inquiryReminder: true })).then(
      async (res) => {
        if (res.status) {
          setIsLoding(false);
        } else {
          setIsLoding(false);
        }
      }
    );
  };

  useEffect(() => {
    getInquiryReminder();
  }, [dispatch]);

  useEffect(() => {
    setReminderList(reminder);
  }, [reminder]);

  // useEffect(() => {
  //   let arr = [];
  //   inquiries.forEach((element) => {
  //     let reminders = [];
  //     reminders = reminder?.filter(
  //       (item) => item.inquiryId === element._id
  //     );
  //     let data = {
  //       ...element,
  //       reminders,
  //     };
  //     if (reminders.length !== 0) {
  //       arr.push(data);
  //     }
  //   });
  //   // setInquiryList(arr);
  // }, [dispatch, reminder, inquiries]);

  const [reminderFormData, setReminderFormData] = useState({
    title: "",
    description: "",
    date: "",
  });

  const handleChangeReminder = (e) => {
    setReminderFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeReminderDate = (date) => {
    setReminderDate(new Date(date));
    setReminderFormData((prev) => ({
      ...prev,
      date: moment(date).format("MM/DD/YYYY"),
    }));
  };

  const editReminder = (id, title, description, date, time) => {
    reminderModelToggle();
    setReminderFormData({
      id: id,
      title: title,
      description: description,
      date: date,
    });
    setReminderDate(new Date(date));
  };
  const updateReminders = async () => {
    // setIsLoding(true);
    const res = await dispatch(updateReminder(reminderFormData));
    if (res.status) {
      toast.success(res.message, {
        theme: "colored",
      });
      reminderModelToggle();
      setReminderFormData({
        title: "",
        description: "",
        date: "",
      });
      setReminderDate(new Date());
      setIsLoding(false);
      getInquiryReminder();
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setIsLoding(false);
    }
  };

  const deleteReminders = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteReminder({ id: id }));
        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setIsLoding(false);
        } else {
          Swal.fire("Error!", res.message, "error");
          setIsLoding(false);
        }
      }
    });
  };

  const reminderModelToggle = () => {
    setReminderModels((state) => !state);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "200px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      // width: "400px",
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      // width: "500px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "100px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "200px",
    },
  ];
  const rows = [
    ...reminderList
      ?.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.description &&
            item.description.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((row, order) => ({
        sno: order + 1,
        newDate:
          moment(row?.date).format("ll") < moment(new Date()).format("ll"),
        date: (
          <>
            <span>
              {moment(row?.date).format("ll")}
              {moment(row?.date).format("ll") < moment(new Date()).format("ll")}
            </span>
          </>
        ),
        title: <>{row?.title}</>,
        notes: <>{row?.description}</>,
        status: (
          <ToggleSwitch
            key={order}
            newStatus={row?.status}
            id={row?._id}
            order={order}
          />
        ),
        action: (
          <>
            <Link
              to={`/${authUser?.userType.toLowerCase()}/view-inquiry/${
                row.inquiryId
              }`}
              className="iconbgview"
            >
              <i className="fa fa-eye eyeicon"></i>
            </Link>
            <Link
              // to={`/`}
              className="iconbgedit"
              title="Edit"
              onClick={(id, title, description, date, time) =>
                editReminder(
                  row?._id,
                  row?.title,
                  row?.description,
                  row?.date,
                  row?.time
                )
              }
            >
              <i className="fa fa-edit eyeicon"></i>
            </Link>
            <button
              onClick={(id) => deleteReminders(row?._id)}
              type="button"
              className="iconbgdelete ml-2"
              title="Delete"
            >
              <i className="fa fa-trash eyeicon"></i>
            </button>
          </>
        ),
      })),
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const conditionalRowStyles = [
    {
      when: (row) => row.newDate,
      style: (row) => ({ backgroundColor: row.newDate ? "#f1f1f1" : "inerit" }),
    },
  ];

  return (
    <>
    <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <Card>
            <CardBody>
              <Row>
                <Col className="md-12 grid-margin stretch-card">
                  <Card>
                    <CardBody>
                      <h4>All Inquiry Reminder</h4>
                      <Row className="mt-3">
                        <Col className="md-12">
                          <div className="table-responsive">
                            <DataTable
                              columns={columns}
                              data={rows}
                              pagination
                              progressPending={isLoading}
                              progressComponent={
                                <ClipLoader size={50} color="#264B75" />
                              }
                              customStyles={customStyles}
                              conditionalRowStyles={conditionalRowStyles}
                              subHeader
                              subHeaderComponent={subHeaderComponentMemo}
                              pointerOnHover
                              // dense
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Footer />
      </div>

      {/* Reminder Model */}
      <Modal isOpen={reminderModels} toggle={reminderModelToggle}>
        <ModalHeader toggle={reminderModelToggle}>Add Reminder</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="title">
                  Add Title <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control emailinput"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={reminderFormData?.title}
                  onChange={(e) => handleChangeReminder(e)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <Label htmlFor="date">Date </Label>
                <DatePicker
                  id="date"
                  selected={reminderDate}
                  onChange={handleChangeReminderDate}
                  name="date"
                  className="form-control emailinput"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="description">
                  Add Description <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  rows="5"
                  className="form-control emailinput"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  onChange={(e) => handleChangeReminder(e)}
                  value={reminderFormData?.description}
                ></textarea>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btncolor btn-rounded btn-fw"
            onClick={(e) => updateReminders(e)}
            disabled={isLoading}
          >
            Update
          </button>{" "}
          <button
            type="button"
            className="btn btncolor btn-rounded btn-fw"
            style={{ backgroundColor: "#ACACAC" }}
            onClick={reminderModelToggle}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>

      {/* Modal Ends */}
    </>
  );
};

export default InquiryReminder;
