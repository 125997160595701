import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
} from "reactstrap";
import {
  addRequirments,
} from "../../../../redux/actions/requirments";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../../layout/FilterComponent";
import { ClipLoader } from "react-spinners";
import * as Yup from "yup";
export default function RequirementSelect({ handlePrevStep, data }) {
  document.title = `Select Requirment | Glasier Wellness`;
  const dispatch = useDispatch();
  const { requirment, loading } = useSelector((state) => state.requirment);
  const [requirementFormData] = useState({
    requirement: "",
    units: "",
    perUnitPrice: "",
  });
  const [requirmentModels, setRequirmentModels] = useState(false);
  const [requirementList, setRequirementList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requirementArray, setRequirementArray] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [Loader, setLoader] = useState(false);

  useEffect(() => {
    setRequirementList(requirment);
    setIsLoading(loading);
  }, [requirment]);

  useEffect(() => {
    let object = data?.requirements.reduce(
      (obj, item) => Object.assign(obj, { [item.requirementId]: item.units }),
      {}
    );
    setRequirementArray(object);
  }, []);

  // const handleChangeRequirment = (e) => {
  //   setRequirementFormData((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const submitRequirment = async () => {
  //   setIsLoading(true);
  //   const res = await dispatch(addRequirments(requirementFormData));
  //   if (res.status) {
  //     toast.success(res.message, {
  //       theme: "colored",
  //     });
  //     setRequirementFormData({});
  //     setTimeout(() => {
  //       requirmentModelToggle();
  //     }, 1000);
  //     setIsLoading(false);
  //   } else {
  //     toast.error(res.message, {
  //       theme: "colored",
  //     });
  //     setIsLoading(false);
  //   }
  // };

  // const updateRequirments = async () => {
  //   setIsLoading(true);
  //   const res = await dispatch(updateRequirment(requirementFormData));
  //   if (res.status) {
  //     toast.success(res.message, {
  //       theme: "colored",
  //     });
  //     setRequirementFormData({});
  //     setIsLoading(false);
  //     setTimeout(() => {
  //       requirmentModelToggle();
  //     }, 1000);
  //   } else {
  //     toast.error(res.message, {
  //       theme: "colored",
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const handleUnit = (e, id) => {
    const { value } = e.target;
    setRequirementArray((prev) => ({
      ...prev,
      [id]: value.replace(/^\s+|\s+$/gm, " "),
    }));
  };

  let reqData = [];
  let reqDataNew = [];
  const handleSubmit = async () => {
    setLoader(true);
    Object.entries(requirementArray).map(([key, value]) => {
      reqData.push({
        requirementId: key,
        units: value,
      });
    });
    reqData
      ?.filter((item, i) => item.units != "")
      .map((item) => {
        reqDataNew.push(item);
      });
    setTimeout(async () => {
      setLoader(false);
      await handlePrevStep(data, reqDataNew, "back");
    }, 600);
  };
  const requirmentModelToggle = () => {
    setRequirmentModels((state) => !state);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: requirementFormData,
    validationSchema: Yup.object({
      requirement: Yup.string().required("Please Enter Requirement"),
      // units: Yup.string().required("Please Enter Units"),
      // perUnitPrice: Yup.string().required("Please Enter Price/Units"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const res = await dispatch(addRequirments(values));
      if (res.status) {
        toast.success(res.message, {
          theme: "colored",
        });
        setRequirementList([...requirementList, res?.data]);
        setTimeout(() => {
          requirmentModelToggle();
        }, 1000);
        setIsLoading(false);
        resetForm();
      } else {
        toast.error(res.message, {
          theme: "colored",
        });
        setIsLoading(false);
      }
    },
  });

  const columns = [
    {
      name: "Requirements",
      selector: (row) => row.requirement,
    },
    {
      name: "Price/Units",
      selector: (row) => row.perUnitPrice,
      width: "200px",
    },
    {
      name: "Units",
      selector: (row) => row.units,
      width: "250px",
    },
  ];
  const rows = [
    ...requirementList
      ?.filter(
        (item) =>
          item.requirement &&
          item.requirement.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((row, i) => ({
        selected: requirementArray && requirementArray[row._id] ? true : false,
        action: (
          <div key={i} className="form-check">
            <Label className="form-check-label">
              <Input
                type="checkbox"
                className="form-check-input"
                // value={JSON.stringify({ id: row._id })}
                value={requirementArray ? requirementArray[row._id] : ""}
                checked={
                  requirementArray && requirementArray[row._id] ? true : false
                }
              />
              <i className="input-helper"></i>
            </Label>
          </div>
        ),
        requirement: row.requirement,
        perUnitPrice: row.perUnitPrice,
        units: (
          <div key={i} className="textbox-round">
            <div className="form-group">
              <input
                type="text"
                className="form-control emailinput"
                placeholder="Enter Units"
                name="units"
                value={
                  requirementArray && requirementArray[row._id]
                    ? requirementArray[row._id]
                    : ""
                }
                onChange={(e, id) => handleUnit(e, row._id, row.requirement)}
                style={{
                  margin: "5px 0",
                  height: "45px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          </div>
        ),
      })),
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.selected,
      style: (row) => ({
        backgroundColor: row.selected ? "#f0f0f0" : "inerit",
      }),
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);
  return (
    <>
      <Row>
        <Col className="text-left">
          <button
            className="btn btncolor btn-rounded btn-fw mr-2"
            onClick={() => requirmentModelToggle()}
            style={{ position: "absolute", zIndex: 9 }}
          >
            Add Requirement
          </button>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12">
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={rows}
              progressPending={isLoading}
              progressComponent={
                <div style={{ position: "relative" }}>
                  <ClipLoader size={30} color="#264B75" />
                </div>
              }
              pagination
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              pointerOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </div>

          <div className="row mt-4 ">
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="btn btncolor btn-rounded btn-fw"
                disabled={Loader}
                onClick={handleSubmit}
              >
                Submit
                {Loader ? (
                  <ClipLoader size={20} color="#fff" className="ml-2" />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </Row>

      <Modal isOpen={requirmentModels} toggle={requirmentModelToggle}>
        <ModalHeader toggle={requirmentModelToggle}>
          <h5 className="modal-title">Add Requirement</h5>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="requirement">
                    Add Requirements <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control emailinput"
                    id="requirement"
                    placeholder="Enter Requirement Name*"
                    name="requirement"
                    invalid={
                      validation.touched.requirement &&
                      validation.errors.requirement
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.requirement}
                  />
                  {validation.touched.requirement &&
                  validation.errors.requirement ? (
                    <FormFeedback type="invalid">
                      {validation.errors.requirement}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="units">Units</label>
                  <Input
                    type="text"
                    className="form-control emailinput"
                    id="units"
                    name="units"
                    placeholder="Enter Units"
                    invalid={
                      validation.touched.units && validation.errors.units
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.units}
                  />
                  {validation.touched.units && validation.errors.units ? (
                    <FormFeedback type="invalid">
                      {validation.errors.units}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="perUnitPrice">Price/Units</label>
                  <Input
                    type="number"
                    className="form-control emailinput"
                    id="perUnitPrice"
                    name="perUnitPrice"
                    placeholder="Enter Price/Units*"
                    invalid={
                      validation.touched.perUnitPrice &&
                      validation.errors.perUnitPrice
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.perUnitPrice}
                  />
                  {validation.touched.perUnitPrice &&
                  validation.errors.perUnitPrice ? (
                    <FormFeedback type="invalid">
                      {validation.errors.perUnitPrice}
                    </FormFeedback>
                  ) : null}
                </div>
              </div> */}
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btncolor btn-rounded btn-fw"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}
