import React, { useState } from "react";
import "./App.css";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import SideBar from "./layout/SideBar";
import Header from "./layout/Header";
import pathPNG from "./assets/img/Path.png";
import PageNotFound from "./PageNotFound";
import "./assets/css/custom.css";
import Layout from "./routes/Layout";
import Dashboard from "./components/CommonPages/Dashboard";
import Profile from "./components/CommonPages/Profile";
import EditProfile from "./components/CommonPages/EditProfile";
// ADMIN ROUTES
import AdminDashboard from "./components/CommonPages/Dashboard";
import InquiryDashBoard from "./components/Admin/Inquiry/InquiryDashBoard";
import AdminProfile from "./components/CommonPages/Profile";
import SourceList from "./components/Admin/sources/SourceList";
import UserList from "./components/Admin/users/UserList";
import EditSource from "./components/Admin/sources/EditSource";
import AddSource from "./components/Admin/sources/AddSource";
import InquiryDetails from "./components/Admin/Inquiry/InquiryDetailsView/InquiryDetails";
import AddUser from "./components/Admin/users/AddUser";
import EditUser from "./components/Admin/users/EditUser";
import AdminEditProfile from "./components/CommonPages/EditProfile";
import AddInquiryNew from "./components/Admin/Inquiry/NewInquiry/AddNewInquiry";
import UpdateInquiry from "./components/Admin/Inquiry/UpdateInquiry/UpdateInquiry";
import AdminUserEditProfile from "./components/Admin/UserEditProfile";
import AdminTodoList from "./components/CommonPages/TodoList";
import AdminInquiryReminder from "./components/CommonPages/InquiryReminder/InquiryReminder";

// STAFF ROUTE
import StaffDashboard from "./components/CommonPages/Dashboard";
import StaffInquiryReminder from "./components/CommonPages/InquiryReminder/InquiryReminder";
import StaffInquiryReport from "./components/CommonPages/InquiryReport/InquiryReport";
import StaffProfile from "./components/CommonPages/Profile";
import StaffEditProfile from "./components/CommonPages/EditProfile";
import StaffTodoList from "./components/CommonPages/TodoList";

// MANAGER ROUTE
import ManagerDashboard from "./components/CommonPages/Dashboard";
import ManagerProfile from "./components/CommonPages/Profile";
import ManagerEditProfile from "./components/CommonPages/EditProfile";
import ManagerInquiryReminder from "./components/CommonPages/InquiryReminder/InquiryReminder";
import ManagerReport from "./components/Admin/Inquiry/InquiryDetailsView/InquiryDetails";
import ManagerInquiryReport from "./components/CommonPages/InquiryReport/InquiryReport";
import ManagerTodoList from "./components/CommonPages/TodoList";

import ScrollToTop from "./common/ScrollToTop";
import Notification from "./components/CommonPages/Notification";
import Requirements from "./components/CommonPages/Requirements/Requirements";
import Category from "./components/CommonPages/Gallery/Category";
import Products from "./components/CommonPages/Gallery/Products";
import ProductGallery from "./components/CommonPages/Gallery/ProductGallery";
import ProductShare from "./layout/ShareLayout/ProductShare";
import GalleryShare from "./layout/ShareLayout/GalleryShare";
import InquiryView from "./components/Admin/Inquiry/InquiryDetailsView/InquiryView";
import RateInquiry from "./components/Admin/Inquiry/RatedInquiry/RateInquiry";
import ThousandUnitInquiry from "./components/Admin/Inquiry/ThousandUnitInquiry/ThousandUnitInquiry";
import ChatRoom from "./common/ChatModule/ChatRoom";
import { io } from "socket.io-client";
import { ROLES } from "./common/constant";
import CategoryShare from "./layout/ShareLayout/CategoryShare";
import MultipleImagesShare from "./layout/ShareLayout/MultipleImagesShare";
import MultiCategoryProductShare from "./layout/ShareLayout/MultiCategoryProductShare";
const socket = io.connect(process.env.REACT_APP_API_URL);

function App() {
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login socket={socket} />} />
          <Route path="/logout/*" element={<Logout socket={socket} />} />
          <Route path="/" element={<Layout />}>
            {/* Chat Route */}
            <Route
              index
              path="/chat"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/chat" />
                    <ChatRoom socket={socket} />
                  </div>
                </div>
              }
            />
            {authUser?.userType === ROLES.PRODUCTION_USER ? (
              <Route
                path="/"
                element={
                  <div className="container-scroller">
                    <Header />
                    <div className="container-fluid page-body-wrapper">
                      <img src={pathPNG} className="desh-bg" />
                      <SideBar path="/" />
                      <Profile />
                    </div>
                  </div>
                }
              />
            ) : (
              <Route
                path="/"
                element={
                  <div className="container-scroller">
                    <Header />
                    <div className="container-fluid page-body-wrapper">
                      <img src={pathPNG} className="desh-bg" />
                      <SideBar path="/" />
                      <Dashboard />
                    </div>
                  </div>
                }
              />
            )}

            {/* ADMIN ROUTES */}
            <Route
              index
              path="/admin/dashboard"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/dashboard" />
                    <AdminDashboard />
                  </div>
                </div>
              }
            />
            <Route
              index
              path="/admin/source-list"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/source-list" />
                    <SourceList />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/users"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/users" />
                    {/* <AdminUserView /> */}
                    <UserList />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/user-edit-profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/user-edit-profile" />
                    <AdminUserEditProfile />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/source/:id"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/source/:id" />
                    <EditSource />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/add-source"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/add-source" />
                    <AddSource />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/users/:id"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/users/:id" />
                    <EditUser />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/inquiry-dashboard"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/inquiry-dashboard" />
                    <InquiryDashBoard />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/inquiry-details"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/inquiry-details" />
                    <InquiryDetails />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/add-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/add-inquiry" />
                    <AddInquiryNew />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/view-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/view-inquiry" />
                    <InquiryView />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/edit-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/edit-inquiry" />
                    <UpdateInquiry />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/update-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/update-inquiry" />
                    <UpdateInquiry />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/edit-profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/edit-profile" />
                    <AdminEditProfile />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/profile" />
                    <AdminProfile />
                  </div>
                </div>
              }
            />
            <Route
              index
              path="/admin/add-users"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/add-users" />
                    <AddUser />
                  </div>
                </div>
              }
            />
            {/* <Route
                  index
                  path="/admin/select-requirements"
                  element={
                    <div className="container-scroller">
                      <Header />
                      <div className="container-fluid page-body-wrapper">
                        <img src={pathPNG} className="desh-bg" />
                        <SideBar path="/admin/select-requirements" />
                        <SelectRequirment />
                      </div>
                    </div>
                  }
                /> */}

            <Route
              index
              path="/admin/inquiry-reminder"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/inquiry-reminder" />
                    <AdminInquiryReminder />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/admin/todo-list"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/admin/todo-list" />
                    <AdminTodoList />
                  </div>
                </div>
              }
            />

            {/* MANAGER ROUTES */}
            <Route
              index
              path="/manager/dashboard"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/dashboard" />
                    <ManagerDashboard />
                  </div>
                </div>
              }
            />
            <Route
              index
              path="/manager/profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/profile" />
                    <ManagerProfile />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/edit-profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/edit-profile" />
                    <ManagerEditProfile />
                  </div>
                </div>
              }
            />
            <Route
              index
              path="/manager/add-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/add-inquiry" />
                    <AddInquiryNew />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/inquiry-reminder"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/inquiry-reminder" />
                    <ManagerInquiryReminder />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/inquiry-report"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/inquiry-report" />
                    <ManagerInquiryReport />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/manager-report"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/manager-report" />
                    <ManagerReport />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/todo-list"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/todo-list" />
                    <ManagerTodoList />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/view-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/view-inquiry" />
                    <InquiryView />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/manager/edit-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/manager/edit-inquiry" />
                    <UpdateInquiry />
                  </div>
                </div>
              }
            />

            {/* STAFF ROUTES */}
            <Route
              index
              path="/staff/dashboard"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/dashboard" />
                    <StaffDashboard />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/profile" />
                    <StaffProfile />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/edit-profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/edit-profile" />
                    <StaffEditProfile />
                  </div>
                </div>
              }
            />
            <Route
              index
              path="/staff/inquiry-reminder"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/inquiry-reminder" />
                    <StaffInquiryReminder />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/inquiry-report"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/inquiry-report" />
                    <StaffInquiryReport />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/todo-list"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/todo-list" />
                    <StaffTodoList />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/add-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/add-inquiry" />
                    <AddInquiryNew />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/view-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/view-inquiry" />
                    <InquiryView />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/staff/edit-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/staff/edit-inquiry" />
                    <UpdateInquiry />
                  </div>
                </div>
              }
            />

            {/* notifications */}
            <Route
              index
              path="/notification"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/notification" />
                    <Notification />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/dashboard"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/dashboard" />
                    {/* <Dashboard /> */}
                    <Profile />
                  </div>
                </div>
              }
            />

            {/* Profile */}
            <Route
              index
              path="/profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/profile" />
                    <Profile />
                  </div>
                </div>
              }
            />
            {/* Edit Profile */}
            <Route
              index
              path="/edit-profile"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/edit-profile" />
                    <EditProfile />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/requirements"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/requirements" />
                    <Requirements />
                  </div>
                </div>
              }
            />

            {/* Product Category Box */}
            <Route
              index
              path="/product-gallery"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/product-gallery" />
                    <Category />
                  </div>
                </div>
              }
            />

            {/* Product Box by CategoryID*/}
            <Route
              index
              path="/products/:catId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/products/:catId" />
                    <Products />
                  </div>
                </div>
              }
            />

            {/* Product Images */}
            <Route
              index
              path="/gallery/:catId/:productId/"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/gallery/" />
                    <ProductGallery />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/rated-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/rated-inquiry" />
                    <RateInquiry />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/thousand-unit-inquiry"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/thousand-unit-inquiry" />
                    <ThousandUnitInquiry />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/view-inquiry/:inquiryId"
              element={
                <div className="container-scroller">
                  <Header />
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <SideBar path="/view-inquiry" />
                    <InquiryView />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/share/gallery/:catId/:productId"
              element={
                <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <GalleryShare />
                  </div>
                </div>
              }
            />

              {/* Share Multiple Images */}
            <Route
              index
              path="/share/images/"
              element={
                <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <MultipleImagesShare />
                  </div>
                </div>
              }
            />

            <Route
              index
              path="/share/products/:catId"
              element={
                <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <ProductShare />
                  </div>
                </div>
              }
            />



            {/* Multiple Category Share */}
            <Route
              index
              path="/share/category/"
              element={
                <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <CategoryShare />
                  </div>
                </div>
              }
            />
              
            {/* Multiple Product Share without category */}
            <Route
              index
              path="/share/products/"
              element={
                <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <img src={pathPNG} className="desh-bg" />
                    <MultiCategoryProductShare />
                  </div>
                </div>
              }
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default React.memo(App);
