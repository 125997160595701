import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
  FETCH_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS_REQUEST,
CLEAR_NOTIFICATIONS_SUCCESS,
CLEAR_NOTIFICATIONS_FAIL,
} from "../../constants/notificationConstants";

//Fetch All Notification
export const getNotifications = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
        let data ={};
      dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });
      const response = await axios.post(API_URLS.NOTIFICATION_LIST, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_NOTIFICATIONS_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_NOTIFICATIONS_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

export const clearNotifications = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
        let data ={};
      dispatch({ type: CLEAR_NOTIFICATIONS_REQUEST });
      const response = await axios.post(API_URLS.CLEAR_NOTIFICATION, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: CLEAR_NOTIFICATIONS_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: CLEAR_NOTIFICATIONS_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: CLEAR_NOTIFICATIONS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
