import {
  FETCH_PRODUCT_FAIL,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAIL,
  FETCH_PRODUCT_IMAGES_REQUEST,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  FETCH_PRODUCT_IMAGES_FAIL,
} from "../../constants/productConstants";

let initialState = {
  categories: [],
  products: [],
  productImages: [],
  error: null,
  loading: false,
};

export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case FETCH_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        products: [],
        error: action.payload,
      };
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case FETCH_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        categories:[],
        error: action.payload,
      };

      case FETCH_PRODUCT_IMAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        productImages: action?.payload?.attachPhotos,
      };
    case FETCH_PRODUCT_IMAGES_FAIL:
      return {
        ...state,
        loading: false,
        productImages:[],
        error: action.payload,
      };
    default:
      return state;
  }
};
