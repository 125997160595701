import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
} from "../../constants/userConstants";


let initialState = {
    authUser: [],
    loading: false,
    error: null
}
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                error:null
            };
        case LOGIN_SUCCESS:
            let data = {
                ...state,
                loading: false,
                isAuthenticated: true,
                authUser: action.payload,
                error:null
            };
            return data;

        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                authUser: [],
                error: action.payload,
            };

        default:
            return state;
    }
};

export const signUpReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case SIGNUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                user: null,
            };
        case SIGNUP_SUCCESS:
            let data = {
                ...state,
                loading: false,
                user: action.payload,
                error: null,
            };
            return data;

        case SIGNUP_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload.message,
            };

        default:
            return state;
    }
};