import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
  FETCH_PRODUCT_FAIL,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  FETCH_PRODUCT_IMAGES_REQUEST,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  FETCH_PRODUCT_IMAGES_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from "../../constants/productConstants";

//Fetch All Categories
export const getAllCategories = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_CATEGORY_REQUEST });
      const response = await axios.post(
        API_URLS.GET_PRODUCT_CATEGORY,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_CATEGORY_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_CATEGORY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_CATEGORY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Fetch All Products
export const getAllProducts = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_PRODUCT_REQUEST });
      const response = await axios.post(API_URLS.GET_PRODUCTS, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Fetch All Products
export const getMultipleShareProducts = (data) => {
  return async (dispatch) => {
    // const Token = localStorage.getItem("accessToken");
    const config = {
      // headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_PRODUCT_REQUEST });
      const response = await axios.post(API_URLS.GET_MULTIPLE_PRODUCTS, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Fetch Product Images
export const getProductImages = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_PRODUCT_IMAGES_REQUEST });
      const response = await axios.post(
        API_URLS.GET_PRODUCTS_IMAGES,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_PRODUCT_IMAGES_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_IMAGES_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Fetch  All Inquiry
export const addProducts = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      dispatch({ type: ADD_PRODUCT_REQUEST });
      const response = await axios.post(API_URLS.ADD_PRODUCTS, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: ADD_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: ADD_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

export const updateProducts = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });
      const response = await axios.post(API_URLS.UPDATE_PRODUCTS, data, config);
      // const response = await axios.post(`http://192.168.0.21:4040/web/add-or-delete-product-images`, data, config);

      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

export const updateProductsImage = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });
      const response = await axios.post(
        API_URLS.ADD_UPDATE_PRODUCTS_IMAGES,
        data,
        config
      );
      // const response = await axios.post(`http://192.168.0.21:4040/web/add-or-delete-product-images`, data, config);

      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Delete Category
export const deleteProducts = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
      const response = await axios.post(
        API_URLS.DELETE_PRODUCTS,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};


//Delete Category
export const deleteProductCategory = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      dispatch({ type: DELETE_CATEGORY_REQUEST });
      const response = await axios.post(
        API_URLS.DELETE_PRODUCT_CATEGORY,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: DELETE_CATEGORY_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: DELETE_CATEGORY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: DELETE_CATEGORY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
