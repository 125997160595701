import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
} from "../../constants/userConstants";
//Login
export const login = (userData) => {
    return async (dispatch) => {
        try {
            
            dispatch({ type: LOGIN_REQUEST });
            const response = await axios.post(API_URLS.LOGIN, userData);
            if(response.status ===200){
                if(response?.data && response?.data?.status){
                    dispatch({ type: LOGIN_SUCCESS, payload: response?.data?.data });
                    localStorage.setItem('authUser', JSON.stringify(response?.data?.data?.user))
                    localStorage.setItem('accessToken', response?.data?.data?.token)
                    return response?.data
                }
                else{
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: response?.data
                    });
                    return response?.data
                }
            }

        } catch (error) {
            dispatch({
                type: LOGIN_FAIL,
                payload: error.data.message
            });
            return error
        }
    }
}

//Admin registering (Adding) New Users
export const adminRegister = (userData) => {
    return async (dispatch) => {
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { 
                Authorization: `Bearer ${Token}`,
                'Access-Control-Allow-Origin':'*',
                CREDENTIALS: true
            }
        }
        try {
            dispatch({ type: SIGNUP_REQUEST });
            const response = await axios.post(API_URLS.SIGNUP, userData, config);
            if(response.status === 200){
                if(response?.data && response?.data?.status){
                    dispatch({ type: SIGNUP_SUCCESS, payload: response?.data?.data });
                    return response?.data
                }
                else{
                    dispatch({
                        type: SIGNUP_FAIL,
                        payload: response?.data
                    });
                    return response?.data
                }
            }

        } catch (error) {
            dispatch({
                type: SIGNUP_FAIL,
                payload: error
            });
            return error
        }
    }
}



