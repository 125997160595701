import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import {
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import FilterComponent from "../../../layout/FilterComponent";
import Footer from "../../../layout/Footer";
import * as Yup from "yup";
import {
  addRequirments,
  deleteRequirment,
  fetchAllRequirments,
  updateRequirment,
} from "../../../redux/actions/requirments";
import IsAuth from "../../auth/IsAuth";
import Swal from "sweetalert2";

export default function Requirements() {
  document.title = "Requirement List | Glasier Wellness";
  const dispatch = useDispatch();
  const { requirment } = useSelector((state) => state.requirment);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [requirementList, setRequirementList] = useState([]);
  const [requirementFormData, setRequirementFormData] = useState({
    requirement: "",
    units: "",
    perUnitPrice: "",
  });
  const [requirmentModels, setRequirmentModels] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getRequirement();
  }, []);

  useEffect(() => {
    setRequirementList(requirment);
  }, [requirment]);

  const getRequirement = async () => {
    setIsLoading(true);
    await dispatch(fetchAllRequirments());
    setIsLoading(false);
  };

  const requirmentModelToggle = (e, values, status) => {
    setRequirmentModels((state) => !state);
    if (status === "edit") {
      setRequirementFormData({
        id: values?._id,
        requirement: values?.requirement,
        units: values?.units,
        perUnitPrice: values?.perUnitPrice,
      });
    } else {
      setRequirementFormData({
        requirement: "",
        units: "",
        perUnitPrice: "",
      });
    }
  };

  const deleteRequirments = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteRequirment({ id: id })).then(async (res) => {
          if (res.status) {
            setRequirementList(
              requirementList.filter((element) => element._id !== id)
            );
            toast.success(res.message, {
              theme: "colored",
            });
          } else {
            toast.error(res.message, {
              theme: "colored",
            });
          }
        });
      }
    });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: requirementFormData,
    validationSchema: Yup.object({
      requirement: Yup.string().required("Please Enter Requirement"),
      // units: Yup.string().required("Please Enter Units"),
      perUnitPrice: Yup.string().required("Please Enter Price/Units"),
    }),

    onSubmit: async (values, { resetForm }) => {
      await dispatch(
        isEdit ? updateRequirment(values) : addRequirments(values)
      ).then(async (res) => {
        if (res.status) {
          toast.success(res.message, {
            theme: "colored",
          });
          setRequirementList([...requirementList, res?.data]);
          resetForm();
          requirmentModelToggle();
        } else {
          toast.error(res.message, {
            theme: "colored",
          });
        }
      });
    },
  });

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      width: "80px",
    },
    {
      name: "Requirement",
      selector: (row) => row.requirement,
    },
    {
      name: "Price/Units",
      selector: (row) => row.perUnitPrice,
      width: "150px",
    },
    {
      name: "Units",
      selector: (row) => row.units,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const rows = [
    ...requirementList
      ?.filter(
        (item) =>
          item.requirement &&
          item.requirement.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((row, order) => ({
        sno: order + 1,
        requirement: row?.requirement,
        perUnitPrice: row?.perUnitPrice,
        units: row?.units,
        action: (
          <>
            <Link
              key={order}
              className="iconbgedit"
              title="Edit"
              onClick={(e) => {
                setIsEdit(true);
                requirmentModelToggle(e, row, "edit");
              }}
            >
              <i className="fa fa-edit eyeicon"></i>
            </Link>
            <button
              type="button"
              onClick={() => deleteRequirments(row._id)}
              className="iconbgdelete ml-2"
              title="Delete"
            >
              <i className="fa fa-trash eyeicon"></i>
            </button>
          </>
        ),
      })),
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="main-heading mb-0">Requirement List</h4>
                </div>
                <div className="col-md-6">
                  <Link
                    onClick={(e) => {
                      setIsEdit(false);
                      requirmentModelToggle(e, {}, "add");
                    }}
                    className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                  >
                    Add Requirement &nbsp;{" "}
                    <i className="fa fa-plus" style={{ fontSize: "14px" }}></i>{" "}
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <DataTable
                              columns={columns}
                              data={rows}
                              progressPending={isLoading}
                              progressComponent={
                                <div style={{ position: "relative" }}>
                                  <ClipLoader size={50} color="#264B75" />
                                </div>
                              }
                              pagination
                              customStyles={customStyles}
                              highlightOnHover
                              pointerOnHover
                              subHeader
                              subHeaderComponent={subHeaderComponentMemo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Requirement */}
      <Modal
        isOpen={requirmentModels}
        toggle={(e) => requirmentModelToggle(e, {}, "add")}
      >
        <ModalHeader toggle={(e) => requirmentModelToggle(e, {}, "add")}>
          <h5 className="modal-title">Add Requirement</h5>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="requirement">
                    Add Requirements <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control emailinput"
                    id="requirement"
                    name="requirement"
                    placeholder="Enter Requirement Name*"
                    invalid={
                      validation.touched.requirement &&
                      validation.errors.requirement
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.requirement}
                  />
                  {validation.touched.requirement &&
                  validation.errors.requirement ? (
                    <FormFeedback type="invalid">
                      {validation.errors.requirement}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="units">Units</label>
                  <Input
                    type="text"
                    className="form-control emailinput"
                    id="units"
                    name="units"
                    placeholder="Enter Units"
                    invalid={
                      validation.touched.units && validation.errors.units
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.units}
                  />
                  {validation.touched.units && validation.errors.units ? (
                    <FormFeedback type="invalid">
                      {validation.errors.units}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="perUnitPrice">
                    Price/Units <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    type="number"
                    className="form-control emailinput"
                    id="perUnitPrice"
                    name="perUnitPrice"
                    placeholder="Enter Price/Units*"
                    invalid={
                      validation.touched.perUnitPrice &&
                      validation.errors.perUnitPrice
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.perUnitPrice}
                  />
                  {validation.touched.perUnitPrice &&
                  validation.errors.perUnitPrice ? (
                    <FormFeedback type="invalid">
                      {validation.errors.perUnitPrice}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              className="btn btncolor btn-rounded btn-fw"
              // onClick={(e) => submitRequirment(e)}
            >
              {isEdit ? "Update" : "Submit"}
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
