import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Input, Label } from "reactstrap";
import {
  toggleInquiryStatus,
} from "../../../redux/actions/inquiry";
import newInqury from "../../../assets/img/new-inqury.png";
import totalINQPNG from "../../../assets/img/totaal-inqury.png";
import winPNG from "../../../assets/img/win.png";
import downloadPNG from "../../../assets/img/download.png";
import lostPNG from "../../../assets/img/lost.png";
import indiamart from "../../../assets/img/indiamart.png";
import tradeIndia from "../../../assets/img/trade-india.png";

import IsAuth from "../../auth/IsAuth";
import { API_URLS } from "../../../common/constant";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Footer from "../../../layout/Footer";
import moment from "moment-timezone";
import FilterComponent from "../../../layout/FilterComponent";
import { ClipLoader, PulseLoader } from "react-spinners";
const InquiryDashBoard = () => {
  document.title = "Inquiry Dashboard | Glasier Wellness";
  const dispatch = useDispatch();
  const { inquiries } = useSelector((state) => state.inquiry);
  const [Token] = useState(localStorage.getItem("accessToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [adminDashboard, setAdminDashboard] = useState({});
  const [filterText, setFilterText] = useState("");
  const [inquiryList, setInquiryList] = useState(inquiries);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [newLoading, setLoading] = useState(false);

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    setIsLoading(true);
    setLoading(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios.get(API_URLS.ADMIN_DASHBOARD, config).then((result) => {
        if (result.status) {
          if (result?.data?.status) {
            setIsLoading(false);
            setLoading(false);
            setAdminDashboard(result?.data.data);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const toggleChecked = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          toggleInquiryStatus({ id: id, status: e == true ? 1 : 0 })
        );
        if (res.status) {
          // getInquiries();
          Swal.fire("Success!", res.message, "success");
          setIsLoading(false);
        } else {
          Swal.fire("Error!", res.message, "error");
          setIsLoading(false);
        }
      }
    });
  };

  let date = new Date();
  date.setHours(date.getHours() - 48);

  useEffect(() => {
    fetchInquiries(1); // fetch page 1 of users
  }, [dispatch]);

  const fetchInquiries = async (page) => {
    setIsLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        { page: page, limit: perPage },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchInquiries(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        { page: page, limit: perPage },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
    // setData(response?.data?.data);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  const columns = [
    // {
    //   name: "S.No.",
    //   selector: (row) => row.sno,
    //   width: "70px",
    //   borderWidth: "1px",
    // },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "200px",
    },
    {
      name: "Party Details",
      selector: (row) => row.partyDetails,
      // width: "600px",
    },
    {
      name: "Requirement/ Units",
      selector: (row) => row.requirements,
      // width: "550px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];
  
  const rows = [
    ...inquiryList
      ?.filter(
        (item) =>
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.partyName &&
            item.partyName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((row, order) => ({
        // ...row,
        // sno: order + 1,
        date: (
          <>
            <span>
              <Moment format="D MMM YYYY" withTitle>
                {row.createdAt}
              </Moment>
            </span>
            <br></br>
            <br></br>
            <span>{row.companyName}</span>
            <br></br>
            <br></br>
            {row?.isRead == 0 ? (
              <span className={`w-h-15 small-box-red`}></span>
            ) : null}
            {moment().diff(row?.date, "h") <= 48 ? (
              <span className={`w-h-15 small-box-green`}></span>
            ) : (
              ""
            )}
            {moment().diff(row?.date, "h") >= 48 &&
            moment().diff(row?.date, "h") <= 72 ? (
              <span className={`w-h-15 small-box-orange`}></span>
            ) : moment().diff(row?.date, "h") > 72 && row?.stepStatus=='new' ?(
              <span className={`w-h-15 small-box-red`}></span>
            ):""}
          </>
        ),
        partyDetails: (
          <>
            <div className="thirdcol">
              <ul className="thirdul">
                <li>
                  {" "}
                  <strong> Party Name : </strong>{" "}
                  {row.partyName ? row.partyName : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Email ID :</strong> {row.email ? row.email : "NA"}{" "}
                </li>
                <li>
                  {" "}
                  <strong>Contact No. : </strong>
                  {row.contactNumber ? row.contactNumber : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Location : </strong>
                  {row.address && row.address} {row.city && row.city}{" "}
                  {row.state && row.state}
                  {row.country && ", " + row.country}{" "}
                  {row.pinCode && " - " + row.pinCode}
                </li>
              </ul>
            </div>
          </>
        ),
        requirements: (
          <>
            {row.requirements?.map((item, i) => (
              <div key={i}>
                <p className="textcolor mb-2">
                  {" "}
                  {item.requirement && item.requirement}{" "}
                </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Units :- </strong>
                    {item.units && item.units}
                  </li>
                </ul>
              </div>
            ))}
            {row.remark && (
              <div>
                <p className="textcolor"> Remarks </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Type : </strong> {row.remark}
                  </li>
                </ul>
              </div>
            )}
          </>
        ),
        action: (
          <>
            <Label className="switch1">
              <Input
                type="checkbox"
                checked={row.status}
                onClick={(e, id) => toggleChecked(e.target.checked, row._id)}
              />
              <span className="slider1 round1"></span>
            </Label>
          </>
        ),
      })),
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);
  return (
    <>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-6 grid-margin">
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2">Total Inquiry</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-inline-block">
                          <div className="d-md-flex">
                            <h2
                              className="mb-0"
                              style={{ width: "max-content" }}
                            >
                              {newLoading ? (
                                <PulseLoader
                                  color="#264B75"
                                  margin={2}
                                  size={8}
                                  speedMultiplier={0.5}
                                />
                              ) : adminDashboard?.totolInquiries ? (
                                adminDashboard?.totolInquiries
                              ) : (
                                0
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="icon1">
                          <img src={totalINQPNG} className="icon1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2">New Inquiry</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-inline-block">
                          <div className="d-md-flex">
                            <h2 className="mb-0" style={{ width: "max-content" }}>
                              {newLoading ? (
                                <PulseLoader
                                  color="#264B75"
                                  margin={2}
                                  size={8}
                                  speedMultiplier={0.5}
                                />
                              ) : adminDashboard?.newInquiries ? (
                                adminDashboard?.newInquiries
                              ) : (
                                0
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="icon1">
                          <img src={newInqury} className="icon1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2">Won</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-inline-block">
                          <div className="d-md-flex">
                            <h2 className="mb-0" style={{ width: "max-content" }}>
                              {newLoading ? (
                                <PulseLoader
                                  color="#264B75"
                                  margin={2}
                                  size={8}
                                  speedMultiplier={0.5}
                                />
                              ) : adminDashboard?.wonInquiries ? (
                                adminDashboard?.wonInquiries
                              ) : (
                                0
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="icon1">
                          <img src={winPNG} className="icon1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2">Lost</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-inline-block">
                          <div className="d-md-flex">
                            <h2 className="mb-0" style={{ width: "max-content" }}>
                              {newLoading ? (
                                <PulseLoader
                                  color="#264B75"
                                  margin={2}
                                  size={8}
                                  speedMultiplier={0.5}
                                />
                              ) : adminDashboard?.lostInquiries ? (
                                adminDashboard?.lostInquiries
                              ) : (
                                0
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="icon1">
                          <img src={lostPNG} className="icon1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-margin welcome-box">
              <div className="card overflow-hidden source-bg">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-5">
                      <img
                        src={downloadPNG}
                        alt=""
                        className="img-fluid ml-15"
                      />
                    </div>
                    <div className="col-7">
                      <div className="source-primary">
                        <div className="text-primary p-3">
                          <h5>Source of Inquiry</h5>
                        </div>
                        <div className="logo-brand">
                          <ul>
                            <li className="mr-50">
                              <img src={indiamart} />
                              <p>India mart</p>
                            </li>
                            <li>
                              <img src={tradeIndia} />
                              <p>Trade India</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-sm-10">
                      <div className="pt-4">
                        <div className="row">
                          <div className="col-12">
                            <p className="text-muted mb-0">
                              {/* Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text book. */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <h4 className="mb-3">Total Inquiry</h4>
                    </div>
                    <div className="col-12">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={rows}
                          progressPending={isLoading}
                          progressComponent={
                            <div style={{ position: "relative" }}>
                              <ClipLoader size={50} color="#264B75" />
                            </div>
                          }
                          customStyles={customStyles}
                          pointerOnHover
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InquiryDashBoard;
