export const ROLES = {
  PRODUCTION_USER: "Production Admin",
  ADMIN: "Admin",
  STAFF: "Staff",
  MANAGER: "Manager",
};

export const API_URLS = {
  LOGIN: `${process.env.REACT_APP_API_URL}/web/login`,
  SIGNUP: `${process.env.REACT_APP_API_URL}/web/register`,
  DASHBOARD: `${process.env.REACT_APP_API_URL}/web/dashboard`,
  ADMIN_DASHBOARD: `${process.env.REACT_APP_API_URL}/web/admin-dashboard`,
  DASHBOARD_CHART: `${process.env.REACT_APP_API_URL}/web/dashboard-chart`,

  NOTIFICATION_LIST: `${process.env.REACT_APP_API_URL}/web/notification`,
  // NOTIFICATION_LIST: `http://3.110.62.25:4000/web/notification`,
  CLEAR_NOTIFICATION: `${process.env.REACT_APP_API_URL}/web/clear-notification`,

  INQUIRY_LIST: `${process.env.REACT_APP_API_URL}/web/inquiry`,
  PARTY_NAME_SEARCH: `${process.env.REACT_APP_API_URL}/web/party-names`,
  
  // ADMIN_INQUIRY_LIST: `${process.env.REACT_APP_API_URL}/web/admin-get-all-inquiry`,
  ADMIN_INQUIRY_TOGGLE: `${process.env.REACT_APP_API_URL}/web/change-status`,

  ADD_INQUIRY: `${process.env.REACT_APP_API_URL}/web/add-inquiry`,
  UPDATE_INQUIRY: `${process.env.REACT_APP_API_URL}/web/update-inquiry`,
  INQUIRY_DETAILS: `${process.env.REACT_APP_API_URL}/web/inquiry-details`,
  INQUIRY_DELETE: `${process.env.REACT_APP_API_URL}/web/delete-inquiry`,
  INQUIRY_CHANGE_STATUS: `${process.env.REACT_APP_API_URL}/web/change-inquiry-status`,
  UPDATE_INQUIRY_REMARK: `${process.env.REACT_APP_API_URL}/web/update-inquiry-remark`,

  // SOURCES
  SOURCE_LIST: `${process.env.REACT_APP_API_URL}/web/source`,
  SOURCE_ADD: `${process.env.REACT_APP_API_URL}/web/add-source`,
  SOURCE_CHANGE_STATUS: `${process.env.REACT_APP_API_URL}/web/change-source-status`,
  SOURCE_UPDATE: `${process.env.REACT_APP_API_URL}/web/update-source`,
  SOURCE_DELETE: `${process.env.REACT_APP_API_URL}/web/delete-source`,
  //USERS
  USERS: `${process.env.REACT_APP_API_URL}/web/users`,
  TOP_USERS: `${process.env.REACT_APP_API_URL}/web/top-users`,

  MANAGER_STAFF_USERS: `${process.env.REACT_APP_API_URL}/web/get-managers-and-staff`,
  GET_USER_PROFILE_DETAILS: `${process.env.REACT_APP_API_URL}/web/profile`,
  EDIT_USER_PROFILE_DETAILS: `${process.env.REACT_APP_API_URL}/web/edit-profile`,
  ADMIN_EDIT_PROFILE: `${process.env.REACT_APP_API_URL}/web/admin-edit-profile`,
  ADMIN_CHANGE_USER_STATUS: `${process.env.REACT_APP_API_URL}/web/admin-change-user-status`,

  // TODO
  TODO_LIST: `${process.env.REACT_APP_API_URL}/web/todo`,
  ADD_TODO: `${process.env.REACT_APP_API_URL}/web/add-todo`,
  UPDATE_TODO: `${process.env.REACT_APP_API_URL}/web/update-todo`,
  DELETE_TODO: `${process.env.REACT_APP_API_URL}/web/delete-todo`,
  CHANGE_STATUS_TODO: `${process.env.REACT_APP_API_URL}/web/change-todo-status`,

  //REMINDER
  REMINDER_LIST: `${process.env.REACT_APP_API_URL}/web/reminder`,
  ADD_REMINDER: `${process.env.REACT_APP_API_URL}/web/add-reminder`,
  UPDATE_REMINDER: `${process.env.REACT_APP_API_URL}/web/update-reminder`,
  CHANGE_REMINDER_STATUS: `${process.env.REACT_APP_API_URL}/web/change-reminder-status`,
  DELETE_REMINDER: `${process.env.REACT_APP_API_URL}/web/delete-reminder`,

  //REQUIRMENT
  REQUIRMENT_LIST: `${process.env.REACT_APP_API_URL}/web/requirement`,
  ADD_REQUIRMENT: `${process.env.REACT_APP_API_URL}/web/add-requirement`,
  UPDATE_REQUIRMENT: `${process.env.REACT_APP_API_URL}/web/update-requirement`,
  DELETE_REQUIRMENT: `${process.env.REACT_APP_API_URL}/web/delete-requirement`,
  USER_REQUIRMENT: `${process.env.REACT_APP_API_URL}/web/user-requirement`,
  CHANGE_REQUIRMENT_STATUS: `${process.env.REACT_APP_API_URL}/web/change-requirement-status`,

  // Product Category

  GLOBAL_PRODUCT_SEARCH: `${process.env.REACT_APP_API_URL}/web/search-category-and-product`,

  GET_PRODUCT_CATEGORY: `${process.env.REACT_APP_API_URL}/web/get-category`,
  ADD_PRODUCT_CATEGORY: `${process.env.REACT_APP_API_URL}/web/add-category`,
  UPDATE_PRODUCT_CATEGORY: `${process.env.REACT_APP_API_URL}/web/update-category`,
  DELETE_PRODUCT_CATEGORY: `${process.env.REACT_APP_API_URL}/web/delete-category`,

  // Product
  GET_PRODUCTS: `${process.env.REACT_APP_API_URL}/web/get-product`,
  GET_MULTIPLE_PRODUCTS: `${process.env.REACT_APP_API_URL}/web/get-multiple-products`,
  ADD_PRODUCTS: `${process.env.REACT_APP_API_URL}/web/add-product`,
  UPDATE_PRODUCTS: `${process.env.REACT_APP_API_URL}/web/update-product`,
  DELETE_PRODUCTS: `${process.env.REACT_APP_API_URL}/web/delete-product`,
  GET_PRODUCTS_IMAGES: `${process.env.REACT_APP_API_URL}/web/get-product-images`,
  ADD_UPDATE_PRODUCTS_IMAGES: `${process.env.REACT_APP_API_URL}/web/add-or-delete-product-images`,

  SHARE_IMAGES_TOKEN: `${process.env.REACT_APP_API_URL}/web/share-images`,
  GET_SHARE_IMAGES: `${process.env.REACT_APP_API_URL}/web/decode-token`,
  
  // Add Discount On Reqirements
  DISCOUNT_ON_REQIREMENT: `${process.env.REACT_APP_API_URL}/web/discount-on-reqirement`,
  PRODUCTION_USER_COMMENT: `${process.env.REACT_APP_API_URL}/web/add-production-user-comment`,

  // Chat Documenmt Upload
  SEND_DOCUMENTS: `${process.env.REACT_APP_API_URL}/web/send-documents`,
};
