export const FETCH_REMINDER_REQUEST = "FETCH_REMINDER_REQUEST";
export const FETCH_REMINDER_SUCCESS = "FETCH_REMINDER_SUCCESS";
export const FETCH_REMINDER_FAIL = "FETCH_REMINDER_FAIL";

export const ADD_REMINDER_REQUEST = "ADD_REMINDER_REQUEST";
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const ADD_REMINDER_FAIL = "ADD_REMINDER_FAIL";

export const UPDATE_REMINDER_REQUEST = "UPDATE_REMINDER_REQUEST";
export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS";
export const UPDATE_REMINDER_FAIL = "UPDATE_REMINDER_FAIL";

export const CHANGE_REMINDER_STATUS_REQUEST = "CHANGE_REMINDER_STATUS_REQUEST";
export const CHANGE_REMINDER_STATUS_SUCCESS = "CHANGE_REMINDER_STATUS_SUCCESS";
export const CHANGE_REMINDER_STATUS_FAIL = "CHANGE_REMINDER_STATUS_FAIL";

export const DELETE_REMINDER_REQUEST = "DELETE_REMINDER_REQUEST";
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS";
export const DELETE_REMINDER_FAIL = "DELETE_REMINDER_FAIL";



