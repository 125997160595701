import {
  ADD_REMINDER_FAIL,
  ADD_REMINDER_REQUEST,
  ADD_REMINDER_SUCCESS,
  FETCH_REMINDER_FAIL,
  FETCH_REMINDER_REQUEST,
  FETCH_REMINDER_SUCCESS,
  UPDATE_REMINDER_FAIL,
  UPDATE_REMINDER_REQUEST,
  UPDATE_REMINDER_SUCCESS,
} from "../../constants/reminderConstants";

let initialState = {
  reminder: [],
  loading: false,
  error: null,
};
export const ReminderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        reminder: action.payload,
        error: null,
      };
    case FETCH_REMINDER_FAIL:
      return {
        ...state,
        loading: false,
        reminder: [],
        error: action.payload,
      };
    case ADD_REMINDER_REQUEST:
    case UPDATE_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
        reminder: [],
      };
    case ADD_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        loading: true,
        reminder: [],
      };
    case ADD_REMINDER_FAIL:
    case UPDATE_REMINDER_FAIL:
    default:
      return state;
  }
};
