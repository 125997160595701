import {
 
    FETCH_REQUIRMENT_FAIL,
    FETCH_REQUIRMENT_REQUEST,
    FETCH_REQUIRMENT_SUCCESS,
   
} from "../../constants/requirmentConstants";

let initialState = {
    requirment: [],
    loading: false,
    error: null,
}
export const RequirmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUIRMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_REQUIRMENT_SUCCESS:
           return {
                ...state,
                loading: false,
                requirment: action.payload,
                error: null
            };
        case FETCH_REQUIRMENT_FAIL:
            return {
                ...state,
                loading: false,
                requirment: [],
                error: action.payload,
            };
        default:
            return state;
    }
};