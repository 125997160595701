import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
    FETCH_SOURCE_REQUEST,
    FETCH_SOURCE_SUCCESS,
    FETCH_SOURCE_FAIL,
    ADD_SOURCE_REQUEST,
    ADD_SOURCE_SUCCESS,
    ADD_SOURCE_FAIL,
    UPDATE_SOURCE_REQUEST,
    UPDATE_SOURCE_SUCCESS,
    UPDATE_SOURCE_FAIL,
    STATUS_SOURCE_REQUEST,
    STATUS_SOURCE_SUCCESS,
    STATUS_SOURCE_FAIL,
    DELETE_SOURCE_REQUEST,
    DELETE_SOURCE_SUCCESS,
    DELETE_SOURCE_FAIL,
} from "../../constants/sourceConstants";

//Fetch All Source (Admin)
export const fetchAllSource = () => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: FETCH_SOURCE_REQUEST});
            const response = await axios.get(API_URLS.SOURCE_LIST, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: FETCH_SOURCE_SUCCESS, payload:response?.data?.data});
                    return response?.data;
                }else{
                    dispatch({
                        type: FETCH_SOURCE_FAIL,
                        payload: response?.data?.message
                    });
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: FETCH_SOURCE_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Adding A Source
export const addSource = (sourceData) => {
    return async(dispatch) => {
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: ADD_SOURCE_REQUEST});
            const response = await axios.post(API_URLS.SOURCE_ADD, sourceData, config);
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: ADD_SOURCE_SUCCESS, payload:response?.data});
                    dispatch(fetchAllSource());
                    return response?.data;
                }else{
                    dispatch({
                        type: ADD_SOURCE_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllSource());
                    return response?.data;
                }
            }

        } catch (error) {
            dispatch({
                type: ADD_SOURCE_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Update A Source
export const updateSource = (sourceData) => {
    return async(dispatch) => {
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: UPDATE_SOURCE_REQUEST});
            const response = await axios.post(API_URLS.SOURCE_UPDATE, sourceData, config);
            dispatch({type: UPDATE_SOURCE_SUCCESS, payload:response?.data});
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: UPDATE_SOURCE_SUCCESS, payload:response?.data});
                    dispatch(fetchAllSource());
                    return response?.data;
                }else{
                    dispatch({
                        type: UPDATE_SOURCE_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllSource());
                    return response?.data;
                }
            }

        } catch (error) {
            dispatch({
                type: UPDATE_SOURCE_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Toggle Source Completed Or Not
export const changeStatus = (sourceData) => {
    return async(dispatch) => {
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: STATUS_SOURCE_REQUEST});
            const response = await axios.post(API_URLS.SOURCE_CHANGE_STATUS, sourceData, config);
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: STATUS_SOURCE_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllSource());
                    return response?.data;
                }else{
                    dispatch({
                        type: STATUS_SOURCE_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch(fetchAllSource());
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: STATUS_SOURCE_FAIL,
                payload: error
            });
            // dispatch(fetchAllSource());
            return error;
        }
    }
}

//Delete A Source
export const deleteSource = (sourceData) => {
    return async(dispatch) => {
        const Token = localStorage.getItem("accessToken");
        try {
            dispatch({type: DELETE_SOURCE_REQUEST});
            const response = await axios.delete(API_URLS.SOURCE_DELETE, { data: sourceData, headers: { Authorization: `Bearer ${Token}` }})
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: DELETE_SOURCE_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllSource());
                    return response?.data;
                }else{
                    dispatch({
                        type: DELETE_SOURCE_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllSource());
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: DELETE_SOURCE_FAIL,
                payload: error
            });
            dispatch(fetchAllSource());
            return error;
        }
    }
}