import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
  ADMIN_EDIT_PROFILE_FAIL,
  ADMIN_EDIT_PROFILE_REQUEST,
  ADMIN_EDIT_PROFILE_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  EDIT_USER_PROFILE_FAIL,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  TOP_USER_REQUEST,
  TOP_USER_SUCCESS,
  TOP_USER_FAIL,
} from "../../constants/userConstants";

//Fetch All Users (Admin)
export const getAllUsers = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      let data = {};
      dispatch({ type: GET_ALL_USERS_REQUEST });
      const response = await axios.post(API_URLS.USERS, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: GET_ALL_USERS_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_ALL_USERS_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
      const users = await axios.get(
        `${process.env.REACT_APP_API_URL}/web/admin/users`,
        config
      );
      dispatch({ type: GET_ALL_USERS_SUCCESS, payload: users.data.data });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// Fetch top users
export const getTopUsers = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: TOP_USER_REQUEST });
      const response = await axios.get(API_URLS.TOP_USERS, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({ type: TOP_USER_SUCCESS, payload: response?.data?.data });
          return response?.data;
        } else {
          dispatch({
            type: TOP_USER_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: TOP_USER_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// Get Manager And Staff Users
export const getManagerStaffUsers = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      let data = {};
      dispatch({ type: GET_ALL_USERS_REQUEST });
      const response = await axios.post(
        API_URLS.MANAGER_STAFF_USERS,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: GET_ALL_USERS_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_ALL_USERS_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
      dispatch({ type: GET_ALL_USERS_SUCCESS, payload: response?.data?.data });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Edit User (Admin)
export const adminEditUser = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: ADMIN_EDIT_PROFILE_REQUEST });
      const response = await axios.post(
        API_URLS.ADMIN_EDIT_PROFILE,
        data,
        config
      );
      dispatch(getAllUsers());
      return dispatch({
        type: ADMIN_EDIT_PROFILE_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_EDIT_PROFILE_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Toggle User Active Or Not
export const adminChangeUserStatus = (userData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: CHANGE_USER_STATUS_REQUEST });
      const response = await axios.post(
        API_URLS.ADMIN_CHANGE_USER_STATUS,
        userData,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: CHANGE_USER_STATUS_SUCCESS,
            payload: response?.data?.data,
          });
          // dispatch(getAllUsers());
          return response?.data;
        } else {
          dispatch({
            type: CHANGE_USER_STATUS_FAIL,
            payload: response?.data?.message,
          });
          dispatch(getAllUsers());
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: CHANGE_USER_STATUS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Get User Profile (Admin, Manager, Staff)
export const getUserProfile = () => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: GET_USER_PROFILE_REQUEST });
      const response = await axios.get(
        API_URLS.GET_USER_PROFILE_DETAILS,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: GET_USER_PROFILE_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_USER_PROFILE_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Edit User Profile (Admin, Manager, Staff)
export const editUserProfile = (userData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: EDIT_USER_PROFILE_REQUEST });
      const response = await axios.post(
        API_URLS.EDIT_USER_PROFILE_DETAILS,
        userData,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: EDIT_USER_PROFILE_SUCCESS,
            payload: response?.data?.data,
          });
          localStorage.setItem("authUser", response?.data?.data);
          return response?.data;
        } else {
          dispatch({
            type: EDIT_USER_PROFILE_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: EDIT_USER_PROFILE_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
