import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userPNG from "../../assets/img/user.png";
import totalINQPNG from "../../assets/img/totaal-inqury.png";
import winPNG from "../../assets/img/win.png";
import lostPNG from "../../assets/img/lost.png";
import downloadPNG from "../../assets/img/download.png";
import { API_URLS } from "../../common/constant";
import axios from "axios";
import { Link } from "react-router-dom";
import Avatar from "../../common/Avatar";
import IsAuth from "../auth/IsAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTopUsers } from "../../redux/actions/users";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from "react-spinners";
import {
  addTodo,
  deleteTodo,
  fetchAllToDo,
  updateTodo,
} from "../../redux/actions/todo";
import {
  addReminder,
  deleteReminder,
  fetchAllReminders,
  updateReminder,
} from "../../redux/actions/reminder";

import Moment from "react-moment";
import "moment-timezone";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Charts from "../../common/Charts";
import Footer from "../../layout/Footer";
import { ROLES } from "../../common/constant";
import classnames from "classnames";
import UserINQChart from "../Admin/Dashboard/UserINQChart";
import Checkbox from "./Checkbox";
import Loader from "../../common/Loader";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { todo } = useSelector((state) => state.todo);
  const reminder = useSelector((state) => state.reminder);
  const { top_users } = useSelector((state) => state.users);

  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [Token] = useState(localStorage.getItem("accessToken"));
  const [particularUserINQs, setParticularUserINQs] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Loaders, setLoaders] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [dataPoint, setdataPoint] = useState([]);
  const [newChartData, setnewChartData] = useState([]);
  const [todoList, setTodoList] = useState([]);
  const [reminderList, setReminderList] = useState([]);
  const [taskModels, setTaskModels] = useState(false);
  const [reminderModels, setReminderModels] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [taskDate, setTaskDate] = useState(new Date());
  const [reminderDate, setReminderDate] = useState(new Date());
  const [combinedDashboard, setCombinedDashboard] = useState({});
  const [currentStep, setCurrentStep] = useState(
    authUser?.userType == ROLES.ADMIN ? 0 : 1
  );
  document.title = `${
    authUser && authUser.userType
  } Dashboard | Glasier Wellness`;

  useEffect(() => {
    getDashboard();
  }, [dispatch]);
  useEffect(() => {
    getChartData();
  }, []);

  useEffect(() => {
    getTopUser();
  }, [dispatch]);

  useEffect(() => {
    getTODO();
  }, [dispatch]);

  useEffect(() => {
    setTodoList(todo);
  }, [todo]);

  useEffect(() => {
    getReminders();
  }, [dispatch]);

  useEffect(() => {
    setReminderList(reminder.reminder);
  }, [reminder]);

  const getDashboard = async () => {
    setIsLoading(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios.get(API_URLS.DASHBOARD, config).then((result) => {
        if (result?.status) {
          if (result?.data?.status) {
            setCombinedDashboard(result?.data?.data);
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTopUser = async () => {
    setLoaders(true);
    const result = await dispatch(getTopUsers());
    if (result.status) {
      setLoaders(false);
    } else {
      setLoaders(false);
    }
  };

  const getChartData = async () => {
    setIsLoading(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios.get(API_URLS.DASHBOARD_CHART, config).then((result) => {
        if (result.status) {
          if (result?.data?.status) {
            setChartData(result?.data.data);
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleViewModal = async (user) => {
    setViewModal(true);
    setdataPoint((prev) => [
      user.newInquiries,
      user.contactedInquiries,
      user.workingInquiries,
      user.wonInquiries,
      user.lostInquiries,
    ]);
    let userData = {
      name: user.full_name,
      inquiry: dataPoint,
    };
    setParticularUserINQs(userData);
  };

  const getTODO = async () => {
    await dispatch(fetchAllToDo({}));
  };

  const getReminders = async () => {
    await dispatch(fetchAllReminders());
  };

  const [todoFormData, setTodoFormData] = useState({
    title: "",
    date: moment(taskDate).format("MM/DD/YYYY"),
  });

  const handleChangeTask = (e) => {
    setTodoFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.replace(/^\s+|\s+$/gm, " "),
    }));
  };
  const handleChangeTaskDate = (date) => {
    setTaskDate(new Date(date));
    setTodoFormData((prev) => ({
      ...prev,
      date: moment(date).format("MM/DD/YYYY"),
    }));
  };
  const submitTodos = async () => {
    setLoading(true);
    const res = await dispatch(
      addTodo({
        title: todoFormData?.title.replace(/^\s+|\s+$/gm, " "),
        date: moment(taskDate).format("MM/DD/YYYY"),
      })
    );
    if (res.status) {
      setTodoList([...todoList, res?.data]);
      setLoading(false);
      toast.success(res.message, {
        theme: "colored",
      });
      taskModelToggle();
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
      });
    }
  };

  const editTask = (id, title, date) => {
    setIsEdit(true);
    taskModelToggle();
    setTodoFormData({
      id: id,
      title: title.replace(/^\s+|\s+$/gm, " "),
      date: date,
    });
    setTaskDate(new Date(date));
  };

  const updateTodos = async () => {
    setLoading(true);
    const res = await dispatch(updateTodo(todoFormData));
    if (res.status) {
      setIsEdit(false);
      toast.success(res.message, {
        theme: "colored",
      });
      // setTodoList([...todoList, res?.data]);
      // setTimeout(() => {
      taskModelToggle();
      setTodoFormData({
        title: "",
        date: "",
      });
      // }, 800);
      setLoading(false);
    } else {
      setIsEdit(false);
      toast.error(res.message, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const deleteTask = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteTodo({ id: id }));
        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setIsLoading(false);
          setTodoList(
            todoList.filter((element) => element._id !== id),
            [...todoList]
          );
        } else {
          Swal.fire("Error!", res.message, "error");
          setIsLoading(false);
        }
      }
    });
  };

  const taskModelToggle = () => {
    setTaskModels((state) => !state);
  };

  const [reminderFormData, setReminderFormData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
  });

  const handleChangeReminder = (e) => {
    setReminderFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.replace(/^\s+|\s+$/gm, " "),
    }));
  };
  const handleChangeReminderDate = (date) => {
    setReminderDate(new Date(date));
    setReminderFormData((prev) => ({
      ...prev,
      date: moment(date).format("MM/DD/YYYY"),
    }));
  };
  const submitReminders = async () => {
    setLoading(true);
    const res = await dispatch(
      addReminder({
        title: reminderFormData.title.replace(/^\s+|\s+$/gm, " "),
        date: moment(reminderDate).format("MM/DD/YYYY"),
        description: reminderFormData.description,
        time: moment(reminderFormData.time, "HHmm").format("HH:mm"),
      })
    );
    // setIsLoading(true);
    if (res.status) {
      toast.success(res.message, {
        theme: "colored",
      });
      // setTimeout(() => {
      reminderModelToggle();
      // }, 1500);
      setLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const editReminder = (id, title, description, date, time) => {
    setIsEdit(true);
    reminderModelToggle();
    setReminderFormData({
      id: id,
      title: title,
      description: description,
      date: date,
    });
    setReminderDate(new Date(date));
  };
  const updateReminders = async () => {
    setLoading(true);
    const res = await dispatch(updateReminder(reminderFormData));
    if (res.status) {
      setIsEdit(false);
      toast.success(res.message, {
        theme: "colored",
      });
      // setTimeout(() => {
      reminderModelToggle();
      setReminderFormData({
        title: "",
        description: "",
        date: "",
      });
      setReminderDate(new Date());
      // }, 800);
      setLoading(false);
    } else {
      setIsEdit(false);
      toast.error(res.message, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const deleteReminders = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteReminder({ id: id }));
        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setIsLoading(false);
        } else {
          Swal.fire("Error!", res.message, "error");
          setIsLoading(false);
        }
      }
    });
  };

  const reminderModelToggle = () => {
    setReminderModels((state) => !state);
  };

  useEffect(() => {
    let chart = [];
    const borderColors = [
      "#264B75",
      "rgb(53, 162, 235, 0.5)",
      "#6366F1",
      "#A855F7",
      "#22C55E",
      "#F59E0B",
    ];
    const bgColors = [
      "#264b7561",
      "rgb(53, 162, 235, 0.5)",
      "#6366f17a",
      "#a855f780",
      "#22c55e82",
      "#f59e0b87",
    ];
    chartData.forEach((element, i) => {
      let obj = {
        fill: true,
        label: element.label,
        data: element.data,
        borderColor: borderColors[i],
        backgroundColor: bgColors[i],
      };
      chart.push(obj);
      setnewChartData(chart);
    });
  }, [chartData]);

  const PieChartData = {
    labels: [
      "New Inquiry",
      "Contacted Inquiry",
      "Working Inquiry",
      "Won Inquiry",
      "Lost Inquiry",
    ],
    datasets: [
      {
        // label: "#",
        data: dataPoint,
        borderColor: [
          "rgb(53, 162, 235, 0.5)",
          "#6366F1",
          "#A855F7",
          "#22C55E",
          "#F59E0B",
        ],
        backgroundColor: [
          "rgb(53, 162, 235, 0.5)",
          "#6366f17a",
          "#a855f780",
          "#22c55e82",
          "#f59e0b87",
        ],
        borderWidth: 1,
      },
    ],
  };

  function toggleTab(tab) {
    if (currentStep !== tab) {
      if (tab >= 0 && tab <= 3) {
        setCurrentStep(tab);
      }
    }
  }
  const currentDate = new Date();
  return (
    <>
      <IsAuth />
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-6 grid-margin">
                  <div className="row">
                    <div className="col-md-6 grid-margin">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-2">Total Inquiry</h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-inline-block">
                              <div className="d-md-flex">
                                <h2 className="mb-0">
                                  {combinedDashboard?.totolInquiries
                                    ? combinedDashboard?.totolInquiries
                                    : 0}
                                </h2>
                              </div>
                            </div>
                            <div className="icon1">
                              <img
                                alt="image"
                                src={totalINQPNG}
                                className="icon1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 grid-margin">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-2">New Inquiry</h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-inline-block">
                              <div className="d-md-flex">
                                <h2 className="mb-0">
                                  {combinedDashboard?.newInquiries
                                    ? combinedDashboard?.newInquiries
                                    : 0}
                                </h2>
                              </div>
                            </div>
                            <div className="icon1">
                              <img
                                alt="image"
                                src={userPNG}
                                className="icon1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 grid-margin">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-2">Won</h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-inline-block">
                              <div className="d-md-flex">
                                <h2 className="mb-0">
                                  {combinedDashboard?.wonInquiries
                                    ? combinedDashboard?.wonInquiries
                                    : 0}
                                </h2>
                              </div>
                            </div>
                            <div className="icon1">
                              <img alt="image" src={winPNG} className="icon1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 grid-margin">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-2">Lost</h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-inline-block">
                              <div className="d-md-flex">
                                <h2 className="mb-0">
                                  {combinedDashboard?.lostInquiries
                                    ? combinedDashboard?.lostInquiries
                                    : 0}
                                </h2>
                              </div>
                            </div>
                            <div className="icon1">
                              <img
                                alt="image"
                                src={lostPNG}
                                className="icon1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin welcome-box">
                  <div className="card overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-7">
                          <div className="text-primary p-3 ml-15">
                            <h5 className="text-primary">
                              Welcome{" "}
                              {authUser?.full_name ? authUser?.full_name : ""}{" "}
                            </h5>
                            <p>Glasier Dashboard</p>
                          </div>
                        </div>
                        <div className="col-5 align-self-end">
                          <img src={downloadPNG} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="avatar-md profile-user-wid mb-4">
                            <Avatar className="img-thumbnail rounded-circle" name={authUser?.full_name ? authUser?.full_name : "Guest"} />
                          </div>
                        </div>
                        <div className="col-sm-10">
                          <div className="pt-4">
                            <div className="row">
                              <div className="col-12">
                                <p className="text-muted mb-0">
                                  {authUser?.about ? authUser?.about : ""}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="mt-15">
                              {authUser?.userType == ROLES.PRODUCTION_USER ? (
                                <Link
                                  to={`/profile`}
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  View Profile
                                </Link>
                              ) : (
                                <Link
                                  to={`/${authUser?.userType.toLowerCase()}/profile`}
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  View Profile
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="d-flex flex-space-between title-text">
                    <h4 className="mb-4">
                      Current Month : {moment(currentDate).format("MMMM")}
                      {", "}
                      {new Date().getFullYear()}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="card">
                    <div className="card-body">
                      <Charts data={newChartData} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="card other-details">
                    <div className="card-header">
                      <ul className="nav nav-tabs" role="tablist">
                        {authUser?.userType == ROLES.ADMIN ? (
                          <NavItem
                            className={classnames({
                              current: currentStep === 0,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: currentStep === 0,
                              })}
                              onClick={() => {
                                toggleTab(0);
                              }}
                            >
                              {" "}
                              Top Employees
                            </NavLink>
                          </NavItem>
                        ) : (
                          ""
                        )}
                        <NavItem
                          className={classnames({
                            current: currentStep === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 1,
                            })}
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            {" "}
                            To Do List
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: currentStep === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 2,
                            })}
                            onClick={() => {
                              toggleTab(2);
                            }}
                          >
                            {" "}
                            Reminder
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="card-body">
                      {/* <!-- Tab panes --> */}
                      <TabContent activeTab={currentStep} className="p-0">
                        {authUser?.userType == ROLES.ADMIN ? (
                          <TabPane tabId={0} className="">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <tbody>
                                  {Loaders ? (
                                    <tr>
                                      <td>
                                        <div className="text-center"><ClipLoader size={35} color="#264B75" /></div>
                                      </td>
                                    </tr>
                                  ) : top_users ? (
                                    top_users
                                      .filter((item, idx) => idx < 10)
                                      .map((data, i) => (
                                        <tr key={i}>
                                          <td>{data.full_name}</td>
                                          <td className="text-center">
                                            <Link
                                              className="iconbg"
                                              onClick={() =>
                                                handleViewModal(data)
                                              }
                                            >
                                              <i className="fa fa-eye eyeicon"></i>
                                            </Link>
                                          </td>
                                        </tr>
                                      ))
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                        ) : (
                          ""
                        )}
                        <TabPane tabId={1} className="">
                          <div className="d-flex">
                            <h4 className="mt-2">To Do List</h4>
                            <button
                              type="button"
                              className="btn btncolor btn-rounded btn-fw btn-right btn-plus"
                              onClick={() => {
                                setIsEdit(false);
                                taskModelToggle();
                                setTodoFormData({
                                  title: "",
                                  date: "",
                                });
                                setTaskDate(new Date());
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                          <div className="mt-4 table-responsive">
                            <table
                              className="table remider-table"
                              style={
                                todoList?.length > 2
                                  ? { position: "relative" }
                                  : { position: "unset" }
                              }
                            >
                              <tbody>
                                <>
                                  {todoList?.map((item, i) => (
                                    <tr key={i}>
                                      <td>
                                        <div className="form-check">
                                          <Checkbox
                                            key={i}
                                            completionStatus={
                                              item.completionStatus
                                            }
                                            id={item._id}
                                            order={i}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <h6>{item?.title}</h6>
                                      </td>

                                      <td className="dropdown">
                                        <i
                                          className="fas fa-ellipsis-v"
                                          id={`dropdown_${i}`}
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="true"
                                        ></i>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby={`dropdown_${i}`}
                                        >
                                          <li>
                                            <Link
                                              onClick={(id, title, date) =>
                                                editTask(
                                                  item._id,
                                                  item.title,
                                                  item.date
                                                )
                                              }
                                            >
                                              <i className="far fa-edit"></i>
                                              Edit
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              onClick={(id) =>
                                                deleteTask(item._id)
                                              }
                                            >
                                              <i className="far fa-trash-alt"></i>
                                              Delete
                                            </Link>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </TabPane>
                        <TabPane tabId={2} className="">
                          <div className="d-flex">
                            <h4 className="mt-2">Reminder</h4>
                            <button
                              type="button"
                              className="btn btncolor btn-rounded btn-fw btn-right btn-plus"
                              onClick={() => {
                                setIsEdit(false);
                                reminderModelToggle();
                                setReminderFormData({
                                  title: "",
                                  date: "",
                                  time: "",
                                  description: "",
                                });
                                setReminderDate(new Date());
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                          <div className="mt-4 table-responsive">
                            <table
                              className="table remider-table"
                              style={
                                reminderList?.length > 2
                                  ? { position: "relative" }
                                  : { position: "unset" }
                              }
                            >
                              <tbody>
                                {reminderList?.map((item, i) => (
                                  <tr key={i}>
                                    <td>
                                      <h6>{item?.title}</h6>
                                    </td>
                                    <td style={{ width: "100%" }}>
                                      <div className="d-flex flex-space-between">
                                        <p>
                                          <Moment format="D MMM YYYY">
                                            {item?.date}
                                          </Moment>
                                        </p>
                                        <p>{item?.time}</p>
                                      </div>
                                    </td>
                                    <td className="dropdown">
                                      <i
                                        className="fas fa-ellipsis-v"
                                        id={`dropdownMenu_${i}`}
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                      ></i>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby={`dropdownMenu_${i}`}
                                      >
                                        <li>
                                          <Link
                                            onClick={(
                                              id,
                                              title,
                                              description,
                                              date,
                                              time
                                            ) =>
                                              editReminder(
                                                item._id,
                                                item.title,
                                                item.description,
                                                item.date,
                                                item.time
                                              )
                                            }
                                          >
                                            <i className="far fa-edit"></i>
                                            Edit
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            onClick={(id) =>
                                              deleteReminders(item._id)
                                            }
                                          >
                                            <i className="far fa-trash-alt"></i>
                                            Delete
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

          {/* Top Users */}
          <Modal
            isOpen={viewModal}
            toggle={() => setViewModal((state) => !state)}
          >
            <ModalHeader toggle={() => setViewModal((state) => !state)}>
              Inquiry Details of{" "}
              {particularUserINQs ? particularUserINQs.name : ""}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-5">
                  <UserINQChart data={PieChartData} />
                </div>
                <div className="col-lg-7 grid-margin stretch-card">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th>Top Employee Name</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>New Inquiry</td>
                          <td>{dataPoint ? dataPoint[0] : 0}</td>
                        </tr>

                        <tr>
                          <td>Contacted</td>
                          <td>{dataPoint ? dataPoint[1] : 0}</td>
                        </tr>

                        <tr>
                          <td>Working</td>
                          <td>{dataPoint ? dataPoint[2] : 0}</td>
                        </tr>
                        <tr>
                          <td>Won</td>
                          <td>{dataPoint ? dataPoint[3] : 0}</td>
                        </tr>
                        <tr>
                          <td>Lost Inquiry</td>
                          <td>{dataPoint ? dataPoint[4] : 0}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* Task Model */}
          <Modal isOpen={taskModels} toggle={taskModelToggle}>
            <ModalHeader toggle={taskModelToggle}>{isEdit?"Update":"Add"} Task</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="title">
                      Add Task <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control emailinput"
                      id="title"
                      placeholder="Enter task"
                      name="title"
                      onChange={(e) => handleChangeTask(e)}
                      value={todoFormData?.title}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Label htmlFor="date">Date</Label>
                    <DatePicker
                      id="date"
                      selected={taskDate}
                      onChange={handleChangeTaskDate}
                      // name="date"
                      className="form-control emailinput"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {isEdit ? (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  onClick={(e) => updateTodos(e)}
                  disabled={Loading || !todoFormData?.title}
                >
                  Update
                </button>
              ) : Loading ? (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  disabled={Loading || !todoFormData?.title}
                >
                  <ClipLoader size={20} color="#fff" className="ml-2" />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  onClick={(e) => submitTodos(e)}
                  disabled={Loading || !todoFormData?.title}
                >
                  Submit
                </button>
              )}
            </ModalFooter>
          </Modal>

          {/* Reminder Model */}
          <Modal isOpen={reminderModels} toggle={reminderModelToggle}>
            <ModalHeader toggle={reminderModelToggle}>{isEdit?"Update":"Add"} Reminder</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="title">
                      Add Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control emailinput"
                      id="title"
                      placeholder="Enter Title"
                      name="title"
                      value={reminderFormData?.title}
                      onChange={(e) => handleChangeReminder(e)}
                    />
                  </div>
                </div>

                <div className={`${isEdit ? "col-md-12" : "col-md-6"}`}>
                  <div className="form-group">
                    <Label htmlFor="date">Date </Label>
                    <DatePicker
                      id="date"
                      selected={reminderDate}
                      onChange={handleChangeReminderDate}
                      name="date"
                      className="form-control emailinput"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
                {isEdit ? (
                  <></>
                ) : (
                  <div className="col-md-6">
                    <div className="form-group">
                      <Label htmlFor="time">Time </Label>
                      <Input
                        id="time"
                        name="time"
                        type="time"
                        className="form-control emailinput"
                        value={reminderFormData?.time}
                        onChange={(e) => handleChangeReminder(e)}
                      ></Input>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="description">
                      Add Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      rows="5"
                      className="form-control emailinput"
                      placeholder="Enter Description"
                      name="description"
                      id="description"
                      onChange={(e) => handleChangeReminder(e)}
                      value={reminderFormData?.description}
                    ></textarea>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {isEdit ? (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  onClick={(e) => updateReminders(e)}
                  disabled={Loading || !reminderFormData?.title}
                >
                  Update
                </button>
              ) : Loading ? (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  disabled={Loading || !reminderFormData?.title}
                >
                  <ClipLoader size={20} color="#fff" className="ml-2" />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btncolor btn-rounded btn-fw"
                  onClick={(e) => submitReminders(e)}
                  disabled={Loading || !reminderFormData?.title}
                >
                  Submit
                </button>
              )}
            </ModalFooter>
          </Modal>

          {/* Modal Ends */}
        </>
      )}
    </>
  );
};

export default Dashboard;
