import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import React, { useEffect, useState } from "react";
import ok from "../../assets/img/ok.png";
import loadergif from "../../assets/img/giphy.webp";
import placeholder from "../../assets/img/placeholder.png";
import Footer from "../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts, getProductImages } from "../../redux/actions/products";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import LazyImage from "../../components/CommonPages/Gallery/LazyImage";

export default function GalleryShare() {
  document.title = "Products Gallery | Glasier Wellness";
  const imageBaseUrl = process.env.REACT_APP_S3_IMAGE_BASE_URL

  const dispatch = useDispatch();
  const { catId, productId } = useParams();
  const { products } = useSelector((state) => state.products);
  const { productImages } = useSelector((state) => state.products);
  const [urls, setUrls] = useState([]);
  const [prodImages, setProdImages] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [productName, setProductName] = useState("");
  const IMAGE_URL = process.env.REACT_APP_IMAGE_PATH;

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  useEffect(() => {
    fetchProductImages();
  }, [dispatch]);

  useEffect(() => {
    setProdImages(productImages);
  }, [productImages]);
  
  useEffect(() => {
    getProducts();
  }, [dispatch]);

  useEffect(() => {
    setProductsList(products);
  }, [products]);

  const getProducts = async () => {
    await dispatch(getAllProducts({ categoryId: catId }));
  };

  useEffect(() => {
    productsList?.filter((product)=>product._id == productId)
    .map((product) => {
      setProductName(product?.productName);
    })
  }, [productsList]);

  const fetchProductImages = async () => {
    setLoader(true);
    await dispatch(getProductImages({ productId: productId })).then(
      async (res) => {
        setLoader(false);
      }
    );
  };

  const selectItem = (e) => {
    const { checked, value } = e.currentTarget;
    setUrls((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };
  const downloadZip = () => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "products.zip";
    urls.forEach(async function (url) {
      const urlArr = url.split("/");
      const filename = urlArr[urlArr.length - 1];
      try {
        const file = await JSZipUtils.getBinaryContent(url);
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
      }
    });
  };
  return (
    <React.Fragment>
      <div className="main-panel" style={{ width: "100%" }}>
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4 justify-content-center">
                <div className="col-md-9 mt-2">
                  <h4 className="main-heading">{capitalize(productName)}</h4>
                </div>
                <div className="col-md-3">
                  {/* <button
                    type="button"
                    onClick={downloadZip}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3"
                    disabled={urls.length === 0}
                    title="Download"
                  >
                    <i className="fa fa-download eyeicon"></i>
                  </button> */}
                </div>
              </div>
              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : prodImages?.length > 0 ? (
                <Gallery 
                  withDownloadButton
                  options={{
                    padding: { top: 20, bottom: 40, left: 100, right: 100 },
                  }}
                >
                <div className="row">
                  {prodImages?.map((image, i) => (
                    <div key={i} className="col-md-2">
                      <label className="pro_img">
                        <Item
                          original={`${imageBaseUrl}/${image}`}
                          thumbnail={`${imageBaseUrl}/${image}`}
                          width="800"
                          height="1024"
                        >
                          {({ ref, open }) => (
                            <>
                              <LazyImage
                                placeholderSrc={loadergif}
                                key={i}
                                reference={ref}
                                onClick={open}
                                src={`${imageBaseUrl}/${image}`}
                                placeholderStyle={{width: '100%'}}
                                errorImg={placeholder}
                               />
                            </>
                          )}
                        </Item>
                        <span style={{ WebkitMaskImage: `url(${ok})`, WebkitMaskBoxImage: `url(${ok})` }}></span>
                      </label>
                    </div>
                  ))}
                </div>
                </Gallery>
              ) : (
                <div className="position-relative text-center">
                  No Image Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
