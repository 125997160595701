import React from "react";
import notFoundImg from "./assets/img/notfound.jpg"

export default function PageNotFound () {
  return (
    <div style={{ 
      backgroundImage: `url(${notFoundImg})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      width: '100vw', 
      height: '100vh'
    }}></div>
  );
}