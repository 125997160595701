import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormFeedback, Input, Row } from "reactstrap";
import { login } from "../../redux/actions/auth";
import logo from "../../assets/img/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROLES } from "../../common/constant";

const Login = ({socket}) => {
  document.title = "Login | Glasier Wellness";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      await dispatch(login(values)).then(async (res) => {
        if (res.status) {
          toast.success(res.message, {
            theme: "colored",
          });
          if (res?.data) {
            localStorage.setItem("accessToken", res?.data.token);
            localStorage.setItem("authUser", JSON.stringify(res?.data.user));
            setIsLoading(false);
            socket.connect();
            // navigate("/");
            if (res?.data?.user?.userType === ROLES.ADMIN) {
              navigate("/admin/dashboard");
            } else if (res?.data?.user?.userType === ROLES.MANAGER) {
              navigate("/manager/dashboard");
            } else if (res?.data?.user?.userType === ROLES.STAFF) {
              navigate("/staff/dashboard");
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          setIsLoading(false);
          toast.error(res.message, {
            theme: "colored",
          });
        }
      });
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div
            className="content-wrapper d-flex align-items-center auth"
            style={{
              backgroundImage:
                "url(" + require("../../assets/img/login_bg.png") + ")",
              backgroundPosition: "bottom",
            }}
          >
            <Row className="row w-100">
              <Col className="col-lg-4 mx-auto">
                <div className="auth-form-light p-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <Form
                    className="forms-sample"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="form-group">
                      <Input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Email*"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Input
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Enter Password*"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="btnmargi">
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btncolor signup-btn mr-2"
                        disabled={isLoading ? isLoading : false}
                      >
                        Sign In
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
