import React, { useEffect, useState } from "react";
import folder from "../../../assets/img/folder.png";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { API_URLS } from "../../../common/constant";
import { Input } from "reactstrap";
import axios from "axios";

export default function GlobalSearch() {
  document.title = "Search | Glasier Wellness";
  const [Token] = useState(localStorage.getItem("accessToken"));
  const [dataList, setDataList] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [categoryString, setCategoryString] = useState("");
  const [productString, setProductString] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const searchCategory = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    if (e.target.value.length > 3) {
      setLoader(true);
      try {
        let config = {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        };
        await axios
          // .post(API_URLS.GET_PRODUCT_CATEGORY, { search: e.target.value }, config)
          .post(
            API_URLS.GLOBAL_PRODUCT_SEARCH,
            { search: e.target.value },
            config
          )
          .then((result) => {
            if (result?.data?.status) {
              setDataList(result?.data?.data);
              setLoader(false);
            } else {
              setLoader(false);
            }
          });
      } catch (error) {
        setLoader(false);
      }
    } else if (e.target.value.length < 1) {
      // getCategories();
      setDataList([]);
    }
  };
  const multipleShare = (e, categoryId, productId) => {
    e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copied!';
    const el = document.createElement("input");
    // el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/category/?categoryId=${categoryId}`;
    if(categoryId !== '' && productId !== ''){
      el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/category/?categoryId=${categoryId}, 
      ${process.env.REACT_APP_LIVE_SHARE_URL}/products/?productId=${productId}`;
    }
    else if(categoryId !== ''){
      el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/category/?categoryId=${categoryId}`;
    }
    else if(productId !== ''){
      el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/products/?productId=${productId}`;
    }
    // el.value = `${
    //   process.env.REACT_APP_LIVE_SHARE_URL
    // }/products/${catId}/?productId=${productString}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copy Link';
    }, 1000);
  };

  const handleMultipleShare = (e) => {
    const { checked, value } = e.currentTarget;
    if (e.currentTarget.name == "category") {
      setCategoryArray((prev) =>
        checked ? [...prev, value] : prev.filter((val) => val !== value)
      );
    } else {
      setProductArray((prev) =>
        checked ? [...prev, value] : prev.filter((val) => val !== value)
      );
    }
  };

  const multipleSelect = () => {
    setSelectMultiple((state) => !state);
    setCategoryArray([]);
    setProductArray([])
  };

  const SelectAll = () => {
    setSelectMultiple(true);
    const categoryIds = dataList?.filter((item => item?.type=='category'))
    .map((data) => {
        return data?._id;
    });
    const productsIds = dataList?.filter((item => item?.type=='product'))
    .map((data) => {
        return data?._id;
    });
    setCategoryArray(categoryIds);
    setProductArray(productsIds);
  };
  const DeSelectAll = () => {
    setSelectMultiple(true);
    setCategoryArray([]);
    setProductArray([])
  };
  useEffect(() => {
    const categoryStringIds = categoryArray?.toString()?.replace(/,/g, "-");
    setCategoryString(categoryStringIds);
  }, [categoryArray]);

  useEffect(() => {
    const productStringIds = productArray?.toString()?.replace(/,/g, "-");
    setProductString(productStringIds);
  }, [productArray]);

  return (
    <React.Fragment>
      <div className="">
        <div className="row mb-4 justify-content-center">
          <div className="col-md-6 ">
            <label className="d-block">
              <Input
                type="search"
                placeholder="Search..."
                aria-controls="order-listing"
                onChange={(e) => searchCategory(e)}
                value={searchText}
                style={{ width: "100%", borderColor: "#5f6368", borderRadius: "30px" }}
              />
            </label>
          </div>
        </div>
        {dataList?.length > 0 ? (
          <div className="row mb-4 justify-content-center">
            <div className="col-md-6 text-center">
              {categoryArray?.length === 0 && productArray?.length === 0 ? (
                <button
                  className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                  onClick={() => SelectAll()}
                  disabled={dataList?.length === 0}
                >
                  Select All
                </button>
              ) : (
                <button
                  className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                  onClick={() => DeSelectAll()}
                  disabled={dataList?.length === 0}
                >
                  Deselect All
                </button>
              )}

              <button
                className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                onClick={() => multipleSelect()}
                disabled={dataList?.length === 0}
              >
                {categoryArray?.length == 0 && productArray?.length === 0 ? "Select" : "Clear"}
              </button>
              <button
                className="btn btncolor btn-rounded btn-fw mb-2"
                onClick={(e) => multipleShare(e, categoryString, productString)}
                disabled={categoryArray?.length === 0 && productArray?.length === 0}
              >
                <i className="fa fa-copy mr-2"></i> Copy Link
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {Loader ? (
          <div className="text-center">
            <ClipLoader size={40} color="#264B75" />
          </div>
        ) : dataList?.length > 0 ? (
          <div className="row search-result">
            {dataList
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((data, i) => (
                <div key={i} className="col-md-2 mb-3">
                  <div
                    className={
                      categoryArray?.find((item2) => data?._id == item2) ||
                      // productArray?.find(
                      //   (item2) => data?._id == item2?.productId
                      // )
                      productArray?.find(
                        (item2) => data?._id == item2
                      )
                        ? "category-box selected"
                        : "category-box"
                    }
                  >
                    {data?.type === "product" ? (
                      <>
                        {selectMultiple ? (
                          <div className="form-check share-checkbox">
                            <label className="form-check-label form-label">
                              <input
                                type="checkbox"
                                className="form-check-input form-check-input"
                                name="product"
                                value={data?._id}
                                onChange={(e) => handleMultipleShare(e)}
                                checked={
                                  productArray?.find(
                                    (item2) => data?._id == item2
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        <Link
                          to={`/gallery/${data?.categoryId}/${data?._id}`}
                          className="category-box-link"
                          data-type={data?.type}
                          target="_blank"
                        >
                          <div
                            className="folder-icon"
                            style={{ WebkitMaskImage: `url(${folder})` }}
                          ></div>
                          <h5 className="cat-title mb-0">
                            {capitalize(data?.productName)}
                          </h5>
                        </Link>
                      </>
                    ) : (
                      <>
                        {selectMultiple ? (
                          <div className="form-check share-checkbox">
                            <label className="form-check-label form-label">
                              <input
                                type="checkbox"
                                className="form-check-input form-check-input"
                                name="category"
                                value={data?._id}
                                onChange={(e) => handleMultipleShare(e)}
                                checked={
                                  categoryArray?.find(
                                    (item2) => data?._id == item2
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        <Link
                          to={`/products/${data?._id}`}
                          className="category-box-link"
                          data-type={data?.type}
                          target="_blank"
                        >
                          <div
                            className="folder-icon"
                            style={{ WebkitMaskImage: `url(${folder})` }}
                          ></div>
                          <h5 className="cat-title mb-0">
                            {capitalize(data?.categoryName)}
                          </h5>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="position-relative text-center">No Data Found!</div>
        )}
      </div>
    </React.Fragment>
  );
}
