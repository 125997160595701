import {
  CREATE_INQUIRY_FAIL,
  CREATE_INQUIRY_REQUEST,
  CREATE_INQUIRY_SUCCESS,
  FETCH_INQUIRY_FAIL,
  FETCH_INQUIRY_REQUEST,
  FETCH_INQUIRY_SUCCESS,
  GET_INQUIRY_BY_USER_SUCCESS,
  UPDATE_INQUIRY_FAIL,
  UPDATE_INQUIRY_REQUEST,
  FETCH_SINGLE_INQUIRY_REQUEST,
  FETCH_SINGLE_INQUIRY_SUCCESS,
  FETCH_SINGLE_INQUIRY_FAIL,
} from "../../constants/inquiryConstants";

let initialState = {
  inquiry: [],
  inquiries: [],
  userINQs: [],
  statusWiseCount: [],
  loading: false,
};

export const InquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INQUIRY_REQUEST:
    case UPDATE_INQUIRY_REQUEST:
    case FETCH_INQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiries: action.payload,
      };
    case GET_INQUIRY_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userINQs: action.payload,
      };
    case FETCH_SINGLE_INQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiry: action.payload,
      };
    case FETCH_SINGLE_INQUIRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiries: action?.payload ? action.payload : [...state],
      };
    case FETCH_INQUIRY_FAIL:
      return {
        ...state,
        loading: false,
        inquiries: [],
        error: action.payload,
      };
    case CREATE_INQUIRY_FAIL:
    case UPDATE_INQUIRY_FAIL:
    default:
      return state;
  }
};
