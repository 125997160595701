import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dashboardPNG from "../assets/img/menu/dashboard-select.png";
import userPNG from "../assets/img/menu/user.png";
import inquiryFormPNG from "../assets/img/menu/InquiryForm.png";
import reportPNG from "../assets/img/menu/Report.png";
import inquiryReportPNG from "../assets/img/menu/InquiryReport.png";
import inquiryReminderPNG from "../assets/img/menu/InquiryReminder.png";
import toDOList from "../assets/img/menu/to-do-list.png";
import Avatar from "../common/Avatar";
import { ROLES } from "../common/constant";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/actions/users";
// icons
import dashboardIcon from "../assets/img/menu-icons/grid.svg";
import trelloIcon from "../assets/img/menu-icons/trello.svg";
import sourcesIcon from "../assets/img/menu-icons/source.png";
import galleryIcon from "../assets/img/menu-icons/gallery.png";

const SideBar = ({ path }) => {
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [fullName, setFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  let URL =
    authUser && authUser.userType === ROLES.ADMIN
      ? "/admin/dashboard"
      : authUser && authUser.userType === ROLES.MANAGER
      ? "/manager/dashboard"
      : authUser && authUser.userType === ROLES.STAFF
      ? "/staff/dashboard"
      : "/dashboard";

  const getUser = async () => {
    await dispatch(getUserProfile());
  };

  useEffect(() => {
    getUser();
  }, [dispatch]);
  useEffect(() => {
    setFullName(user?.full_name);
    setUserRole(user?.userType)
  }, [user]);
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <div className="prodis">
              <Avatar className="img-lg proimg" name={user?.full_name?authUser?.full_name:'Guest'} />
              <div className="mt-3">
                <h4 className="profilrtext">
                  {fullName && fullName ? (
                    <span>{fullName}</span>
                  ) : (
                    authUser?.full_name
                  )}
                </h4>
                
                {userRole && userRole ? (
                    <span className="user-role">{userRole}</span>
                  ) : (
                    <span className="user-role">{authUser?.userType}</span>
                  )}
              </div>
            </div>
          </li>
          <li
            className={`nav-item ${
              path.match(URL) ||
              path.match(`/${authUser?.userType.toLowerCase()}/profile`) ||
              path.match(`/${authUser?.userType.toLowerCase()}/edit-profile`) ||
              path.match(`/profile`) ||
              path.match(`/edit-profile`)
                ? "active"
                : ""
            }`}
          >
            <Link to={URL} className="nav-link">
              <span
                className="icon-menus"
                style={{ WebkitMaskImage: `url("${dashboardIcon}")` }}
              ></span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {/* ADMIN */}
          {authUser && authUser.userType === ROLES.ADMIN ? (
            <>
              <li
                className={`nav-item ${
                  path.match("/admin/inquiry-dashboard") ||
                  path.match("/admin/inquiry-details") ||
                  path.match("/admin/inquiry-reminder") ||
                  path.match("/admin/add-inquiry") ||
                  path.match("/admin/edit-inquiry") ||
                  path.match("/admin/view-inquiry")
                    ? "active"
                    : ""
                }`}
              >
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#page-layouts"
                  aria-expanded={`${
                    path.match("/admin/inquiry-dashboard") ||
                    path.match("/admin/inquiry-details") ||
                    path.match("/admin/inquiry-reminder") ||
                    path.match("/admin/add-inquiry") ||
                    path.match("/admin/edit-inquiry") ||
                    path.match("/admin/view-inquiry")
                      ? "true"
                      : "false"
                  }`}
                  aria-controls="page-layouts"
                >
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${trelloIcon}")` }}
                  ></span>
                  <span className="menu-title">Inquiry</span>
                  <i className="menu-arrow"></i>
                </a>
                <div
                  className={`collapse ${
                    path.match("/admin/inquiry-dashboard") ||
                    path.match("/admin/inquiry-details") ||
                    path.match("/admin/inquiry-reminder") ||
                    path.match("/admin/add-inquiry") ||
                    path.match("/admin/edit-inquiry") ||
                    path.match("/admin/view-inquiry")
                      ? "show"
                      : ""
                  }`}
                  id="page-layouts"
                >
                  <ul className="nav flex-column sub-menu">
                    <li
                      className={`nav-item d-none d-lg-block ${
                        path.match("/admin/inquiry-dashboard") ? "active" : ""
                      }`}
                    >
                      {" "}
                      <Link
                        className={`nav-link`}
                        to="/admin/inquiry-dashboard"
                      >
                        {/* <img src={dashboardPNG} /> */}
                        <span
                          className="icon-menus"
                          style={{
                            WebkitMaskImage: `url("${dashboardPNG}")`,
                          }}
                        ></span>
                        Dashboard
                      </Link>
                    </li>
                    <li
                      className={`nav-item d-none d-lg-block ${
                        path.match("/admin/inquiry-details") ||
                        path.match("/admin/add-inquiry") ||
                        path.match("/admin/edit-inquiry") ||
                        path.match("/admin/view-inquiry")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link className={`nav-link`} to="/admin/inquiry-details">
                        {/* <img src={inquiryReportPNG} /> */}
                        <span
                          className="icon-menus"
                          style={{
                            WebkitMaskImage: `url("${inquiryReportPNG}")`,
                          }}
                        ></span>
                        Inquiry Details
                      </Link>
                    </li>

                    <li
                      className={`nav-item d-none d-lg-block ${
                        path.match("/admin/inquiry-reminder") ? "active" : ""
                      }`}
                    >
                      <Link className={`nav-link`} to="/admin/inquiry-reminder">
                        {/* <img src={inquiryReminderPNG} /> */}
                        <span
                          className="icon-menus"
                          style={{
                            WebkitMaskImage: `url("${inquiryReminderPNG}")`,
                          }}
                        ></span>
                        Inquiry Reminder
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={`nav-item ${
                  path.match("/admin/users") || path.match("/admin/add-users")
                    ? "active"
                    : ""
                }`}
              >
                <Link to={"/admin/users"} className="nav-link">
                  {/* <img src={userPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${userPNG}")` }}
                  ></span>
                  <span className="menu-title">Users</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/admin/source-list") ||
                  path.match("/admin/add-source")
                    ? "active"
                    : ""
                }`}
              >
                <Link to={"/admin/source-list"} className="nav-link">
                  {/* <i className="fa fa-university menu-icon"></i> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${sourcesIcon}")` }}
                  ></span>
                  <span className="menu-title">Sources</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/admin/todo-list") ? "active" : ""
                }`}
              >
                <Link to={"/admin/todo-list"} className="nav-link">
                  {/* <img src={toDOList} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${toDOList}")` }}
                  ></span>
                  <span className="menu-title">To Do List</span>
                </Link>
              </li>
            </>
          ) : authUser && authUser.userType === ROLES.MANAGER ? (
            <>
              <li
                className={`nav-item ${
                  path.match("/manager/add-inquiry") ? "active" : ""
                }`}
              >
                <Link to={"/manager/add-inquiry"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${inquiryFormPNG}")` }}
                  ></span>
                  <span className="menu-title">Inquiry Form</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/manager/manager-report") ||
                  path.match("/manager/view-inquiry") ||
                  path.match("/manager/edit-inquiry")
                    ? "active"
                    : ""
                }`}
              >
                <Link to={"/manager/manager-report"} className="nav-link">
                  {/* <img src={reportPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${reportPNG}")` }}
                  ></span>
                  <span className="menu-title">Report</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/manager/inquiry-report") ? "active" : ""
                }`}
              >
                <Link to={"/manager/inquiry-report"} className="nav-link">
                  {/* <img src={inquiryReportPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{
                      WebkitMaskImage: `url("${inquiryReportPNG}")`,
                    }}
                  ></span>
                  <span className="menu-title">Inquiry Report</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/manager/inquiry-reminder") ? "active" : ""
                }`}
              >
                <Link to={"/manager/inquiry-reminder"} className="nav-link">
                  {/* <img src={inquiryReminderPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{
                      WebkitMaskImage: `url("${inquiryReminderPNG}")`,
                    }}
                  ></span>
                  <span className="menu-title">Inquiry Reminder</span>
                </Link>
              </li>

              <li
                className={`nav-item ${
                  path.match("/manager/todo-list") ? "active" : ""
                }`}
              >
                <Link to={"/manager/todo-list"} className="nav-link">
                  {/* <img src={toDOList} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${toDOList}")` }}
                  ></span>
                  <span className="menu-title">To Do List</span>
                </Link>
              </li>
            </>
          ) : authUser && authUser.userType === ROLES.STAFF ? (
            <>
              <li
                className={`nav-item ${
                  path.match("/staff/add-inquiry") ? "active" : ""
                }`}
              >
                <Link to={"/staff/add-inquiry"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${inquiryFormPNG}")` }}
                  ></span>
                  <span className="menu-title">Inquiry Form</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/staff/inquiry-report") ||
                  path.match("/staff/view-inquiry") ||
                  path.match("/staff/edit-inquiry")
                    ? "active"
                    : ""
                }`}
              >
                <Link to={"/staff/inquiry-report"} className="nav-link">
                  {/* <img src={reportPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${reportPNG}")` }}
                  ></span>
                  <span className="menu-title">Report</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/staff/inquiry-reminder") ? "active" : ""
                }`}
              >
                <Link to={"/staff/inquiry-reminder"} className="nav-link">
                  {/* <img src={inquiryReminderPNG} /> */}
                  <span
                    className="icon-menus"
                    style={{
                      WebkitMaskImage: `url("${inquiryReminderPNG}")`,
                    }}
                  ></span>
                  <span className="menu-title">Inquiry Reminder</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  path.match("/staff/todo-list") ? "active" : ""
                }`}
              >
                <Link to={"/staff/todo-list"} className="nav-link">
                  {/* <img src={toDOList} /> */}
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${toDOList}")` }}
                  ></span>
                  <span className="menu-title">To Do List</span>
                </Link>
              </li>
            </>
          ) : (
            <>
              {/* Rated Inquiry */}
              <li
                className={`nav-item ${path.match("/rated-inquiry") ? "active" : ""}`}
              >
                <Link to={"/rated-inquiry"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${inquiryReportPNG}")` }}
                  ></span>
                  <span className="menu-title">Rate Inquiry</span>
                </Link>
              </li>


              {/* Rated Inquiry */}
              <li
                className={`nav-item ${path.match("/thousand-unit-inquiry") ? "active" : ""}`}
              >
                <Link to={"/thousand-unit-inquiry"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${inquiryReportPNG}")` }}
                  ></span>
                  <span className="menu-title">Thousand Inquiry</span>
                </Link>
              </li>

              {/* Requirements */}
              <li
                className={`nav-item ${
                  path.match("/requirements") ? "active" : ""
                }`}
              >
                <Link to={"/requirements"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${galleryIcon}")` }}
                  ></span>
                  <span className="menu-title">Requirements</span>
                </Link>
              </li>
            </>
          )}
          <>
              <li
                className={`nav-item ${
                  path.match("/product-gallery") ||
                  path.match("/products") ||
                  path.match("/gallery")
                    ? "active"
                    : ""
                }`}
              >
                <Link to={"/product-gallery"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${galleryIcon}")` }}
                  ></span>
                  <span className="menu-title">Product Gallery</span>
                </Link>
              </li>

              {/* Chat Menu */}
              <li
                className={`nav-item ${
                  path.match("/chat") ? "active" : ""
                }`}
              >
                <Link to={"/chat"} className="nav-link">
                  <span
                    className="icon-menus"
                    style={{ WebkitMaskImage: `url("${galleryIcon}")` }}
                  ></span>
                  <span className="menu-title">Chat</span>
                </Link>
              </li>
          </>
        </ul>
      </nav>
    </>
  );
};

export default SideBar;
