import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Footer from "../Footer";
import folder from "../../assets/img/folder.png";
import { API_URLS } from "../../common/constant";
import axios from "axios";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ShareGlobalSearch from "./ShareGlobalSearch";
function MultiCategoryProductShare() {
  document.title = "Product List | Glasier Wellness";
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("productId");
  const { products } = useSelector((state) => state.products);
  const [Loader, setLoader] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [productId, setProductId] = useState([]);
  const [globalSearch, setGlobalSearch] = useState(false);
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  useEffect(() => {
    setProductId(queryParams?.split("-"));
  }, [queryParams]);

  const getProducts = async () => {
    setLoader(true);
    try {
      let config = {
      };
      await axios
        .post(API_URLS.GET_MULTIPLE_PRODUCTS, { productIds: queryParams?.split("-") }, config)
        .then((result) => {
          if (result?.data?.status) {
            setProductsList(result?.data?.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        });
    } catch (error) {
      setLoader(false);
    }
  };
  
  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  useMemo(() => getProducts(productId), [productId]);

  useEffect(() => {
    if (queryParams !== null) {
      const filteredProducts = products?.filter((product) => {
        return productId?.find((id) => product._id == id);
      });
      setProductsList(filteredProducts);
    } else {
      setProductsList(products);
    }
  }, [products]);
  return (
    <React.Fragment>
      <div className="main-panel" style={{ width: "100%" }}>
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-5 justify-content-center">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center mt-2">
                  <h4 className="main-heading mb-0">Products</h4>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>
                </div>
              </div>

              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : productsList?.length > 0 ? (
                <div className="row">
                  {productsList
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((product, i) => (
                      <div key={i} className="col-md-2 mb-3">
                        <div className="category-box">
                          <Link
                            to={`/share/gallery/${product?.categoryId}/${product?._id}`}
                            className="category-box-link"
                          >
                            <div
                              className="folder-icon"
                              style={{ WebkitMaskImage: `url(${folder})` }}
                            ></div>
                            <h5 className="cat-title mb-0">
                              {capitalize(product?.productName)}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="position-relative text-center">
                  No Product Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Global Search Popup */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <ShareGlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
export default React.memo(MultiCategoryProductShare);
