import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Row,
} from "reactstrap";
// Formik
import * as Yup from "yup";
import { Formik, Form } from "formik";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { getPartyNames } from "../../../../redux/actions/inquiry";
export default function BasicDetails({
  toggleTab,
  handleNextStep,
  data,
  users,
  source,
}) {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPartyNames, setSearchPartyName] = useState([]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setTimeout(() => {
      handleNextStep(values);
      toggleTab(1);
    }, 500);
  };
  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };
    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setCountries(response?.data);
      } else {
        setCountries([]);
        setStates([]);
        setCities([]);
      }
    }
  };

  const getStates = async (countryCode) => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };

    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/" + countryCode + "/states",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setStates(response?.data);
      } else {
        setStates([]);
        setCities([]);
      }
    }
  };

  const getCities = async (stateCode) => {
    const options = {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "WVBOZ0tVajRLMElWcG55YlJPb3lTbFBNdjFvakpXRzVVV0hJeTZ2UQ==",
      },
      redirect: "follow",
    };
    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/" +
        countryCode +
        "/states/" +
        stateCode +
        "/cities",
      options
    );
    if (response.status == 200) {
      if (response?.data) {
        setCities(response?.data);
      } else {
        setCities([]);
      }
    }
  };

  const BasicDetailschema = Yup.object({
    partyName: Yup.string().required("Please Enter Party Name"),
    // companyName: Yup.string().required("Please Enter Company Name"),
    contactNumber: Yup.string().required("Please Enter Contact number"),
    // email: Yup.string()
    //   .email("Invalid Email Address")
    //   .required("Please Enter Your Email"),
    // source: Yup.string().required("Please Select Source of Inquiry"),
    person: Yup.string().required("Please Select Person"),
    country: Yup.string().required("Please Select Country"),
    state: Yup.string().required("Please Select State"),
    city: Yup.string().required("Please Select City"),
  });
  return (
    <Card>
      <CardBody>
        <Formik
          validationSchema={BasicDetailschema}
          initialValues={data}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            // step one form
            <Form
              onChange={async (e) => {
                if (e.target.name == "country") {
                  getStates(e.target.value);
                  setCountryCode(e.target.value);
                  let index = e.nativeEvent.target.selectedIndex;
                  setFieldValue("country", e.nativeEvent.target[index].text);
                  if (e.target.value == "") {
                    setStates([]);
                    setCities([]);
                  }
                } else if (e.target.name == "state") {
                  getCities(e.target.value);
                  let index = e.nativeEvent.target.selectedIndex;
                  setFieldValue("state", e.nativeEvent.target[index].text);
                  if (e.target.value == "") {
                    setCities([]);
                  }
                } else if (e.target.name == "partyName") {
                  const res = await dispatch(
                    getPartyNames({ search: e.target.value })
                  );
                  if (res.status) {
                    setSearchPartyName(res?.data);
                  }
                }
                return false;
              }}
            >
              <Row>
                <Col className="md-12">
                  <div className="row">
                    {/* partyName */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="partyName">
                          Party Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id="partyName"
                          name="partyName"
                          list="partyNameList"
                          className="form-control emailinput"
                          placeholder="Enter Party Name*"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.partyName || ""}
                          invalid={
                            touched.partyName && errors.partyName ? true : false
                          }
                        />

                        {touched.partyName && errors.partyName ? (
                          <FormFeedback type="invalid">
                            {errors.partyName}
                          </FormFeedback>
                        ) : null}
                        <datalist id="partyNameList">
                          {searchPartyNames?.map((name) => (
                            <option value={name} />
                          ))}
                        </datalist>
                      </div>
                    </div>

                    {/* companyName */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="companyName">
                          Company name 
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>

                        <Input
                          id="companyName"
                          name="companyName"
                          className="form-control emailinput"
                          placeholder="Enter Company Name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName || ""}
                          invalid={
                            touched.companyName && errors.companyName
                              ? true
                              : false
                          }
                        />
                        {touched.companyName && errors.companyName ? (
                          <FormFeedback type="invalid">
                            {errors.companyName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* contactNumber */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="contactNumber">
                          Contact Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id="contactNumber"
                          name="contactNumber"
                          className="form-control emailinput"
                          placeholder="Enter Contact Number*"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactNumber || ""}
                          invalid={
                            touched.contactNumber && errors.contactNumber
                              ? true
                              : false
                          }
                        />
                        {touched.contactNumber && errors.contactNumber ? (
                          <FormFeedback type="invalid">
                            {errors.contactNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* email */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email">
                          Email ID 
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control emailinput"
                          placeholder="Enter Eamil ID"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email || ""}
                          invalid={touched.email && errors.email ? true : false}
                        />
                        {touched.email && errors.email ? (
                          <FormFeedback type="invalid">
                            {errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* address */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <Input
                          id="address"
                          name="address"
                          className="form-control emailinput"
                          placeholder="Enter Address"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address || ""}
                          invalid={
                            touched.address && errors.address ? true : false
                          }
                        />
                        {touched.address && errors.address ? (
                          <FormFeedback type="invalid">
                            {errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* source */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="source">
                          Source of Inquiry{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <Input
                          id="source"
                          name="source"
                          type="select"
                          className="form-control emailinput"
                          invalid={
                            touched.source && errors.source ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Source of Inquiry</option>
                          {source &&
                            source?.map((sourc, i) => (
                              <option key={i} value={sourc._id}>
                                {sourc.sourceName}
                              </option>
                            ))}
                        </Input>
                        {touched.source && errors.source ? (
                          <FormFeedback type="invalid">
                            {errors.source}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* person */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="person">
                          Select Person <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id="person"
                          name="person"
                          type="select"
                          className="form-control emailinput"
                          invalid={
                            touched.person && errors.person ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Person</option>
                          {users &&
                            users?.map((user, i) => (
                              <option key={i} value={user._id}>
                                {user.full_name}
                              </option>
                            ))}
                        </Input>
                        {touched.person && errors.person ? (
                          <FormFeedback type="invalid">
                            {errors.person}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* country */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="country">
                          Country <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id="country"
                          name="country"
                          type="select"
                          className="form-control emailinput"
                          invalid={
                            touched.country && errors.country ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Country</option>
                          {countries &&
                            countries?.map((country, i) => (
                              <option key={i} value={country.iso2}>
                                {country.name}
                              </option>
                            ))}
                        </Input>
                        {touched.country && errors.country ? (
                          <FormFeedback type="invalid">
                            {errors.country}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* state */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="state">
                          State <span style={{ color: "red" }}>*</span>
                        </label>
                        {states?.length > 0 ? (
                          <Input
                            id="state"
                            name="state"
                            type="select"
                            className="form-control emailinput"
                            invalid={
                              touched.state && errors.state ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select State</option>
                            {states &&
                              states?.map((state, i) => (
                                <option key={i} value={state.iso2}>
                                  {state.name}
                                </option>
                              ))}
                          </Input>
                        ) : (
                          <Input
                            id="state"
                            name="state"
                            type="text"
                            placeholder="Enter State"
                            className="form-control emailinput"
                            invalid={
                              touched.state && errors.state ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                        {touched.state && errors.state ? (
                          <FormFeedback type="invalid">
                            {errors.state}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* city */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="city">
                          City
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {states?.length > 0 && cities?.length > 0 ? (
                          <Input
                            id="city"
                            name="city"
                            type="select"
                            className="form-control emailinput"
                            invalid={touched.city && errors.city ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select City</option>
                            {cities &&
                              cities?.map((city, i) => (
                                <option key={i} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                          </Input>
                        ) : (
                          <Input
                            id="city"
                            name="city"
                            type="text"
                            placeholder="Enter City"
                            className="form-control emailinput"
                            invalid={touched.city && errors.city ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                        {touched.city && errors.city ? (
                          <FormFeedback type="invalid">
                            {errors.city}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername1">Remark</label>
                        <Input
                          id="remark"
                          name="remark"
                          className="form-control emailinput"
                          placeholder="Enter any remark*"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remark || ""}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-5 text-center">
                      <button
                        type="submit"
                        className="btn btncolor btn-rounded btn-fw"
                        disabled={isLoading}
                      >
                        Next
                        {isLoading ? (
                          <ClipLoader size={20} color="#fff" className="ml-2" />
                        ) : null}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
}
