import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IsAuth from "../../auth/IsAuth";
import { addSource } from "../../../redux/actions/sources";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../layout/Footer";
import { ClipLoader } from "react-spinners";

export default function AddSource() {
  document.title = `Add Source | Glasier Wellness`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sourceName, setSourceName] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  const handleChange = (e) => {
    setisLoading(false);
    if (e.target.value == "") {
      setisLoading(true);
    }
    setSourceName(e.target.value);
  };

  const addSources = async () => {
    setisLoading(true);
    await dispatch(addSource({ sourceName: sourceName })).then(async (res) => {
      if (res.status) {
        toast.success(res.message, {
          theme: "colored",
        });
        // setTimeout(() => {
          navigate(-1);
        // }, 500);
        setisLoading(false);
      } else {
        toast.error(res.message, {
          theme: "colored",
        });
        setisLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="main-heading mb-0">Add Source</h4>
                  {/* <h4 style={{ fontSize: 20 }}>
                    Source can be managed from here
                  </h4> */}
                </div>
                <div className="col-md-6 text-right">
                  <Link
                    to="/admin/source-list"
                    className="btn btncolor btn-rounded btn-fw"
                  >
                    {" "}
                    <i
                      className="fa fa-chevron-left"
                      style={{ fontSize: 14 }}
                    ></i>{" "}
                    &nbsp;Back
                  </Link>
                </div>
              </div>

              <>
                <div className="row mt-4 align-items-center">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="sourceName">
                        Source Name <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="text"
                        name="sourceName"
                        className="form-control emailinput"
                        id="sourceName"
                        placeholder="India Mart"
                        value={sourceName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {isLoading ? (
                      <button
                        type="button"
                        className="btn btncolor btn-rounded btn-fw"
                        disabled={isLoading}
                      >
                        <ClipLoader size={20} color="#fff" className="ml-2" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btncolor btn-rounded btn-fw"
                        onClick={(e) => addSources(e)}
                        disabled={isLoading}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
