import React, { useEffect, useState } from "react";
import folder from "../../assets/img/folder.png";

import Footer from "../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/actions/products";
import { Link, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ShareGlobalSearch from "./ShareGlobalSearch";

export default function CategoryShare() {
  document.title = "Products Category | Glasier Wellness";
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("categoryId");
  const { categories } = useSelector((state) => state.products);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(false);
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  useEffect(() => {
    setCategoryId(queryParams?.split("-"));
  }, [queryParams]);

  useEffect(() => {
    getCategories();
  }, [dispatch]);

  const getCategories = async () => {
    setLoader(true);
    await dispatch(getAllCategories()).then(async (res) => {
      setLoader(false);
    });
  };

  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  useEffect(() => {
    if (queryParams !== null) {
      const filteredCategories = categories?.filter((category) => {
        return categoryId?.find((id) => category._id == id);
      });
      setCategoryList(filteredCategories);
    } else {
      setCategoryList(categories);
    }
  }, [categories, queryParams]);

  return (
    <React.Fragment>
      <div className="main-panel" style={{ width: "100%" }}>
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-3"></div>
                <div className="col-md-6 mt-2 text-center">
                  <h4 className="main-heading">Category List</h4>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>
                </div>
              </div>
              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : categoryList?.length > 0 ? (
                <div className="row">
                  {categoryList
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((category, i) => (
                      <div key={i} className="col-md-2 mb-3">
                        <div className="category-box">
                          <Link
                            to={`/share/products/${category._id}`}
                            className="category-box-link"
                          >
                            <div
                              className="folder-icon"
                              style={{ WebkitMaskImage: `url(${folder})` }}
                            ></div>
                            <h5 className="cat-title mb-0">
                              {capitalize(category?.categoryName)}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="position-relative text-center">
                  No Product Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Global Search Popup */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <ShareGlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
