import React, { useEffect, useState } from "react";
import { changeStatus } from "../../../redux/actions/sources";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
export default function ToggleSwitch({ status, id }) {
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = useState(status);
  const handleStatus = () => {
    toggleChecked(!checkbox, id);
    setCheckbox(!checkbox);
  };
  useEffect(() => {
  }, [checkbox]);

  const toggleChecked = async (stats, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          changeStatus({ id: id, status: stats?1:0 })
        );
        if (res.status) {
          Swal.fire("Success!", res.message, "success");
        } else {
          setCheckbox(status);
          Swal.fire("Error!", res.message, "error");
        }
      }
      else{
        setCheckbox(status);
      }
    });
  };

  return (
    <>
      <label className="switch1">
        <input
          type="checkbox"
          onChange={() => {
            handleStatus();
          }}
          checked={checkbox}
        />
        <span className="slider1 round1"></span>
      </label>
    </>
  );
}
