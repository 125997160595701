import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import Footer from "../../../../layout/Footer";
import moment from "moment-timezone";
import {
  getAllUsers,
  getManagerStaffUsers,
} from "../../../../redux/actions/users";
import { fetchAllSource } from "../../../../redux/actions/sources";
import { API_URLS } from "../../../../common/constant";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../../layout/FilterComponent";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import IsAuth from "../../../auth/IsAuth";
export default function ThousandUnitInquiry() {
  const dispatch = useDispatch();
  document.title = `More Then Thousand Inquiry | Glasier Wellness`;
  const { inquiries } = useSelector((state) => state.inquiry);
  const [inquiryList, setInquiryList] = useState(inquiries);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");

  const [totalRows, setTotalRows] = useState(inquiries?.length);
  const [perPage, setPerPage] = useState(10);

  const getUserData = async () => {
    const res = await dispatch(getAllUsers());
    if (!res.status) {
      await dispatch(getManagerStaffUsers());
    }
  };
  const getSources = async () => {
    const res = await dispatch(fetchAllSource());
  };

  useEffect(() => {
    getUserData();
  }, [dispatch]);

  useEffect(() => {
    getSources();
  }, [dispatch]);


  useEffect(() => {
    fetchInquiries(1); // fetch page 1 of users
  }, [dispatch]);

  const fetchInquiries = async (page) => {
    setIsLoading(true);
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        {
          moreThanOneThousandUnits: true,
          page: page,
          limit: perPage,
        },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchInquiries(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        {
          moreThanOneThousandUnits: true,
          page: page,
          limit: newPerPage,
        },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      width: "200px",
    },
    {
      name: "Party Details",
      selector: (row) => row.partyDetails,
    },
    {
      name: "Requirement/ Units",
      selector: (row) => row.requirements,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];
  const rows = [
    ...inquiryList
      ?.filter(
        (item) =>
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.partyName &&
            item.partyName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((row, order) => ({
        date: (
          <>
            <span>
              <Moment format="D MMM YYYY" withTitle>
                {row.date}
              </Moment>
            </span>
            <br></br>
            <br></br>
            <span>{row.companyName}</span>
            <br></br>
            <br></br>
            {row?.isRead == 0 ? (
              <span className={`w-h-15 small-box-red`}></span>
            ) : null}
            {moment().diff(row?.date, "h") <= 48 ? (
              <span className={`w-h-15 small-box-green`}></span>
            ) : (
              ""
            )}
            {moment().diff(row?.date, "h") >= 48 &&
            moment().diff(row?.date, "h") <= 72 ? (
              <span className={`w-h-15 small-box-orange`}></span>
            ) : moment().diff(row?.date, "h") > 72 && row?.stepStatus=='new' ?(
              <span className={`w-h-15 small-box-red`}></span>
            ):""}
          </>
        ),
        partyDetails: (
          <>
            <div className="thirdcol">
              <ul className="thirdul">
                <li>
                  {" "}
                  <strong> Party Name : </strong>{" "}
                  {row.partyName ? row.partyName : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Email ID :</strong> {row.email ? row.email : "NA"}{" "}
                </li>
                <li>
                  {" "}
                  <strong>Contact No. : </strong>
                  {row.contactNumber ? row.contactNumber : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Location : </strong>
                  {row.address && row.address} {row.city && row.city}{" "}
                  {row.state && row.state}
                  {row.country && ", " + row.country}{" "}
                  {row.pinCode && " - " + row.pinCode}
                </li>
              </ul>
            </div>
          </>
        ),
        requirements: (
          <>
            {row.requirements?.map((item, i) => (
              <div key={i}>
                <p className="textcolor mb-1">
                  {" "}
                  {item.requirement && item.requirement}{" "}
                </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Units :- </strong>
                    {item.units && item.units}
                  </li>
                </ul>
              </div>
            ))}
            {row.remark && (
              <div>
                <p className="textcolor mb-1"> Remarks </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Type : </strong> {row.remark}
                  </li>
                </ul>
              </div>
            )}
          </>
        ),
        action: (
          <>
            <Link
              to={`/view-inquiry/${row._id}`}
              className="iconbgview"
            >
              <i className="fa fa-eye eyeicon"></i>
            </Link>
          </>
        ),
      })),
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
    <IsAuth />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              {/* <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="startDate">From</label>
                    <Input
                      type="date"
                      className="form-control emailinput"
                      // onChange={(e) => setStartDate(e.target.value)}
                      name="startDate"
                      onChange={(e) =>
                        // handleSearch({ startDate: e.target.value })
                        {
                          if (e.target.value != "") {
                            setStartDate({
                              startDate: moment(e.target.value).format(
                                "DD-MM-YYYY"
                              ),
                            });
                          } else {
                            setStartDate("");
                          }
                        }
                      }
                      invalid={startDateErr && startDateErr ? true : false}
                    />

                    {startDateErr && startDateErr ? (
                      <FormFeedback type="invalid">{startDateErr}</FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="endDate">To</label>
                    <Input
                      type="date"
                      className="form-control emailinput"
                      // onChange={(e) => setEndDate(e.target.value)}
                      name="endDate"
                      // onChange={(e) => handleSearch(e)}
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setEndDate({
                            endDate: moment(e.target.value).format(
                              "DD-MM-YYYY"
                            ),
                          });
                        } else {
                          setEndDate("");
                        }
                      }}
                      invalid={endDateErr && endDateErr ? true : false}
                    />
                    {endDateErr && endDateErr ? (
                      <FormFeedback type="invalid">{endDateErr}</FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect2">Staff</label>
                    <Input
                      id="userId"
                      name="userId"
                      type="select"
                      className="form-control emailinput"
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setStaffSearch({ userId: e.target.value });
                        } else {
                          setStaffSearch("");
                        }
                      }}
                      // onChange={(e) => handleSearch(e)}
                    >
                      <option value="" hidden>
                        Select Staff
                      </option>
                      {users &&
                        users?.map((user, i) => (
                          <option key={i} value={user._id}>
                            {user.full_name}
                          </option>
                        ))}
                    </Input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="source">Source of Inquiry</label>
                    <Input
                      id="source"
                      name="source"
                      type="select"
                      className="form-control emailinput"
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setSourceSearch({ sourceId: e.target.value });
                        } else {
                          setSourceSearch("");
                        }
                      }}
                      // onChange={(e) => handleSearch(e)}
                    >
                      <option value="" hidden>
                        Source
                      </option>
                      {source &&
                        source?.map((sourc, i) => (
                          <option key={i} value={sourc._id}>
                            {sourc.sourceName}
                          </option>
                        ))}
                    </Input>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="stage">Stage</label>
                    <select
                      className="form-control emailinput"
                      id="stage"
                      name="stage"
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setStageSearch({ stepStatus: e.target.value });
                        } else {
                          setStageSearch("");
                        }
                      }}
                      // onChange={(e) => handleSearch(e)}
                    >
                      <option value={""} hidden> All Stage</option>
                      <option value={"new"}>New</option>
                      <option value={"work"}>Work</option>
                      <option value={"won"}>Won</option>
                      <option value={"lost"}>Lost</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="read">Read/Unread</label>
                    <select
                      className="form-control emailinput"
                      id="read"
                      name="isRead"
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setReadUnread({ isRead: e.target.value });
                        } else {
                          setReadUnread("");
                        }
                      }}
                      // onChange={(e) => handleSearch(e)}
                    >
                      <option value={""} hidden>
                        All Read/Unread
                      </option>
                      <option value={"1"}>Read</option>
                      <option value={"0"}>Unread</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control emailinput"
                      id="status"
                      name={`status`}
                      // onChange={(e) => handleSearch(e)}
                      onChange={(e) => {
                        if (e.target.value == "below48") {
                          setStatusSearch({
                            below48Hours: 1,
                          });
                        } else if (e.target.value == "below72") {
                          setStatusSearch({
                            below72Hours: 1,
                          });
                        } else {
                          setStatusSearch("");
                        }
                      }}
                    >
                      <option value={""} hidden>
                        All Status
                      </option>
                      <option value={"below48"}>Below 48 hours</option>
                      <option value={"below72"}>Below 72 hours</option>
                    </select>
                  </div>
                </div>
              </div> */}
              {/* <!--End Row--> */}
              {/* <div className="row mb-5 ">
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw"
                    onClick={() => fetchInquiries(1)}
                  >
                    Search
                  </button>{" "}
                  &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btncolor btn-rounded btn-fw"
                      onClick={() => clearAll()}
                    >
                      Clear Search
                    </button>
                </div>
              </div> */}
              {/* <!--End Row--> */}
              {/* <div className="row mb-3">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {authUser.userType == ROLES.ADMIN ? (
                    <Link
                      to="/admin/add-inquiry"
                      className="btn btncolor btn-rounded btn-fw pull-right"
                    >
                      Add Inquiry &nbsp;{" "}
                      <i className="fa fa-plus" style={{ fontSize: 14 }}></i>{" "}
                    </Link>
                  ) : authUser.userType == ROLES.MANAGER ? (
                    <Link
                      to="/manager/add-inquiry"
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add Inquiry &nbsp;{" "}
                      <i className="fa fa-plus" style={{ fontSize: 14 }}></i>{" "}
                    </Link>
                  ) : authUser.userType == ROLES.STAFF ? (
                    <Link
                      to="/staff/add-inquiry"
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add Inquiry &nbsp;{" "}
                      <i className="fa fa-plus" style={{ fontSize: 14 }}></i>{" "}
                    </Link>
                  ) : (
                    ""
                  )}

                  {inquiryList?.length ? (
                    <button
                      type="button"
                      className="btn btncolor btn-rounded btn-fw mr-3 pull-right"
                      onClick={() => downloadCSV()}
                    >
                      Export
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">Data table</h4> */}
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <DataTable
                              columns={columns}
                              data={rows}
                              progressPending={isLoading}
                              progressComponent={
                                <div style={{ position: "relative" }}>
                                  <ClipLoader size={50} color="#264B75" />
                                </div>
                              }
                              customStyles={customStyles}
                              pointerOnHover
                              subHeader
                              subHeaderComponent={subHeaderComponentMemo}
                              pagination
                              paginationServer
                              paginationTotalRows={totalRows}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Ed Row--> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
