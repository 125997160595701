import { composeWithDevTools } from "@redux-devtools/extension";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { InquiryReducer } from "./redux/reducers/inquiry";
import { authReducer, signUpReducer } from "./redux/reducers/auth";
import { userReducer } from "./redux/reducers/users";
import { sourceReducer } from "./redux/reducers/sources";
import { todoReducer } from "./redux/reducers/todo";
import { ReminderReducer } from "./redux/reducers/reminder";
import { RequirmentReducer } from "./redux/reducers/requirments";
import { NotificationReducer } from "./redux/reducers/notifications";
import { ProductReducer } from "./redux/reducers/products";

const reducer = combineReducers({
  authUser: authReducer,
  signupUser: signUpReducer,
  users: userReducer,
  inquiry: InquiryReducer,
  sources: sourceReducer,
  todo: todoReducer,
  reminder: ReminderReducer,
  requirment: RequirmentReducer,
  notifications: NotificationReducer,
  products: ProductReducer
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
