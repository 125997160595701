import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input, Label, FormFeedback, Button, Form } from "reactstrap";
import IsAuth from "../../../components/auth/IsAuth";
import { useFormik } from "formik";

import * as Yup from "yup";
import { adminEditUser } from "../../../redux/actions/users";
import Footer from "../../../layout/Footer";
import Loader from "../../../common/Loader";
import { API_URLS } from "../../../common/constant";
import axios from "axios";

export default function EditUser() {
  document.title = "Edit User | Glasier Wellness";
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Token] = useState(localStorage.getItem("accessToken"));
  const { users } = useSelector((state) => state.users);

  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [full_name, setFull_name] = useState("");
  const [position_id, setPosition_id] = useState("");
  const [status, setStatus] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios.post(API_URLS.USERS, {}, config).then((result) => {
        if (result?.status === 200) {
          if (result?.data?.status) {
            setUserList(result?.data?.data);
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: userId,
      full_name: full_name,
      email: email,
      position_id: position_id,
      status: status,
      // password: "",
      // confirmPassword: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Your Email"),
      position_id: Yup.number().required("Please Select Type"),
      password: Yup.string(),
      confirmPassword: Yup.string().when("password", (password, field) =>
        password
          ? field.oneOf([Yup.ref("password")], "Password does not match")
          : field
      ),
    }),

    onSubmit: async (values) => {
      await dispatch(adminEditUser(values)).then(async (res) => {
        if (res.payload.status) {
          navigate(-1);
        }
      });
    },
  });



  useEffect(() => {
    getUserDetails();
  }, [dispatch]);

  useEffect(() => {
    userList
      ?.filter((user) => user._id == id)
      .map((user) => {
        setUserId(user._id);
        setEmail(user.email);
        setFull_name(user.full_name);
        setPosition_id(user.position_id);
        setStatus(user.status);
      });
  }, [userList]);
  return (
    <React.Fragment>
      <IsAuth />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="card">
              <div className="card-body">
                <Form
                autoComplete="new-password"
                  className="forms-sample pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <h4 className="main-heading mb-0">Update User</h4>
                    </div>
                    <div className="col-md-6 text-right">
                      <Link
                        to="/admin/users"
                        className="btn btncolor btn-rounded btn-fw"
                      >
                        {" "}
                        <i
                          className="fa fa-chevron-left"
                          style={{ fontSize: 14 }}
                        ></i>{" "}
                        &nbsp;Back
                      </Link>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label for="exampleInputUsername1">
                          Full Name <span style={{ color: "red" }}> * </span>
                        </Label>
                        <Input
                          id="full_name"
                          name="full_name"
                          className="form-control emailinput"
                          placeholder="Enter Full Name*"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.full_name || full_name}
                          invalid={
                            validation.touched.full_name &&
                            validation.errors.full_name
                              ? true
                              : false
                          }
                          autocomplete="off"
                        />
                        {validation.touched.full_name &&
                        validation.errors.full_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.full_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label for="exampleInputUsername1">
                          Email Address{" "}
                          <span style={{ color: "red" }}> * </span>{" "}
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control emailinput"
                          placeholder="Enter Email*"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || email}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label for="exampleFormControlSelect2">
                          User Type <span style={{ color: "red" }}> * </span>{" "}
                        </Label>

                        <Input
                          id="position_id"
                          name="position_id"
                          type="select"
                          className="form-control emailinput"
                          // defaultChecked={position_id}
                          invalid={
                            validation.touched.position_id &&
                            validation.errors.position_id
                              ? true
                              : false
                          }
                          value={validation.values.position_id || position_id}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        >
                          <option value>Select User Type</option>
                          <option value={1}>Admin</option>
                          <option value={2}>Manager</option>
                          <option value={3}>Staff</option>
                          <option value={4}>Production User</option>
                        </Input>
                        {validation.touched.position_id &&
                        validation.errors.position_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.position_id}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label for="exampleFormControlSelect2">
                          Status <span style={{ color: "red" }}> * </span>{" "}
                        </Label>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          className="form-control emailinput"
                          // defaultChecked={status}
                          invalid={
                            validation.touched.status &&
                            validation.errors.status
                              ? true
                              : false
                          }
                          value={validation.values.status || status}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        >
                          <option value={""}>Select Status</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </Input>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label for="exampleInputUsername1">Password</label>
                        <div className="form-group">
                          <Input
                            id="password"
                            name="password"
                            className="form-control emailinput"
                            placeholder="Enter Password*"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label for="exampleInputUsername1">
                          Confirm Password
                        </Label>
                        <div className="form-group">
                          <Input
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control emailinput"
                            placeholder="Enter Confirm Password*"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-5 mt-3">
                    <div className="col-md-12 text-center">
                      <Button
                        type="submit"
                        className="btn btncolor btn-rounded btn-fw"
                        disabled={validation.isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
