import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import profileNamePNG from "../../assets/img/1profile-name.png";
import profileEmailPNG from "../../assets/img/2profile-email.png";
import profileStatusPNG from "../../assets/img/3profile-status.png";
import profileRemarkPNG from "../../assets/img/4profile-remark.png";
import profileIMGPNG from "../../assets/img/profile-img.png";
import Avatar from "../../common/Avatar";
import { ROLES } from "../../common/constant";
import Footer from "../../layout/Footer";
import { getUserProfile } from "../../redux/actions/users";
import IsAuth from "../auth/IsAuth";

const User = () => {
  document.title = "Profile | Glasier Wellness";
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  const getUser = async () => {
    await dispatch(getUserProfile());
  };

  useEffect(() => {
    getUser();
  }, [dispatch]);

  return (
    <>
    <IsAuth />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 grid-margin profile-box">
                  <div className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-md-12">
                          <img src={profileIMGPNG} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 pl-4">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="avatar-md profile-user-wid mb-4" style={{right: 'unset'}}>
                            <Avatar className="large" name={user?.full_name ? user.full_name : "Guest"} />
                          </div>
                          <div className="profile-name">
                            <h5>{user ? user.full_name : ""}</h5>
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="pt-4">
                            <div className="mt-15">
                              {authUser && authUser.userType == ROLES.ADMIN ? (
                                <Link
                                  to="/admin/edit-profile"
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  Edit Profile
                                </Link>
                              ) : authUser &&
                                authUser.userType == ROLES.MANAGER ? (
                                <Link
                                  to="/manager/edit-profile"
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  Edit Profile
                                </Link>
                              ) : authUser &&
                                authUser.userType == ROLES.STAFF ? (
                                <Link
                                  to="/staff/edit-profile"
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  Edit Profile
                                </Link>
                              ) : (
                                <Link
                                  to="/edit-profile"
                                  className="btn btncolor btn-rounded btn-fw pull-right"
                                >
                                  Edit Profile
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body">
              <div className="borred">
                <div
                  className="d-flex justify-content-between"
                  style={{ borderBottom: "1px solid #E0E0E0" }}
                >
                  <h4 className="card-title">About</h4>
                </div>
                <p className="textsizze">{user ? user.about : ""}</p>
                <div className="list d-flex align-items-center border-bottom py-3">
                  <img
                    className="img-sm rounded-circle"
                    src={profileNamePNG}
                    alt=""
                  />
                  <div className="wrapper w-100 ml-3">
                    <p className="mb-0">
                      <b>Name </b>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <p className="mb-0">{user ? user.full_name : ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list d-flex align-items-center border-bottom py-3">
                  <img
                    className="img-sm rounded-circle"
                    src={profileEmailPNG}
                    alt=""
                  />
                  <div className="wrapper w-100 ml-3">
                    <p className="mb-0">
                      <b> Email ID </b>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <p className="mb-0">{user ? user.email : ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list d-flex align-items-center border-bottom py-3">
                  <img
                    className="img-sm rounded-circle"
                    src={profileStatusPNG}
                    alt=""
                  />
                  <div className="wrapper w-100 ml-3">
                    <p className="mb-0">
                      <b> Status </b>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <p className="mb-0">
                          {" "}
                          {user
                            ? user.status === 1
                              ? "Online"
                              : "Offline"
                            : "Not Available"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list d-flex align-items-center pt-3">
                  <img
                    className="img-sm rounded-circle"
                    src={profileRemarkPNG}
                    alt=""
                  />
                  <div className="wrapper w-100 ml-3">
                    <p className="mb-0">
                      <b> Remark </b>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <p className="mb-0"> {user ? user.remark : ""} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default User;