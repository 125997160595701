export const FETCH_SOURCE_REQUEST = "FETCH_SOURCE_REQUEST";
export const FETCH_SOURCE_SUCCESS = "FETCH_SOURCE_SUCCESS";
export const FETCH_SOURCE_FAIL = "FETCH_SOURCE_FAIL";

export const ADD_SOURCE_REQUEST = "ADD_SOURCE_REQUEST";
export const ADD_SOURCE_SUCCESS = "ADD_SOURCE_SUCCESS";
export const ADD_SOURCE_FAIL = "ADD_SOURCE_FAIL";

export const UPDATE_SOURCE_REQUEST = "UPDATE_SOURCE_REQUEST";
export const UPDATE_SOURCE_SUCCESS = "UPDATE_SOURCE_SUCCESS";
export const UPDATE_SOURCE_FAIL = "UPDATE_SOURCE_FAIL";

export const STATUS_SOURCE_REQUEST = "STATUS_SOURCE_REQUEST";
export const STATUS_SOURCE_SUCCESS = "STATUS_SOURCE_SUCCESS";
export const STATUS_SOURCE_FAIL = "STATUS_SOURCE_FAIL";

export const DELETE_SOURCE_REQUEST = "DELETE_SOURCE_REQUEST";
export const DELETE_SOURCE_SUCCESS = "DELETE_SOURCE_SUCCESS";
export const DELETE_SOURCE_FAIL = "DELETE_SOURCE_FAIL";

