import {
  ADMIN_EDIT_PROFILE_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_SUCCESS,
  TOP_USER_REQUEST,
  TOP_USER_SUCCESS,
  TOP_USER_FAIL
} from "../../constants/userConstants";

let initialState = {
  user: [],
  users: [],
  top_users: [],
  loading: false,
  error: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        users: [],
        error: null,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.payload,
      };
      
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
      case TOP_USER_REQUEST:
      return {
        ...state,
        loading: true,
        top_users: [],
        error: null,
      };
    case TOP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        top_users: action.payload,
      };
      
    case TOP_USER_FAIL:
      return {
        ...state,
        loading: false,
        top_users: [],
        error: action.payload,
      };
      case GET_USER_PROFILE_REQUEST:
        return {
          ...state,
          loading: true,
        };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload,
      };

    case ADMIN_EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload,
      };

    default:
      return state;
  }
};
