import {
  CLEAR_NOTIFICATIONS_FAIL,
  CLEAR_NOTIFICATIONS_REQUEST,
  CLEAR_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from "../../constants/notificationConstants";

let initialState = {
  notifications: [],
  loading: false,
  error: null,
};
export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
        error: null,
      };
    case FETCH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        notifications: [],
        error: action.payload,
      };
    case CLEAR_NOTIFICATIONS_REQUEST:
    case CLEAR_NOTIFICATIONS_SUCCESS:
    case CLEAR_NOTIFICATIONS_FAIL:
    default:
      return state;
  }
};
