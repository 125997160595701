import React from "react"
import { Navigate } from "react-router-dom"

function IsAuth() {
  if (!localStorage.getItem("authUser")) {
    return <Navigate to="/login"/>
  }
  return <div></div>
}
export default IsAuth
