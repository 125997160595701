import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
    FETCH_REQUIRMENT_REQUEST,
    FETCH_REQUIRMENT_SUCCESS,
    FETCH_REQUIRMENT_FAIL,
    ADD_REQUIRMENT_REQUEST,
    ADD_REQUIRMENT_FAIL,
    ADD_REQUIRMENT_SUCCESS,
    UPDATE_REQUIRMENT_REQUEST,
    UPDATE_REQUIRMENT_SUCCESS,
    UPDATE_REQUIRMENT_FAIL,
    DELETE_REQUIRMENT_REQUEST,
    DELETE_REQUIRMENT_SUCCESS,
    DELETE_REQUIRMENT_FAIL,
} from "../../constants/requirmentConstants";


//Fetch All REQUIRMENT
export const fetchAllRequirments = () => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: FETCH_REQUIRMENT_REQUEST});
            const response = await axios.get(API_URLS.REQUIRMENT_LIST, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: FETCH_REQUIRMENT_SUCCESS, payload:response?.data?.data});
                    return response?.data;
                }else{
                    dispatch({
                        type: FETCH_REQUIRMENT_FAIL,
                        payload: response?.data?.message
                    });
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: FETCH_REQUIRMENT_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Adding A REQUIRMENT
export const addRequirments = (requirmentData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: ADD_REQUIRMENT_REQUEST});
            const response = await axios.post(API_URLS.ADD_REQUIRMENT, requirmentData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: ADD_REQUIRMENT_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllRequirments())
                    return response?.data;
                }else{
                    dispatch({
                        type: ADD_REQUIRMENT_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllRequirments())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: ADD_REQUIRMENT_FAIL,
                payload: error
            });
            dispatch(fetchAllRequirments())
            return error;
        }
    }
}

//Update A REQUIRMENT
export const updateRequirment = (requirmentData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: UPDATE_REQUIRMENT_REQUEST});
            const response = await axios.post(API_URLS.UPDATE_REQUIRMENT, requirmentData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: UPDATE_REQUIRMENT_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllRequirments())
                    return response?.data;
                }else{
                    dispatch({
                        type: UPDATE_REQUIRMENT_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch( fetchAllRequirments() )
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: UPDATE_REQUIRMENT_FAIL,
                payload: error
            });
            dispatch(fetchAllRequirments())
            return error;
        }
    }
}


//Delete A REQUIRMENT
export const deleteRequirment = (requirmentData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        try {
            dispatch({type: DELETE_REQUIRMENT_REQUEST});
            const response = await axios.delete(API_URLS.DELETE_REQUIRMENT, { data: requirmentData, headers: { Authorization: `Bearer ${Token}` }})
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: DELETE_REQUIRMENT_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllRequirments())
                    return response?.data;
                }else{
                    dispatch({
                        type: DELETE_REQUIRMENT_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch(fetchAllRequirments())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: DELETE_REQUIRMENT_FAIL,
                payload: error
            });
            // dispatch(fetchAllRequirments())
            return error;
        }
    }
}