import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { Col, Row } from "reactstrap";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import FilterComponent from "../../../layout/FilterComponent";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URLS } from "../../../common/constant";
import { ClipLoader } from "react-spinners";
export default function NewData({
  columns,
  customStyles,
  authUser,
}) {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [perPage, setPerPage] = useState(10);
  useEffect(() => {
    fetchInquiries(1); // fetch page 1 of users
  }, [dispatch]);

  const fetchInquiries = async (page) => {
    setIsLoading(true);
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        {
          page: page,
          limit: perPage,
          stepStatus:"new"
        },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchInquiries(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      const response = await axios.post(
        API_URLS.INQUIRY_LIST,
        {
          page: page,
          limit: newPerPage,
          stepStatus:"new"
        },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          setInquiryList(response?.data?.data?.allInquiries);
          setTotalRows(response?.data?.data?.count);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
    setPerPage(newPerPage);
  };

  const rows = [
    ...inquiryList
      ?.filter(
        (item) =>
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.partyName &&
            item.partyName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((row, order) => ({
        sno: order + 1,
        date: (
          <>
            <span>
              <Moment format="D MMM YYYY" withTitle>
                {row.date}
              </Moment>
            </span>
            <br></br>
            <br></br>
            <span>{row.companyName}</span>
            <br></br>
            <br></br>
            {row?.isRead == 0 ? (
              <span className={`w-h-15 small-box-red`}></span>
            ) : null}
            {moment().diff(row?.date, "h") <= 48 ? (
              <span className={`w-h-15 small-box-green`}></span>
            ) : (
              ""
            )}
            {moment().diff(row?.date, "h") >= 48 &&
            moment().diff(row?.date, "h") <= 72 ? (
              <span className={`w-h-15 small-box-orange`}></span>
            ) : moment().diff(row?.date, "h") > 72 && row?.stepStatus=='new' ?(
              <span className={`w-h-15 small-box-red`}></span>
            ):""}
          </>
        ),
        partyDetails: (
          <>
            <div className="thirdcol">
              <ul className="thirdul">
                <li>
                  {" "}
                  <strong> Party Name : </strong>{" "}
                  {row.partyName ? row.partyName : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Email ID :</strong> {row.email ? row.email : "NA"}{" "}
                </li>
                <li>
                  {" "}
                  <strong>Contact No. : </strong>
                  {row.contactNumber ? row.contactNumber : "NA"}
                </li>
                <li>
                  {" "}
                  <strong>Location : </strong>
                  {row.address && row.address} {row.city && row.city}{" "}
                  {row.state && row.state}
                  {row.country && ", " + row.country}{" "}
                  {row.pinCode && " - " + row.pinCode}
                </li>
              </ul>
            </div>
          </>
        ),
        requirements: (
          <>
            {row.requirements?.map((item, i) => (
              <div key={i}>
                <p className="textcolor mb-2">
                  {" "}
                  {item.requirement && item.requirement}{" "}
                </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Units :- </strong>
                    {item.units && item.units}
                  </li>
                </ul>
              </div>
            ))}
            {row.remark && (
              <div>
                <p className="textcolor"> Remarks </p>
                <ul className="thirdul1">
                  <li>
                    <strong>Type : </strong> {row.remark}
                  </li>
                </ul>
              </div>
            )}
          </>
        ),
        action: (
          <>
            <Link
              to={`/${authUser?.userType.toLowerCase()}/view-inquiry/${
                row._id
              }`}
              className="iconbgview"
              // onClick={() => {
              //   setReportViewData(row);
              //   setActive(true);
              // }}
            >
              <i className="fa fa-eye eyeicon"></i>
            </Link>
            <Link
              to={`/${authUser?.userType.toLowerCase()}/edit-inquiry/${
                row._id
              }`}
              className="iconbgedit"
              title="Edit"
            >
              <i className="fa fa-edit eyeicon"></i>
            </Link>
          </>
        ),
      })),
  ];
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const exportCSV = [
    ...inquiryList
      ?.filter(
        (item) =>
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.partyName &&
            item.partyName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((row, order) => ({
        Inquiry_Id: row._id,
        Party_Name: row.partyName ? row.partyName : "NA",
        Email: row.email ? row.email : "NA",
        Contact_Number: row.contactNumber ? row.contactNumber : "NA",
        Requirement: row.requirements
          ?.map(({ requirement }, i) => requirement)
          .join(" | "),
        Units: row.requirements?.map(({ units }, i) => units).join(" | "),
        Date: moment(row.date).format("MM/DD/YYYY"),
        Source_of_Inquiry: row.source ? row.source : "NA",
        Location: row.address ? row.address : "NA",
        City: row.city ? row.city : "NA",
        State: row.state ? row.state : "NA",
        Country: row.country ? row.country : "NA",
        Remark: row.remark ? row.remark : "NA",
        Status: row.stepStatus,
      })),
  ];

  const convertArrayOfObjectsToCSV = (array) => {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(exportCSV[0]);
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        // eslint-disable-next-line no-plusplus
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const downloadCSV = () => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(exportCSV);
    if (csv == null) return;
    const filename = `inquiry_${moment().valueOf()}.csv`;
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <React.Fragment>
      {inquiryList?.length ? (
        <Row>
          <Col className="md-12">
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw pull-right mobilrbott"
              style={{ position: "absolute", zIndex: 9 }}
              onClick={() => downloadCSV()}
            >
              Export CSV
            </button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col className="md-12">
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={rows}
              progressPending={isLoading}
              progressComponent={
                <div style={{ position: "relative" }}>
                  <ClipLoader size={50} color="#264B75" />
                </div>
              }
              customStyles={customStyles}
              pointerOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
