import React, { useEffect, useState } from "react";
import ok from "../../assets/img/ok.png";
import loadergif from "../../assets/img/giphy.webp";
import placeholder from "../../assets/img/placeholder.png";
import Footer from "../Footer";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { API_URLS } from "../../common/constant";
import axios from "axios";
import LazyImage from "../../components/CommonPages/Gallery/LazyImage";

export default function MultipleImagesShare() {
  document.title = "Products Gallery | Glasier Wellness";

  const [searchParams] = useSearchParams();
  // const productId = searchParams.get("productId");
  const token = searchParams.get("token");
  const imageBaseUrl = process.env.REACT_APP_S3_IMAGE_BASE_URL

  const dispatch = useDispatch();
  const [urls, setUrls] = useState([]);
  const [prodImages, setProdImages] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [productName, setProductName] = useState("");
  const IMAGE_URL = process.env.REACT_APP_IMAGE_PATH;

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  useEffect(() => {
    // fetchProductImages();
    fetchSharedImages();
  }, [dispatch]);


  const fetchSharedImages = async () => {
    setLoader(true);
    try {
      const fileNames = [];
      urls.forEach(async function (url) {
        const urlArr = url.split("/");
        const filename = urlArr[urlArr.length - 1];
        fileNames.push(filename);
        setImageNames(fileNames);
      });
      let config = {};
      await axios.post(
        API_URLS.GET_SHARE_IMAGES,
        { token:token },
        config
      ).then((result) => {
        if (result?.status) {
          if (result?.data?.status) {
            setProductName(result?.data?.data[0]?.productName);
            setProdImages(result?.data?.data[0]?.images)
            setLoader(false);
          }
        }
      });
    } catch (error) {
      setLoader(false);
    }
  }
  
  return (
    <React.Fragment>
      <div className="main-panel" style={{ width: "100%" }}>
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4 justify-content-center">
                <div className="col-md-9 mt-2">
                  <h4 className="main-heading">{capitalize(productName)}</h4>
                </div>
                <div className="col-md-3">
                </div>
              </div>
              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : prodImages?.length > 0 ? (
                <Gallery 
                  withDownloadButton
                  options={{
                    padding: { top: 20, bottom: 40, left: 100, right: 100 },
                  }}                
                >
                <div className="row">
                  {prodImages?.map((image, i) => (
                    <div key={i} className="col-md-2">
                      <label className="pro_img">
                        
                        <Item
                          original={`${imageBaseUrl}/${image}`}
                          thumbnail={`${imageBaseUrl}/${image}`}
                          width="800"
                          height="1024"
                        >
                          {({ ref, open }) => (
                            <>
                            <LazyImage
                              placeholderSrc={loadergif}
                              key={i}
                              reference={ref}
                              onDoubleClick={open}
                              src={`${imageBaseUrl}/${image}`}
                              placeholderStyle={{width: '100%'}}
                              errorImg={placeholder}
                             />
                            </>
                          )}
                        </Item>
                        <span style={{ WebkitMaskImage: `url(${ok})`, WebkitMaskBoxImage: `url(${ok})` }}></span>
                      </label>
                    </div>
                  ))}
                </div>
                </Gallery>
              ) : (
                <div className="position-relative text-center">
                  No Image Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
