import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
  CREATE_INQUIRY_FAIL,
  CREATE_INQUIRY_REQUEST,
  CREATE_INQUIRY_SUCCESS,
  FETCH_INQUIRY_FAIL,
  FETCH_INQUIRY_REQUEST,
  FETCH_INQUIRY_SUCCESS,
  UPDATE_INQUIRY_FAIL,
  UPDATE_INQUIRY_REQUEST,
  UPDATE_INQUIRY_SUCCESS,
  UPDATE_STEPSTATUS_FAIL,
  UPDATE_STEPSTATUS_REQUEST,
  UPDATE_STEPSTATUS_SUCCESS,
  FETCH_SINGLE_INQUIRY_REQUEST,
  FETCH_SINGLE_INQUIRY_SUCCESS,
  FETCH_SINGLE_INQUIRY_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  ADD_USER_COMMENT_REQUEST,
  ADD_USER_COMMENT_SUCCESS,
  ADD_USER_COMMENT_FAIL,
  SEARCH_PARTY_NAME_REQUEST,
SEARCH_PARTY_NAME_SUCCESS,
SEARCH_PARTY_NAME_FAIL,
} from "../../constants/inquiryConstants";

//Fetch  All Inquiry
export const fetchAllInquiry = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_INQUIRY_REQUEST });
      const response = await axios.post(API_URLS.INQUIRY_LIST, data, config);
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_INQUIRY_SUCCESS,
            payload: response?.data?.data?.allInquiries,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_INQUIRY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_INQUIRY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// SINGLE INQUIRY
export const SingleInquiry = (id) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: FETCH_SINGLE_INQUIRY_REQUEST });
      const response = await axios.post(
        API_URLS.INQUIRY_DETAILS,
        { id: id },
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: FETCH_SINGLE_INQUIRY_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: FETCH_SINGLE_INQUIRY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_INQUIRY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Create Inquiry
export const createInquiry = (inquiryData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: CREATE_INQUIRY_REQUEST });
      const response = await axios.post(
        API_URLS.ADD_INQUIRY,
        inquiryData,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: CREATE_INQUIRY_SUCCESS,
            payload: response?.data?.data,
          });
          await dispatch(fetchAllInquiry({page:1, limit:10}));
          return response?.data;
        } else {
          dispatch({
            type: CREATE_INQUIRY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_INQUIRY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Update Inquiry
export const updateInquiry = (inquiryData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: UPDATE_INQUIRY_REQUEST });
      const inquiry = await axios.post(
        API_URLS.UPDATE_INQUIRY,
        inquiryData,
        config
      );
      await dispatch(fetchAllInquiry({page:1, limit:10}));
      dispatch({ type: UPDATE_INQUIRY_SUCCESS, payload: inquiry });
      return inquiry.data;
    } catch (error) {
      dispatch({
        type: UPDATE_INQUIRY_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
};

//Delete Comment
export const deleteComment = (inquiryData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };

    try {
      dispatch({ type: DELETE_COMMENT_REQUEST });
      const response = await axios.post(
        API_URLS.UPDATE_INQUIRY_REMARK,
        inquiryData,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: DELETE_COMMENT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: DELETE_COMMENT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: DELETE_COMMENT_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

//Update Step Status
export const updateStepStatus = (inquiryData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: UPDATE_STEPSTATUS_REQUEST });
      const inquiry = await axios.post(
        API_URLS.INQUIRY_CHANGE_STATUS,
        inquiryData,
        config
      );
      dispatch({ type: UPDATE_STEPSTATUS_SUCCESS, payload: inquiry });
      return inquiry.data;
    } catch (error) {
      dispatch({
        type: UPDATE_STEPSTATUS_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
};

//Toggle Inquiry Status
export const toggleInquiryStatus = (inquiryData) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: UPDATE_INQUIRY_REQUEST });
      const response = await axios.post(
        API_URLS.ADMIN_INQUIRY_TOGGLE,
        inquiryData,
        config
      );

      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: UPDATE_INQUIRY_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: UPDATE_INQUIRY_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_INQUIRY_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

export const addUserRate = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: ADD_USER_COMMENT_REQUEST });
      const response = await axios.post(
        API_URLS.DISCOUNT_ON_REQIREMENT,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: ADD_USER_COMMENT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: DELETE_COMMENT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } 
    catch (error) {
      dispatch({
        type: ADD_USER_COMMENT_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
};

export const addProdUserRate = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: ADD_USER_COMMENT_REQUEST });
      const response = await axios.post(
        API_URLS.PRODUCTION_USER_COMMENT,
        data,
        config
      );
      if (response.status) {
        if (response?.data?.status) {
          dispatch({
            type: ADD_USER_COMMENT_SUCCESS,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: DELETE_COMMENT_FAIL,
            payload: response?.data?.message,
          });
          return response?.data;
        }
      }
    } 
    catch (error) {
      dispatch({
        type: ADD_USER_COMMENT_FAIL,
        payload: error.response?.data?.message,
      });
    }
  };
};


//Get Particular User's Inquiry
export const getPartyNames = (data) => {
  return async (dispatch) => {
    const Token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    try {
      dispatch({ type: SEARCH_PARTY_NAME_REQUEST });
      // const response = await axios.post(`http://192.168.0.21:4040/web/party-names`, data, config);
      const response = await axios.post(API_URLS.PARTY_NAME_SEARCH, data, config);
      dispatch({
        type: SEARCH_PARTY_NAME_SUCCESS,
        payload: response?.data?.data,
      });
      return response?.data;
    } 
    catch (error) {
      dispatch({
        type: SEARCH_PARTY_NAME_FAIL,
        payload: error,
      });
      return error;
    }
  };
};