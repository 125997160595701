import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeStatusTodo } from "../../redux/actions/todo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Checkbox({ completionStatus, id }) {
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = useState(completionStatus);
  const handleStatus = () => {
    changeStatusTODO(!checkbox, id);
    setCheckbox(!checkbox);
  };
  useEffect(() => {}, [checkbox]);

  const changeStatusTODO = async (status, id) => {
    // setIsLoding(true);
    const res = await dispatch(
      changeStatusTodo({ id: id, status: status ? 1 : 0 })
    );
    if (res.status) {
      toast.success(res.message, {
        theme: "colored",
      });
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
      setCheckbox(completionStatus);
    }
  };

  return (
    <label className="form-check-label form-label">
      <input
        type="checkbox"
        className="form-check-input form-check-input"
        checked={checkbox}
        onChange={() => {
          handleStatus();
        }}
      />
      <i className="input-helper"></i>
    </label>
  );
}
