import React, { useEffect, useState } from "react";
import dashboardLogo from "../assets/img/dashboard-logo.png";
import logoIcon from "../assets/img/logo-icon.png";
import { Link } from "react-router-dom";
import Avatar from "../common/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/actions/users";
import { getNotifications } from "../redux/actions/notifications";
import moment from "moment-timezone";
import ClipLoader from "react-spinners/ClipLoader";

const Header = () => {
  const dispatch = useDispatch();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { user, loading } = useSelector((state) => state.users);
  const { notifications } = useSelector((state) => state.notifications);
  //
  const [fullName, setFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUser();
  }, [dispatch]);

  useEffect(() => {
    getAllNotifications();
  }, [isLoading]);

  useEffect(() => {
    setFullName(user?.full_name);
    setUserRole(user?.userType)
    setIsLoading(loading);
  }, [user]);

  const toggleSideBar = () => {
    document.body.classList.toggle("sidebar-icon-only");
  };
  const getUser = async () => {
    setIsLoading(true);
    await dispatch(getUserProfile()).then(async (res) => {
      if (res.status) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    });
  };
  const getAllNotifications = async () => {
    setIsLoading(true);
    await dispatch(getNotifications()).then(async (res) => {
      if (res.status) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row default-layout-navbar">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="#">
            <img src={dashboardLogo} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="#">
            <img src={logoIcon} alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={toggleSideBar}
          >
            <span className="fas fa-bars" style={{ color: "#fff" }}></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="fas fa-bell mx-0  f-size-20"></i>
                <span className="count">
                  {notifications?.filter((item, idx) => idx < 5).length}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <Link 
                to={"/notification"}
                 className="dropdown-item sticky justify-content-between">
                  <p className="mb-0 font-weight-normal float-left">
                    You have{" "}
                    {notifications?.filter((item, idx) => idx < 5).length} new
                    notifications
                  </p>
                  <span
                    className="badge badge-pill badge-warning float-right"
                    target="_blank"
                  >
                    View all
                  </span>
                </Link>

                {isLoading ? (
                  <ClipLoader color="#47a0dc" loading />
                ) : (
                  notifications
                    ?.filter((item, idx) => idx < 5)
                    .map((item, i) => (
                      <div key={i}>
                        <div className="dropdown-divider"></div>
                        <Link
                          to={item?.type=="Inquiry"?`/${authUser?.userType.toLowerCase()}/view-inquiry/${item?.data?.inquiryId}`:'/notification'}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-item-content">
                            <h6 className="preview-subject font-weight-medium">
                              {item?.body}
                            </h6>
                            <p className="font-weight-light small-text mb-0">
                              {moment(item?.modifiedAt).fromNow()}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))
                )}
              </div>
            </li>
            <li className="nav-item nav-profile dropdown">
              <Link
                className="nav-link dropdown-toggle profile-icon"
                to="#"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <Avatar className={'small'} name={user?.full_name?authUser?.full_name:'Guest'} />
                {fullName && fullName ? (
                    <span>{fullName}</span>
                  ) : (
                    authUser?.full_name
                  )}
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <Link className="dropdown-item" to={"/logout"}>
                  <i className="fas fa-power-off text-primary"></i>
                  Logout
                </Link>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="fas fa-bars"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Header;
