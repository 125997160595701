import axios from "axios";
import { API_URLS } from "../../../common/constant";
import {
    FETCH_REMINDER_REQUEST,
    FETCH_REMINDER_SUCCESS,
    FETCH_REMINDER_FAIL,
    ADD_REMINDER_REQUEST,
    ADD_REMINDER_SUCCESS,
    ADD_REMINDER_FAIL,
    UPDATE_REMINDER_REQUEST,
    UPDATE_REMINDER_SUCCESS,
    UPDATE_REMINDER_FAIL,
    CHANGE_REMINDER_STATUS_REQUEST,
    CHANGE_REMINDER_STATUS_SUCCESS,
    CHANGE_REMINDER_STATUS_FAIL,
    DELETE_REMINDER_REQUEST,
    DELETE_REMINDER_SUCCESS,
    DELETE_REMINDER_FAIL,
} from "../../constants/reminderConstants";

//Fetch All REMINDER (Admin)\
export const fetchAllReminders = (data) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: FETCH_REMINDER_REQUEST});
            const response = await axios.post(API_URLS.REMINDER_LIST, data, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: FETCH_REMINDER_SUCCESS, payload:response?.data?.data?.allReminderData});
                    return response?.data;
                }else{
                    dispatch({
                        type: FETCH_REMINDER_FAIL,
                        payload: response?.data?.message
                    });
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: FETCH_REMINDER_FAIL,
                payload: error
            });
            return error;
        }
    }
}

//Adding A REMINDER
export const addReminder = (reminderData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: ADD_REMINDER_REQUEST});
            const response = await axios.post(API_URLS.ADD_REMINDER, reminderData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: ADD_REMINDER_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllReminders())
                    return response?.data;
                }else{
                    dispatch({
                        type: ADD_REMINDER_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllReminders())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: ADD_REMINDER_FAIL,
                payload: error
            });
            dispatch(fetchAllReminders())
            return error;
        }
    }
}

//Update A REMINDER
export const updateReminder = (reminderData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: UPDATE_REMINDER_REQUEST});
            const response = await axios.post(API_URLS.UPDATE_REMINDER, reminderData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: UPDATE_REMINDER_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllReminders())
                    return response?.data;
                }else{
                    dispatch({
                        type: UPDATE_REMINDER_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch( fetchAllReminders() )
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: UPDATE_REMINDER_FAIL,
                payload: error
            });
            dispatch(fetchAllReminders())
            return error;
        }
    }
}

//Toggle REMINDER Completed Or Not
export const toggleReminder = (reminderData) => {
    return async(dispatch) => {
        const Token = localStorage.getItem("accessToken");
        const config = {
            headers: { Authorization: `Bearer ${Token}` }
        }
        try {
            dispatch({type: CHANGE_REMINDER_STATUS_REQUEST});
            const response = await axios.post(API_URLS.CHANGE_REMINDER_STATUS, reminderData, config)
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: CHANGE_REMINDER_STATUS_SUCCESS, payload:response?.data?.data});
                    // dispatch(fetchAllReminders())
                    return response?.data;
                }else{
                    dispatch({
                        type: CHANGE_REMINDER_STATUS_FAIL,
                        payload: response?.data?.message
                    });
                    // dispatch( fetchAllReminders() )
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: CHANGE_REMINDER_STATUS_FAIL,
                payload: error
            });
            dispatch(fetchAllReminders())
            return error;
        }
    }
}

//Delete A REMINDER
export const deleteReminder = (reminderData) => {
    return async(dispatch) =>{
        const Token = localStorage.getItem("accessToken");
        try {
            dispatch({type: DELETE_REMINDER_REQUEST});
            const response = await axios.delete(API_URLS.DELETE_REMINDER, { data: reminderData, headers: { Authorization: `Bearer ${Token}` }})
            if(response.status){
                if(response?.data?.status){
                    dispatch({type: DELETE_REMINDER_SUCCESS, payload:response?.data?.data});
                    dispatch(fetchAllReminders())
                    return response?.data;
                }else{
                    dispatch({
                        type: DELETE_REMINDER_FAIL,
                        payload: response?.data?.message
                    });
                    dispatch(fetchAllReminders())
                    return response?.data;
                }
            }
        } catch (error) {
            dispatch({
                type: DELETE_REMINDER_FAIL,
                payload: error
            });
            dispatch(fetchAllReminders())
            return error;
        }
    }
}
