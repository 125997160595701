import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input, Label, FormFeedback, Form } from "reactstrap";
import profileIMGPNG from "../../assets/img/profile-img.png";
import { editUserProfile, getUserProfile } from "../../redux/actions/users";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "../../common/Avatar";
import Loader from "../../common/Loader";
import Footer from "../../layout/Footer";
import { ROLES } from "../../common/constant";
import IsAuth from "../auth/IsAuth";
const EditProfile = () => {
  document.title = "Edit Profile | Glasier Wellness";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { user } = useSelector((state) => state.users);
  const [full_name, setFull_name] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [about, setAbout] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getUser = async () => {
    setIsLoading(true);
    if (Object.keys(user).length == 0) {
      const res = await dispatch(getUserProfile());
      if (res.status) {
        setAbout(res?.data.about);
        setEmail(res?.data.email);
        setFull_name(res?.data.full_name);
        setStatus(res?.data?.status);
        setRemark(res?.data.remark);
        setIsLoading(false);
      }
    } else {
      setAbout(user.about);
      setEmail(user.email);
      setFull_name(user.full_name);
      setStatus(user.status);
      setRemark(user.remark);
      setIsLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: full_name,
      email: email,
      status: status,
      about: about,
      remark: remark,
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Your Email"),
      password: Yup.string(),
      confirmPassword: Yup.string().when("password", (password, field) =>
        password
          ? field.oneOf([Yup.ref("password")], "Password does not match")
          : field
      ),
    }),

    onSubmit: async (values) => {
      await dispatch(editUserProfile(values)).then(async (res) => {
        if (res.status) {
          localStorage.setItem("authUser", JSON.stringify(res?.data));
          toast.success(res.message, {
            theme: "colored",
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          toast.error(res.message, {
            theme: "colored",
          });
        }
      });
    },
  });

  useEffect(() => {
    getUser();
  }, [dispatch]);
  return (
    <>
      <IsAuth />
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 grid-margin profile-box">
                    <div className="overflow-hidden">
                      <div className="bg-primary bg-soft">
                        <div className="row">
                          <div className="col-md-12">
                            <img src={profileIMGPNG} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0 pl-4">
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="avatar-md profile-user-wid mb-4" style={{right: 'unset'}}>
                              <Avatar className="large" name={user?.full_name ? user.full_name : "Guest"} />
                            </div>
                            <div className="profile-name">
                              <h5>{user ? user.full_name : ""}</h5>
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <div className="pt-4">
                              <div className="mt-15">
                                {authUser &&
                                authUser.userType == ROLES.ADMIN ? (
                                  <Link
                                    to="/admin/profile"
                                    className="btn btncolor btn-rounded btn-fw pull-right"
                                  >
                                    View Profile
                                  </Link>
                                ) : authUser &&
                                  authUser.userType == ROLES.MANAGER ? (
                                  <Link
                                    to="/manager/profile"
                                    className="btn btncolor btn-rounded btn-fw pull-right"
                                  >
                                    View Profile
                                  </Link>
                                ) : authUser &&
                                  authUser.userType == ROLES.STAFF ? (
                                  <Link
                                    to="/staff/profile"
                                    className="btn btncolor btn-rounded btn-fw pull-right"
                                  >
                                    View Profile
                                  </Link>
                                ) : (
                                  <Link
                                    to="/profile"
                                    className="btn btncolor btn-rounded btn-fw pull-right"
                                  >
                                    View Profile
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <Form
                  className="forms-sample pt-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="borred">
                    <div
                      className="d-flex justify-content-between"
                      style={{ borderBottom: "1px solid #E0E0E0" }}
                    >
                      <h4 className="card-title">About</h4>
                    </div>

                    {/* About */}
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="form-group">
                          <Label for="about">
                            About <span style={{ color: "red" }}> * </span>
                          </Label>
                          <Input
                            id="about"
                            name="about"
                            className="form-control emailinput"
                            placeholder="Enter About*"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.about || about}
                            invalid={
                              validation.touched.about &&
                              validation.errors.about
                                ? true
                                : false
                            }
                          />
                          {validation.touched.about &&
                          validation.errors.about ? (
                            <FormFeedback type="invalid">
                              {validation.errors.about}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Name */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <Label for="full_name">
                            Name <span style={{ color: "red" }}> * </span>
                          </Label>
                          <Input
                            id="full_name"
                            name="full_name"
                            className="form-control  emailinput"
                            placeholder="Enter Full Name*"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.full_name || full_name}
                            invalid={
                              validation.touched.full_name &&
                              validation.errors.full_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.full_name &&
                          validation.errors.full_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.full_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Email ID */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <Label for="email">
                            Email ID <span style={{ color: "red" }}> * </span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control emailinput"
                            placeholder="Enter Email*"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Status */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <Label for="status">
                            Status <span style={{ color: "red" }}> * </span>{" "}
                          </Label>
                          <Input
                            id="status"
                            name="status"
                            type="select"
                            className="form-control emailinput"
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || status}
                          >
                            <option value={""}>Select Status</option>
                            <option value={"1"}>Active</option>
                            <option value={"0"}>Inactive</option>
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Password */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <Label for="password">Password</Label>
                          <Input
                            id="password"
                            name="password"
                            className="form-control  emailinput"
                            placeholder="Enter Password"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Confirm Password */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <Label for="confirmPassword">Confirm Password</Label>
                          <Input
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control emailinput"
                            placeholder="Enter Confirm Password"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* Remark */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <Label for="remark">
                            {" "}
                            Remark <span style={{ color: "red" }}> * </span>
                          </Label>
                          <Input
                            id="remark"
                            name="remark"
                            className="form-control emailinput"
                            placeholder="Enter Remark*"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remark || remark}
                            invalid={
                              validation.touched.remark &&
                              validation.errors.remark
                                ? true
                                : false
                            }
                          />
                          {validation.touched.remark &&
                          validation.errors.remark ? (
                            <FormFeedback type="invalid">
                              {validation.errors.remark}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Button
                          type="submit"
                          className="btn btncolor btn-rounded btn-fw"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default EditProfile;
