import { useEffect, useRef, useState } from "react";

const LazyImage = ({
  placeholderSrc,
  placeholderClassName,
  placeholderStyle,
  src,
  alt,
  className,
  style,
  crossOrigin,
  onDoubleClick,
  onClick,
  reference,
  errorImg
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("");
  const placeholderRef = useRef(null);

  const loadImage = (src) => {
    var image = new Image;
    image.onload = function() {
        if ('naturalHeight' in this) {
            if (this.naturalHeight + this.naturalWidth === 0) {
                this.onerror();
                return;
            }
        } else if (this.width + this.height == 0) {
            this.onerror();
            return;
        }
        // document.body.appendChild(image);
        setView(src);
    };
    image.onerror = function() {
        // document.body.appendChild(document.createTextNode('\nError loading as image: ' + this.src));
        //display error
        setView(errorImg);
    };
    image.src = src;
}


  useEffect(() => {
    // Initiating Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      // Set actual image source && unobserve when intersecting
      if (entries[0].isIntersecting) {
        setView(src);
        observer.unobserve(placeholderRef.current);
      }
    });

    // observe for an placeholder image
    if (placeholderRef && placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }
    loadImage(src);
  }, [src]);
  return (
    <>
      {isLoading && (
        <img
          src={placeholderSrc}
          alt={alt}
          className={placeholderClassName}
          style={placeholderStyle}
          ref={placeholderRef}
        />
      )}
      
      <img
        src={view} // Gets src only when placeholder intersecting
        className={className}
        style={isLoading ? {display: "none"} : style}
        alt={alt}
        onLoad={() => setIsLoading(false)}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        ref={reference}
        crossOrigin={crossOrigin}
      />

    </>
  );
};
export default LazyImage;