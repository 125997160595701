import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteSource,
  fetchAllSource,
} from "../../../redux/actions/sources";
import IsAuth from "../../auth/IsAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Footer from "../../../layout/Footer";
import DataTable from "react-data-table-component";
import ToggleSwitch from "./ToggleSwitch";
import FilterComponent from "../../../layout/FilterComponent";
import { ClipLoader } from "react-spinners";
const SourceList = () => {
  document.title = "Sources List | Glasier Wellness";
  const dispatch = useDispatch();
  const { source, loading } = useSelector((state) => state.sources);
  const [isLoading, setIsLoading] = useState(false)
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getSources();
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const getSources = async () => {
    const res = await dispatch(fetchAllSource());
    if (res.status) {
    } else {
      toast.error(res.message, {
        theme: "colored",
      });
    }
  };


  const deleteSources = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteSource({ id: id }));

        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setIsLoading(false);
        } else {
          Swal.fire("Error!", res.message, "error");
          setIsLoading(false);
        }
      }
    });
  };
  let sortbyNewSource = source.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const rowSelectCritera = (row) => row.newStatus;
  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.sno,
      width: "80px",
      borderWidth: "1px",
    },
    {
      name: "Name",
      selector: (row) => row.sourceName,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "200px",
    },
  ];
  const rows = [
    ...sortbyNewSource?.filter(
      (item) =>
        item.sourceName &&
        item.sourceName.toLowerCase().includes(filterText.toLowerCase())
    ).map((row, order) => ({
      // ...row,
      sno: order + 1,
      sourceName: row.sourceName,
      newStatus: row.status,
      status: (
        <>
          <ToggleSwitch
            key={order}
            status={row.status}
            id={row._id}
            order={order}
          />
        </>
      ),
      action: (
        <>
          <Link
            to={`/admin/source/${row._id}`}
            className="iconbgedit"
            title="Edit"
          >
            <i className="fa fa-edit eyeicon"></i>
          </Link>
          <button
            type="button"
            className="iconbgdelete ml-2"
            title="Delete"
            onClick={(id) => deleteSources(row._id)}
          >
            <i className="fa fa-trash eyeicon"></i>
          </button>
        </>
      ),
    })),
  ];
  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);


  return (
    <>
      <IsAuth />
      <ToastContainer />
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <>
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="main-heading mb-0">Source List</h4>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to="/admin/add-source/"
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add New &nbsp;{" "}
                      <i className="fa fa-plus" style={{ fontSize: 14 }}></i>{" "}
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              {/* <CSVLink data={data} headers={headers} className="btn btncolor btn-rounded btn-fw mb-3">
                              Download me
                            </CSVLink>; */}
                              <DataTable
                                columns={columns}
                                data={rows}
                                progressPending={isLoading}
                                progressComponent={
                                  <div style={{ position: "relative" }}>
                                    <ClipLoader size={50} color="#264B75" />
                                  </div>
                                }
                                pagination
                                customStyles={customStyles}
                                // selectableRowSelected={rowSelectCritera}
                                highlightOnHover
                                pointerOnHover
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default SourceList;
