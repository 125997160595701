import {
  ADD_SOURCE_FAIL,
  ADD_SOURCE_REQUEST,
  ADD_SOURCE_SUCCESS,
  FETCH_SOURCE_FAIL,
  FETCH_SOURCE_REQUEST,
  FETCH_SOURCE_SUCCESS,
  STATUS_SOURCE_FAIL,
  UPDATE_SOURCE_FAIL,
  UPDATE_SOURCE_REQUEST,
  UPDATE_SOURCE_SUCCESS,
} from "../../constants/sourceConstants";

let initialState = {
  source: [],
  loading: false,
  error: null,
};
export const sourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        source: [],
        error: null,
      };
    case FETCH_SOURCE_SUCCESS:
      let data = {
        ...state,
        loading: false,
        error: null,
        source: action.payload,
      };
      return data;
    case FETCH_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        source: [],
        error: action.payload,
      };
    case ADD_SOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        source: [],
        error: null,
      };
    case UPDATE_SOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        source: [],
        error: null,
      };
    // case STATUS_SOURCE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case ADD_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        source: action.payload.data,
        error: null,
      };
    case UPDATE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        source: action.payload.data,
        success: action.payload.message,
        error: null,
      };
    // case STATUS_SOURCE_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         error:null
    //     }
    case ADD_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        source: [],
        error: action.payload,
      };
    case UPDATE_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        source: [],
        error: action.payload,
      };
    case STATUS_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
