import React from "react";
export default function Footer() {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright © {new Date().getFullYear()}. All rights reserved.
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            Glasier Wellness <i className="fa fa-heart text-red"></i>
          </span>
        </div>
      </footer>
    </React.Fragment>
  );
}
