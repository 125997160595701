import React, { useState } from "react";

const Avatar = ( { className, name } ) => {
  // const [full_name] = useState(
  //   JSON.parse(localStorage.getItem("authUser"))
  //     ? JSON.parse(localStorage.getItem("authUser")).full_name
  //     : 'Guest'
  // );

  const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

  return (
    <div className={`avatar-name ${className?className:''}`}>
        {getInitials(name)}
    </div>
  );
};

export default Avatar;
