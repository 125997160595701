import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import FilterComponent from "../../../../layout/FilterComponent";
import { ClipLoader } from "react-spinners";
export default function SelectedReuirements({
  handleNextStep,
  data,
  setSelectRequirmentModule,
  AllRequirment,
}) {
  const [Requirment, setRequirment] = useState([]);
  const [requirmentSelected, setRequirmentSelected] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [Loader, setLoader] = useState(false);
  useEffect(() => {
    setRequirment(data?.requirements);
  }, [data?.requirements]);

  const handleSubmitData = (data, requirment) => {
    setLoader(true);
    setTimeout(() => {
      handleNextStep(data, requirment, true);
    }, 500);
  };

  const deleteRequirments = (requirementId) => {
    //Pass in filtered array as new state for attendees
    setRequirment(
      Requirment.filter((element) => element.requirementId !== requirementId)
    );
  };

  const columns = [
    {
      name: "Units",
      selector: (row) => row.units,
      width: "150px",
    },

    {
      name: "Requirements",
      selector: (row) => row.requirement,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  let selectedRequiermentList = [];

  AllRequirment.forEach((element, i) => {
    Requirment.forEach((ele, index) => {
      if (ele.requirementId == element._id) {
        let obj = {
          requirement: element.requirement,
          requirementId: element._id,
          units: ele.units,
        };
        selectedRequiermentList.push(obj);
      }
    });
  });

  let newReq = [];
  useEffect(() => {
    Requirment.forEach((ele, index) => {
      let obj = {
        requirementId: ele.requirementId,
        units: ele.units,
      };
      newReq.push(obj);
    });
    setRequirmentSelected(newReq);
  }, [Requirment]);

  const rows = [
    ...selectedRequiermentList
      ?.filter(
        (item) =>
          item.requirement &&
          item.requirement.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((row, i) => ({
        units: row.units,
        requirement: row.requirement,
        action: (
          <Link
            key={i}
            className="iconbgremove"
            onClick={(id) => deleteRequirments(row.requirementId)}
            title="Delete"
          >
            <i className="fas fa-trash eyeicon"></i>
          </Link>
        ),
      })),
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);
  return (
    <React.Fragment>
      <Row>
        <Col className="text-left">
          <button
            className="btn btncolor btn-rounded btn-fw mr-2"
            onClick={() => setSelectRequirmentModule(true)}
            style={{ position: "absolute", zIndex: 9 }}
          >
            Select Requirement
          </button>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={rows}
        progressComponent={
          <div style={{ position: "relative" }}>
            <ClipLoader size={30} color="#264B75" />
          </div>
        }
        pagination
        customStyles={customStyles}
        pointerOnHover
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />

      <Row className="mb-3 justify-content-center">
        <Col className="text-center">
          <button
            type="submit"
            className="btn btncolor btn-rounded btn-fw"
            disabled={Loader}
            onClick={(e) => handleSubmitData(data, requirmentSelected)}
          >
            Submit
            {Loader ? (
              <ClipLoader size={20} color="#fff" className="ml-2" />
            ) : null}
          </button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
