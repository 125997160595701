import {
    ADD_TODO_FAIL, ADD_TODO_REQUEST, ADD_TODO_SUCCESS, FETCH_TODO_FAIL,
    FETCH_TODO_REQUEST,
    FETCH_TODO_SUCCESS,
    MARK_TODO_COMPLETED_FAIL,
    MARK_TODO_COMPLETED_REQUEST,
    MARK_TODO_COMPLETED_SUCCESS,
    UPDATE_TODO_FAIL,
    UPDATE_TODO_REQUEST,
    UPDATE_TODO_SUCCESS,
} from "../../constants/toDoConstants";

let initialState = {
    todo: [],
    loading: false,
    error: null,
}

export const todoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TODO_REQUEST:
            return {
                ...state,
                loading: true,
                todo: [],
                error: null,
            };
        case FETCH_TODO_SUCCESS:
            let data = {
                ...state,
                loading: false,
                todo: action.payload,
                error: null
            };
            return data;
        case FETCH_TODO_FAIL:
            return {
                ...state,
                loading: false,
                todo: [],
                error: action.payload,
            };
        case ADD_TODO_REQUEST:
        case UPDATE_TODO_REQUEST:
        case MARK_TODO_COMPLETED_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_TODO_SUCCESS:
        case UPDATE_TODO_SUCCESS:
        // case MARK_TODO_COMPLETED_SUCCESS:
        //     let todoData=[...state.todo];
        //     todoData.push(action.payload);
        //     return {
        //         ...state,
        //         loading: false,
        //         todo: todoData,
        //         error: null,
        //     }
        case ADD_TODO_FAIL:
        case UPDATE_TODO_FAIL:
        case MARK_TODO_COMPLETED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

         //Delete TODO reducer
         //Todo By Date reducer
        default:
            return state;
    }
};