import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Input,
  Label,
  FormFeedback,
  Button,
  Form,
} from "reactstrap";
import IsAuth from "../../../components/auth/IsAuth";
import { useFormik } from "formik";

import * as Yup from "yup";
import { adminRegister } from "../../../redux/actions/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../layout/Footer";

export default function AddUser() {
  document.title = `Add User | Glasier Wellness`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: "",
      email: "",
      position_id: "",
      status: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Your Email"),
      position_id: Yup.string().required("Please Select Type"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string().when("password", (password, field) =>
        password
          ? field
              .required("Please Enter Confirm Password")
              .oneOf([Yup.ref("password")], "Password does not match")
          : field
      ),
    }),

    onSubmit: async (values) => {
      await dispatch(adminRegister(values)).then(async (res) => {
        if(res.status){
            toast.success(res.message,{
              theme: "colored",
            });
            setTimeout(() => {
                navigate(-1)
            }, 500);
        }else{
          toast.error(res.message,{
            theme: "colored",
          });
        }
      });
    },
  });


  return (
    <React.Fragment>
      <IsAuth />
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <Form
              autoComplete="new-password"
                className="forms-sample pt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="row mb-5">
                  <div className="col-md-6">
                    <h4 className="main-heading mb-0">Add User</h4>
                  </div>
                  <div className="col-md-6 text-right">
                    <Link
                      to="/admin/users"
                      className="btn btncolor btn-rounded btn-fw"
                    >
                      {" "}
                      <i
                        className="fa fa-chevron-left"
                        style={{ fontSize: 14 }}
                      ></i>{" "}
                      &nbsp;Back
                    </Link>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-4">
                    <div className="form-group">
                      <Label for="full_name">
                        Full Name <span style={{ color: "red" }}> * </span>
                      </Label>
                      <Input
                        id="full_name"
                        name="full_name"
                        className="form-control emailinput"
                        placeholder="Enter Full Name*"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.full_name || ""}
                        invalid={
                          validation.touched.full_name &&
                          validation.errors.full_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.full_name &&
                      validation.errors.full_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.full_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Label for="email">
                        Email Address <span style={{ color: "red" }}> * </span>{" "}
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control emailinput"
                        placeholder="Enter Email*"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Label for="position_id">
                        User Type <span style={{ color: "red" }}> * </span>{" "}
                      </Label>

                      <Input
                        id="position_id"
                        name="position_id"
                        type="select"
                        className="form-control emailinput"
                        invalid={
                          validation.touched.position_id &&
                          validation.errors.position_id
                            ? true
                            : false
                        }
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      >
                        <option value>Select User Type</option>
                        <option value={1}>Admin</option>
                        <option value={2}>Manager</option>
                        <option value={3}>Staff</option>
                        <option value={4}>Production User</option>
                      </Input>
                      {validation.touched.position_id &&
                      validation.errors.position_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.position_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <Label for="status">
                        Status <span style={{ color: "red" }}> * </span>{" "}
                      </Label>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        className="form-control emailinput"
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      >
                        <option value>Select Status</option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label for="password">Password</label>
                      <div className="form-group">
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          className="form-control emailinput"
                          placeholder="Enter Password*"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          autoComplete="new-password"
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Label for="confirmPassword">
                        Confirm Password
                      </Label>
                      <div className="form-group">
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className="form-control emailinput"
                          placeholder="Enter Confirm Password*"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-5 mt-3">
                  <div className="col-md-12 text-center">
                    <Button
                      type="submit"
                      className="btn btncolor btn-rounded btn-fw"
                      disabled={validation.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
