import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../layout/Footer";
import AllData from "./AllData";
import LostData from "./LostData";
import WonData from "./WonData";
import WorkingData from "./WorkingData";
import ContactedData from "./ContactedData";
import NewData from "./NewData";
import { fetchAllInquiry } from "../../../redux/actions/inquiry";
import { API_URLS } from "../../../common/constant";
import axios from "axios";
import IsAuth from "../../auth/IsAuth";

const InquiryReport = () => {
  document.title = `Inquiry Report | Glasier Wellness`;
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [Token] = useState(localStorage.getItem("accessToken"));
  const dispatch = useDispatch();
  const { inquiries } = useSelector((state) => state.inquiry);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("new");
  const [tabCounts, setTabCounts] = useState({});

  useEffect(() => {
    getDashboard();
  }, [dispatch]);

  const getDashboard = async () => {
    setIsLoading(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios.get(API_URLS.DASHBOARD, config).then((result) => {
        if (result.status) {
          if (result?.data?.status) {
            setTabCounts(result?.data.data);
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const changeActiveTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  const columns = [
    // {
    //   name: "S.No.",
    //   selector: (row) => row.sno,
    //   width: "70px",
    // },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "200px",
    },
    {
      name: "Party Details",
      selector: (row) => row.partyDetails,
      width: "600px",
    },
    {
      name: "Requirement/ Units",
      selector: (row) => row.requirements,
      width: "550px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'block',
      },
    },
    rows: {
      style: {
        minHeight: "45px", // override the row height
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#e9ecef",
        fontSize: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dcdcdc",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "17px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#e9ecef",
      },
    },
  };

  return (
    <>
    <IsAuth />
      <div className="main-panel">
        <div className="content-wrapper">
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col className="col-md-6">
                  <h4>All Inquiry List</h4>
                </Col>
              </Row>
              <Row>
                <Col className="md-12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-home"
                        className={classnames({
                          active: activeTab === "new" ? true : false,
                        })}
                        onClick={(e) => changeActiveTab("new")}
                      >
                        New{" "}
                        {tabCounts?.newInquiries ? (
                          <span className="redcount">
                            {tabCounts?.newInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-profile"
                        onClick={(e) => changeActiveTab("contacted")}
                        className={classnames({
                          active: activeTab === "contacted" ? true : false,
                        })}
                      >
                        Connected
                        {tabCounts?.contactedInquiries ? (
                          <span className="redcount">
                            {tabCounts?.contactedInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-contact"
                        onClick={(e) => changeActiveTab("working")}
                        className={classnames({
                          active: activeTab === "working" ? true : false,
                        })}
                      >
                        Working
                        {tabCounts?.workingInquiries ? (
                          <span className="redcount">
                            {tabCounts?.workingInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-contact"
                        onClick={(e) => changeActiveTab("won")}
                        className={classnames({
                          active: activeTab === "won" ? true : false,
                        })}
                      >
                        Won
                        {tabCounts?.wonInquiries ? (
                          <span className="redcount">
                            {tabCounts?.wonInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-contact"
                        onClick={(e) => changeActiveTab("lost")}
                        className={classnames({
                          active: activeTab === "lost" ? true : false,
                        })}
                      >
                        Lost
                        {tabCounts?.lostInquiries ? (
                          <span className="redcount">
                            {tabCounts?.lostInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-controls="pills-contact"
                        onClick={(e) => changeActiveTab("all")}
                        className={classnames({
                          active: activeTab === "all" ? true : false,
                        })}
                      >
                        All
                        {tabCounts?.newInquiries || 
                        tabCounts?.contactedInquiries || 
                        tabCounts?.workingInquiries || 
                        tabCounts?.wonInquiries || 
                        tabCounts?.lostInquiries ? (
                          <span className="redcount">
                          {tabCounts?.newInquiries +
                            tabCounts?.contactedInquiries +
                            tabCounts?.workingInquiries +
                            tabCounts?.wonInquiries +
                            tabCounts?.lostInquiries}
                          </span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    {activeTab === "new" ? (
                      <TabPane tabId="new">
                        <Card>
                          <CardBody>
                            <NewData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "contacted" ? (
                      <TabPane tabId="contacted">
                        <Card>
                          <CardBody>
                            <ContactedData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "working" ? (
                      <TabPane tabId="working">
                        <Card>
                          <CardBody>
                            <WorkingData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "won" ? (
                      <TabPane tabId="won">
                        <Card>
                          <CardBody>
                            <WonData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "lost" ? (
                      <TabPane tabId="lost">
                        <Card>
                          <CardBody>
                            <LostData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "all" ? (
                      <TabPane tabId="all">
                        <Card>
                          <CardBody>
                            <AllData
                              columns={columns}
                              customStyles={customStyles}
                              authUser={authUser}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : (
                      ""
                    )}
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InquiryReport;
