import React, { useEffect, useState } from "react";
import folder from "../../../assets/img/folder.png";
import menuVertical from "../../../assets/img/vertical-menu.png";

import Footer from "../../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductCategory,
  getAllCategories,
} from "../../../redux/actions/products";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { API_URLS, ROLES } from "../../../common/constant";
import { toast, ToastContainer } from "react-toastify";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
// import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import GlobalSearch from "./GlobalSearch";

export default function Category() {
  document.title = "Products Category | Glasier Wellness";
  const dispatch = useDispatch();
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [Token] = useState(localStorage.getItem("accessToken"));
  const { categories } = useSelector((state) => state.products);
  const [categoryList, setCategoryList] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [categoryModels, setCategoryModels] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryString, setCategoryString] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [globalSearch, setGlobalSearch] = useState(false);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  useEffect(() => {
    getCategories();
  }, [dispatch]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  const getCategories = async () => {
    setLoader(true);
    await dispatch(getAllCategories()).then(async (res) => {
      setLoader(false);
    });
  };

  const submitCategory = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(
          API_URLS.ADD_PRODUCT_CATEGORY,
          { categoryName: categoryName },
          config
        )
        .then((result) => {
          if (result?.data?.status) {
            toast.success(result?.data?.message, {
              theme: "colored",
            });
            setCategoryList([...categoryList, result?.data?.data]);
            setLoader(false);
            setCategoryName("");
            categoryModelToggle();
          } else {
            toast.error(result?.data.message, {
              theme: "colored",
            });
            setLoader(false);
          }
        });
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
      setLoader(false);
    }
  };

  const updateCategory = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(
          API_URLS.UPDATE_PRODUCT_CATEGORY,
          { categoryId: categoryId, categoryName: categoryName },
          config
        )
        .then((result) => {
          if (result?.data?.status) {
            toast.success(result?.data?.message, {
              theme: "colored",
            });
            getCategories();
            setLoader(false);
            setCategoryName("");
            setCategoryId("");
            categoryModelToggle();
          } else {
            toast.error(result?.data.message, {
              theme: "colored",
            });
            setLoader(false);
          }
        });
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
      setLoader(false);
    }
  };

  const deleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteProductCategory({ categoryId: id }));
        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setLoader(false);
          setCategoryList(
            categoryList.filter((element) => element._id !== id),
            [...categoryList]
          );
        } else {
          Swal.fire("Error!", res.message, "error");
          setLoader(false);
        }
      }
    });
  };

  const searchCategory = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    setLoader(true);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(API_URLS.GET_PRODUCT_CATEGORY, { search: e.target.value }, config)
        // .post(
        //   API_URLS.GLOBAL_PRODUCT_SEARCH,
        //   { search: e.target.value },
        //   config
        // )
        .then((result) => {
          if (result?.data?.status) {
            setCategoryList(result?.data?.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        });
    } catch (error) {
      setLoader(false);
    }
  };
  const categoryModelToggle = () => {
    setCategoryModels((state) => !state);
  };

  const share = (e, stringCat) => {
    e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copied!';
    const el = document.createElement("input");
    el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/category/?categoryId=${stringCat}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copy Link';
    }, 1000);
  };

  const handleMultipleShare = (e) => {
    const { checked, value } = e.currentTarget;
    setCategoryArray((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };
  const multipleSelect = () => {
    setSelectMultiple((state) => !state);
    setCategoryArray([]);
  };

  const SelectAll = () => {
    setSelectMultiple(true);
    const categoryIds = categoryList?.map((category) => {
      return category?._id;
    });
    setCategoryArray(categoryIds);
  };
  const DeSelectAll = () => {
    setSelectMultiple(true);
    setCategoryArray([]);
  };
  useEffect(() => {
    const categoryStringIds = categoryArray?.toString()?.replace(/,/g, "-");
    setCategoryString(categoryStringIds);
  }, [categoryArray]);

  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-3">
                  <label className="pull-left">
                    <Input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      aria-controls="order-listing"
                      onChange={(e) => searchCategory(e)}
                      value={searchText}
                      style={{ width: "250px" }}
                    />
                  </label>
                </div>
                <div className="col-md-6 mt-2 text-center">
                  <h4 className="main-heading">Category List</h4>
                </div>
                <div className="col-md-3">
                  {authUser && authUser.userType === ROLES.PRODUCTION_USER ? (
                    <Link
                      onClick={() => {
                        categoryModelToggle();
                        setCategoryName("");
                        setCategoryId("");
                        setLoader(false);
                        setIsEdit(false);
                      }}
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add Category &nbsp;{" "}
                      <i
                        className="fa fa-plus"
                        style={{ fontSize: "14px" }}
                      ></i>{" "}
                    </Link>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>
                </div>
                <div className="col-md-6">
                  {categoryArray?.length === 0 ? (
                    <button
                      className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                      onClick={() => SelectAll()}
                      disabled={categoryList?.length === 0}
                    >
                      Select All
                    </button>
                  ) : (
                    <button
                      className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                      onClick={() => DeSelectAll()}
                      disabled={categoryList?.length === 0}
                    >
                      Deselect All
                    </button>
                  )}

                  <button
                    className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                    onClick={() => multipleSelect()}
                    disabled={categoryList?.length === 0}
                  >
                    {categoryArray?.length == 0 ? "Select" : "Clear"}
                  </button>
                  <button
                    className="btn btncolor btn-rounded btn-fw mb-2"
                    onClick={(e) => share(e, categoryString)}
                    disabled={categoryArray?.length === 0}
                  >
                    <i className="fa fa-copy mr-2"></i> Copy Link
                  </button>
                </div>
              </div>
              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : categoryList?.length > 0 ? (
                <div className="row">
                  {categoryList
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((category, i) => (
                      <div key={i} className="col-md-2 mb-3">
                        <div
                          className={
                            categoryArray?.find(
                              (item2) => category?._id == item2
                            )
                              ? "category-box selected"
                              : "category-box"
                          }
                        >
                          {selectMultiple ? (
                            <div className="form-check share-checkbox">
                              <label className="form-check-label form-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input form-check-input"
                                  name="category"
                                  value={category?._id}
                                  onChange={(e) => handleMultipleShare(e)}
                                  checked={
                                    categoryArray?.find(
                                      (item2) => category?._id == item2
                                    )
                                      ? true
                                      : false
                                  }
                                />
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          ) : (
                            ""
                          )}

                          <Link
                            to={`/products/${category?._id}`}
                            className="category-box-link"
                            data-type={category?.type}
                          >
                            <div
                              className="folder-icon"
                              style={{
                                WebkitMaskImage: `url(${folder})`,
                              }}
                            ></div>
                            <h5 className="cat-title mb-0">
                              {capitalize(category?.categoryName)}
                            </h5>
                          </Link>
                          <div
                            className="share-icon"
                            style={{
                              WebkitMaskImage: `url(${menuVertical})`,
                            }}
                            title="Share"
                            id={`dropdown_${i}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          ></div>
                          <div
                            className="dropdown-share dropdown-menu"
                            aria-labelledby={`dropdown_${i}`}
                          >
                            <ul>
                              {authUser &&
                              authUser.userType === ROLES.PRODUCTION_USER ? (
                                <>
                                  <li
                                    onClick={() => {
                                      categoryModelToggle();
                                      setCategoryName(category?.categoryName);
                                      setCategoryId(category?._id);
                                      setIsEdit(true);
                                    }}
                                  >
                                    <i className="fa fa-edit"></i> Edit
                                  </li>
                                  <li
                                    onClick={() =>
                                      deleteCategory(category?._id)
                                    }
                                  >
                                    <i className="fa fa-trash-alt"></i> Delete
                                  </li>
                                </>
                              ) : (
                                ""
                              )}
                              <li onClick={(e) => share(e, category?._id)}>
                                <i className="fa fa-copy"></i> Copy Link
                              </li>
                              <li>
                                <Link
                                  to={`https://web.whatsapp.com/send?text=${process.env.REACT_APP_LIVE_SHARE_URL}/category/?categoryId=${category?._id}`}
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <i className="fab fa-whatsapp-square"></i>{" "}
                                  Share via Whatsapp
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="position-relative text-center">
                  No Data Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <Modal isOpen={categoryModels} toggle={categoryModelToggle}>
        <ModalHeader toggle={categoryModelToggle}>
          {isEdit ? "Update" : "Add"} Category
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="categoryName">
                  Category Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control emailinput"
                  id="categoryName"
                  placeholder="Enter Category Name"
                  name="categoryName"
                  onChange={(e) => setCategoryName(e.target.value)}
                  value={categoryName}
                  required
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {isEdit ? (
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw"
              onClick={(e) => updateCategory(e)}
              disabled={Loader || !categoryName}
            >
              {Loader ? (
                <ClipLoader size={20} color="#fff" className="ml-2" />
              ) : (
                "Update"
              )}
            </button>
          ) : (
            <button
              type="button"
              className="btn btncolor btn-rounded btn-fw"
              onClick={(e) => submitCategory(e)}
              disabled={Loader || !categoryName}
            >
              {Loader ? (
                <ClipLoader size={20} color="#fff" className="ml-2" />
              ) : (
                "Submit"
              )}
            </button>
          )}
        </ModalFooter>
      </Modal>

      {/* Global Search Popup */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <GlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
