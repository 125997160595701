import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../../layout/Footer";
import {
  addProducts,
  deleteProducts,
  getAllCategories,
  getAllProducts,
  getProductImages,
  updateProducts,
} from "../../../redux/actions/products";
import folder from "../../../assets/img/folder.png";
import menuVertical from "../../../assets/img/vertical-menu.png";
import {
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_URLS, ROLES } from "../../../common/constant";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalSearch from "./GlobalSearch";
export default function ProductsByCategory() {
  document.title = "Product List | Glasier Wellness";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Token] = useState(localStorage.getItem("accessToken"));
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const { catId } = useParams();
  const { categories } = useSelector((state) => state.products);
  const { products } = useSelector((state) => state.products);
  const [Loader, setLoader] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [productModels, setProductModels] = useState(false);
  const attachPhotosRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const [productArray, setProductArray] = useState([]);
  const [productString, setProductString] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [productId, setProductId] = useState("");
  const IMAGE_URL = process.env.REACT_APP_IMAGE_PATH;
  const [prodImages, setProdImages] = useState([]);
  const [singleProduct, setSingleProduct] = useState({
    productName: "",
    perUnitPrice: "",
    units: "",
    attachPhotos: "",
  });
  const [oldImage, setOldImage] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [globalSearch, setGlobalSearch] = useState(false);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  useEffect(() => {
    getProducts();
  }, [dispatch]);

  useEffect(() => {
    setProductsList(products);
  }, [products]);

  useEffect(() => {
    getCategories();
  }, [dispatch]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  const getCategories = async () => {
    await dispatch(getAllCategories());
  };

  const getProducts = async () => {
    setLoader(true);
    await dispatch(getAllProducts({ categoryId: catId })).then(async (res) => {
      setLoader(false);
    });
  };

  const fetchProductImages = async (proId) => {
    await dispatch(getProductImages({ productId: proId })).then(async (res) => {
      setProdImages(res?.data?.attachPhotos);
      setLoader(false);
    });
  };
  const searchProduct = async (e) => {
    e.preventDefault();
    setLoader(true);
    setSearchText(e.target.value);
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(
          API_URLS.GET_PRODUCTS,
          { categoryId: catId, search: e.target.value },
          config
        )
        .then((result) => {
          if (result?.data?.status) {
            setProductsList(result?.data?.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        });
    } catch (error) {
      setLoader(false);
    }
  };

  const productModelToggle = () => {
    setProductModels((state) => !state);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: singleProduct?.productName,
      perUnitPrice: singleProduct?.perUnitPrice,
      units: singleProduct?.units,
      attachPhotos: "",
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Please Enter Product Name"),
      // perUnitPrice: Yup.string().required("Please Enter Price/Unit"),
      // units: Yup.string().required("Please Enter Units"),
    }),

    onSubmit: async (values, { resetForm }) => {
      let form_data = new FormData();
      form_data.append("categoryId", catId);
      form_data.append("perUnitPrice", values.perUnitPrice);
      form_data.append("productName", values.productName);
      form_data.append("units", values.units);
      for (var i = 0; i < values.attachPhotos.length; i++) {
        form_data.append("attachPhotos", values.attachPhotos[i]);
      }
      if (isEdit) {
        form_data.append("productId", productId);
        if (oldImage?.length > 0) {
          form_data.append("oldImg", JSON.stringify(oldImage));
        }
        await dispatch(updateProducts(form_data)).then(async (res) => {
          if (res.status) {
            toast.success(res.message, {
              theme: "colored",
            });
            getProducts();
            attachPhotosRef.current.value = null;
            productModelToggle();
            resetForm();
          } else {
            toast.error(res.message, {
              theme: "colored",
            });
          }
        });
      } else {
        await dispatch(addProducts(form_data)).then(async (res) => {
          if (res.status) {
            toast.success(res.message, {
              theme: "colored",
            });
            setProductsList([...productsList, res?.data]);
            attachPhotosRef.current.value = null;
            productModelToggle();
            resetForm();
          } else {
            toast.error(res.message, {
              theme: "colored",
            });
          }
        });
      }
    },
  });

  const productEdit = async (product) => {
    fetchProductImages(product?._id);
    productModelToggle();
    setProductId(product?._id);
    setOldImage([]);
    setNewImage([]);
    setSingleProduct({
      productName: product?.productName,
      perUnitPrice: product?.perUnitPrice,
      units: product?.units,
      attachPhotos: "",
    });
    validation.setFieldValue("attachPhotos", "");
    setIsEdit(true);
  };

  const deleteProduct = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteProducts({ productId: id }));
        if (res.status) {
          Swal.fire("Deleted!", res.message, "success");
          setLoader(false);
          setProductsList(
            productsList.filter((element) => element._id !== id),
            [...productsList]
          );
        } else {
          Swal.fire("Error!", res.message, "error");
          setLoader(false);
        }
      }
    });
  };

  useEffect(() => {
    categoryList
      ?.filter((cat) => cat._id == catId)
      .map((category) => {
        setCategoryName(category?.categoryName);
      });
  }, [categoryList]);

  const removeOldImage = (image) => {
    setOldImage((prev) => [...prev, image]);
  };
  const removeNewImage = (e) => {
    const newImgRemove = newImage.filter((item, index) => index !== e);
    setNewImage(newImgRemove);
  };

  const share = (e, productString) => {
    const el = document.createElement("input");
    el.value = `${process.env.REACT_APP_LIVE_SHARE_URL}/products/${catId}/?productId=${productString}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copied!';
    setTimeout(() => {
      e.target.innerHTML = '<i class="fa fa-copy mr-2"></i> Copy Link';
    }, 2000);
  };

  const handleMultipleShare = (e) => {
    const { checked, value } = e.currentTarget;
    setProductArray((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };
  const multipleSelect = () => {
    setSelectMultiple((state) => !state);
    setProductArray([]);
  };

  const SelectAll = () => {
    setSelectMultiple(true);
    const productsIds = productsList?.map((product) => {
      return product?._id;
    });
    setProductArray(productsIds);
  };
  const DeSelectAll = () => {
    setSelectMultiple(true);
    setProductArray([]);
  };

  const globalSearchModelToggle = () => {
    setGlobalSearch((state) => !state);
  };

  useEffect(() => {
    const productStringIds = productArray?.toString()?.replace(/,/g, "-");
    setProductString(productStringIds);
  }, [productArray]);
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-4">
                  <button
                    onClick={(e) => navigate(-1)}
                    className="btn btncolor btn-rounded btn-fw pull-left mb-0"
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: "14px", marginRight: "10px" }}
                    ></i>
                    Back
                  </button>
                </div>
                <div className="col-md-4 text-center mt-2">
                  <h4 className="main-heading mb-0">
                    {capitalize(categoryName)}
                  </h4>
                </div>
                <div className="col-md-4">
                  <button
                    onClick={globalSearchModelToggle}
                    className="btn btncolor btn-rounded pull-right ml-2 p-3 mb-2 mr-2"
                    title="Search"
                  >
                    <i className="fa fa-search eyeicon"></i>
                  </button>

                  {authUser && authUser.userType === ROLES.PRODUCTION_USER ? (
                    <Link
                      onClick={() => {
                        productModelToggle();
                        setProductId("");
                        setLoader(false);
                        setIsEdit(false);
                        setOldImage([]);
                        setNewImage([]);
                        setSingleProduct({
                          productName: "",
                          perUnitPrice: "",
                          units: "",
                          attachPhotos: "",
                        });
                        setProdImages([]);
                      }}
                      className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    >
                      Add Product &nbsp;{" "}
                      <i
                        className="fa fa-plus"
                        style={{ fontSize: "14px" }}
                      ></i>{" "}
                    </Link>
                  ) : (
                    ""
                  )}
                  <label className="pull-right mr-3">
                    <Input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      aria-controls="order-listing"
                      onChange={(e) => searchProduct(e)}
                      value={searchText}
                      style={{ width: "250px" }}
                    />
                  </label>
                </div>
                <div className="col-md-6">
                  {productArray?.length === 0 ? (
                    <button
                      className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                      onClick={() => SelectAll()}
                      disabled={categoryList?.length === 0}
                    >
                      Select All
                    </button>
                  ) : (
                    <button
                      className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                      onClick={() => DeSelectAll()}
                      disabled={categoryList?.length === 0}
                    >
                      Deselect All
                    </button>
                  )}
                  <button
                    className="btn btncolor btn-rounded btn-fw mb-2 mr-2"
                    onClick={() => multipleSelect()}
                    disabled={productsList?.length === 0}
                  >
                    {productArray?.length == 0 ? "Select" : "Clear"}
                  </button>
                  <button
                    className="btn btncolor btn-rounded btn-fw mb-2"
                    onClick={(e) => share(e, productString)}
                    disabled={productArray?.length == 0}
                  >
                    <i className="fa fa-copy mr-2"></i> Copy Link
                  </button>
                </div>
              </div>

              {Loader ? (
                <div className="text-center">
                  <ClipLoader size={40} color="#264B75" />
                </div>
              ) : productsList?.length > 0 ? (
                <div className="row">
                  {productsList
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((product, i) => (
                      <div key={i} className="col-md-2 mb-3">
                        <div
                          className={
                            productArray?.find(
                              (product2) => product?._id == product2
                            )
                              ? "category-box selected"
                              : "category-box"
                          }
                        >
                          {selectMultiple ? (
                            <div className="form-check share-checkbox">
                              <label className="form-check-label form-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input form-check-input"
                                  name="category"
                                  value={product?._id}
                                  onChange={(e) => handleMultipleShare(e)}
                                  checked={
                                    productArray?.find(
                                      (product2) => product?._id == product2
                                    )
                                      ? true
                                      : false
                                  }
                                />
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          <Link
                            to={`/gallery/${catId}/${product?._id}`}
                            className="category-box-link"
                          >
                            <div
                              className="folder-icon"
                              style={{ WebkitMaskImage: `url(${folder})` }}
                            ></div>
                            <h5 className="cat-title mb-0">
                              {capitalize(product?.productName)}
                            </h5>
                          </Link>
                          <div
                            className="share-icon"
                            style={{ WebkitMaskImage: `url(${menuVertical})` }}
                            title="Share"
                            id={`dropdown_${i}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          ></div>
                          <div
                            className="dropdown-share dropdown-menu"
                            aria-labelledby={`dropdown_${i}`}
                          >
                            <ul>
                              {authUser &&
                              authUser.userType === ROLES.PRODUCTION_USER ? (
                                <>
                                  <li
                                    onClick={() => {
                                      productEdit(product);
                                    }}
                                  >
                                    <i className="fa fa-edit"></i> Edit
                                  </li>
                                  <li
                                    onClick={() => deleteProduct(product?._id)}
                                  >
                                    <i className="fa fa-trash-alt"></i> Delete
                                  </li>
                                </>
                              ) : (
                                ""
                              )}
                              <li onClick={(e) => share(e, product?._id)}>
                                <i className="fa fa-copy"></i> Copy Link
                              </li>
                              <li>
                                <Link
                                  to={`https://web.whatsapp.com/send?text=${process.env.REACT_APP_LIVE_SHARE_URL}/products/${catId}/?productId=${product?._id}`}
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <i className="fab fa-whatsapp-square"></i>{" "}
                                  Share via Whatsapp
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="position-relative text-center">
                  No Product Found!
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* Add Product */}
      <Modal
        size={isEdit ? "lg" : "md"}
        isOpen={productModels}
        toggle={productModelToggle}
      >
        <ModalHeader toggle={productModelToggle}>
          {isEdit ? "Update" : "Add"} Product
        </ModalHeader>
        <ModalBody>
          <Form
            encType="multipart/form-data"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onChange={(e) => {
              if (e.target.name == "attachPhotos") {
                let ImagesArray = Object.entries(e.target.files).map(
                  (file) => file[1]
                );
                validation.setFieldValue("attachPhotos", ImagesArray);
                let newImagesArray = Object.entries(e.target.files).map((e) =>
                  URL.createObjectURL(e[1])
                );
                setNewImage(newImagesArray);
              }
              return false;
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label for="productName">
                    Product Name <span style={{ color: "red" }}> * </span>
                  </Label>
                  <Input
                    type="text"
                    id="productName"
                    name="productName"
                    className="form-control emailinput"
                    placeholder="Enter Product Name*"
                    invalid={
                      validation.touched.productName &&
                      validation.errors.productName
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.productName}
                  />
                  {validation.touched.productName &&
                  validation.errors.productName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.productName}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label for="perUnitPrice">
                    Unit/Price
                    {/* <span style={{ color: "red" }}> * </span>{" "} */}
                  </Label>
                  <Input
                    type="text"
                    id="perUnitPrice"
                    name="perUnitPrice"
                    className="form-control emailinput"
                    placeholder="Enter Unit/Price"
                    autoComplete="off"
                    invalid={
                      validation.touched.perUnitPrice &&
                      validation.errors.perUnitPrice
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.perUnitPrice}
                  />
                  {validation.touched.perUnitPrice &&
                  validation.errors.perUnitPrice ? (
                    <FormFeedback type="invalid">
                      {validation.errors.perUnitPrice}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label for="units">
                    Units
                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                  </Label>
                  <Input
                    type="number"
                    id="units"
                    name="units"
                    className="form-control emailinput"
                    placeholder="Enter Units"
                    autoComplete="off"
                    invalid={
                      validation.touched.units && validation.errors.units
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.units}
                  />
                  {validation.touched.units && validation.errors.units ? (
                    <FormFeedback type="invalid">
                      {validation.errors.units}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <Label for="attachPhotos">
                    Images
                    {/* <span style={{ color: "red" }}>*</span> */}{" "}
                  </Label>
                  <Input
                    type="file"
                    id="attachPhotos"
                    name="attachPhotos"
                    className="form-control emailinput"
                    autoComplete="off"
                    accept="image/*"
                    ref={attachPhotosRef}
                    multiple
                    invalid={
                      validation.touched.attachPhotos &&
                      validation.errors.attachPhotos
                        ? true
                        : false
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.attachPhotos &&
                  validation.errors.attachPhotos ? (
                    <FormFeedback type="invalid">
                      {validation.errors.attachPhotos}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12">
                <div className="old-img">
                  {prodImages?.map((image, i) => {
                    return oldImage?.find((image2) => image == image2) ? (
                      ""
                    ) : (
                      <label className={"old"}>
                        <span
                          className="close-btn"
                          onClick={() => removeOldImage(image)}
                        >
                          &times;{" "}
                        </span>
                        <img
                          crossOrigin="anonymous"
                          src={`${IMAGE_URL}/${image}`}
                        />
                      </label>
                    );
                  })}
                  {newImage?.map((image, i) => {
                    return (
                      <label className={"new"}>
                        <span
                          className="close-btn"
                          onClick={() => removeNewImage(i)}
                        >
                          &times;{" "}
                        </span>
                        <img crossOrigin="anonymous" src={image} />
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {validation?.isSubmitting ? (
                  <button
                    type="button"
                    className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    disabled={validation?.isSubmitting}
                  >
                    <ClipLoader size={20} color="#fff" className="ml-2" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btncolor btn-rounded btn-fw pull-right mb-3"
                    disabled={validation?.isSubmitting}
                  >
                    {isEdit ? "Update" : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* Global Search */}
      <Modal
        className="modal-fullscreen"
        // size="lg"
        isOpen={globalSearch}
        toggle={globalSearchModelToggle}
      >
        <ModalHeader toggle={globalSearchModelToggle}>
          Global Search
        </ModalHeader>
        <ModalBody>
          <GlobalSearch />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
